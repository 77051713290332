"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StartDataImport = void 0;

var _react = _interopRequireDefault(require("react"));

var _reactHelmet = require("react-helmet");

var _formInstructions = require("./formInstructions");

var _reactUtils = require("@opensrp/react-utils");

var _antd = require("antd");

var _form = require("./form");

var StartDataImport = function StartDataImport() {
  var pageTitle = 'Data imports';
  var headerProps = {
    pageHeaderProps: {
      title: pageTitle,
      onBack: undefined
    }
  };
  return _react["default"].createElement(_react["default"].Fragment, null, _react["default"].createElement(_reactUtils.BodyLayout, {
    headerProps: headerProps
  }, _react["default"].createElement(_reactHelmet.Helmet, null, _react["default"].createElement("title", null, pageTitle)), _react["default"].createElement(_antd.Row, {
    className: "list-view"
  }, _react["default"].createElement(_antd.Col, {
    className: "main-content"
  }, _react["default"].createElement(_formInstructions.ImporterFormInstructions, null), _react["default"].createElement(_form.DataImportForm, null)))));
};

exports.StartDataImport = StartDataImport;