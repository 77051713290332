"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.UserDetailsOverview = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _react = _interopRequireDefault(require("react"));

var _antd = require("antd");

var _mls = require("../../../mls");

var _reactUtils = require("@opensrp/react-utils");

var _userManagement = require("@opensrp/user-management");

var _keycloakService = require("@opensrp/keycloak-service");

var _reactQuery = require("react-query");

var _constants = require("../../../constants");

var _icons = require("@ant-design/icons");

var _reactRouterDom = require("react-router-dom");

var UserDetailsOverview = function UserDetailsOverview(props) {
  var _userDetails, _headerLeftData2;

  var keycloakBaseUrl = props.keycloakBaseURL,
      resourceId = props.resourceId;

  var _useTranslation = (0, _mls.useTranslation)(),
      t = _useTranslation.t;

  var _useSearchParams = (0, _reactUtils.useSearchParams)(),
      removeParam = _useSearchParams.removeParam;

  var _useQuery = (0, _reactQuery.useQuery)([_userManagement.KEYCLOAK_URL_USERS, resourceId], function () {
    return new _keycloakService.KeycloakService("".concat(_userManagement.KEYCLOAK_URL_USERS), keycloakBaseUrl).read(resourceId);
  }),
      user = _useQuery.data,
      userIsLoading = _useQuery.isLoading,
      userError = _useQuery.error;

  if (!resourceId) {
    return null;
  }

  if (!user) {
    var _resourceDetailsProp = {
      title: '',
      headerLeftData: (0, _defineProperty2["default"])({}, t('ID'), resourceId),
      headerActions: _react["default"].createElement(_antd.Button, {
        "data-testid": "cancel",
        icon: _react["default"].createElement(_icons.CloseOutlined, null),
        shape: "circle",
        type: "text",
        onClick: function onClick() {
          return removeParam(_reactUtils.viewDetailsQuery);
        }
      }),
      bodyData: function bodyData() {
        if (userIsLoading) {
          return _react["default"].createElement(_antd.Alert, {
            description: t('Fetching user details'),
            type: "info",
            showIcon: true,
            icon: _react["default"].createElement(_icons.SyncOutlined, {
              spin: true
            })
          });
        } else if (userError) {
          _react["default"].createElement(_antd.Alert, {
            message: t("Could not fetch this user's details."),
            type: "info"
          });
        }
      }
    };
    return _react["default"].createElement(_reactUtils.ResourceDetails, _resourceDetailsProp);
  }

  var id = user.id,
      firstName = user.firstName,
      lastName = user.lastName,
      username = user.username,
      email = user.email,
      enabled = user.enabled,
      emailVerified = user.emailVerified;
  var userDetails = (_userDetails = {}, (0, _defineProperty2["default"])(_userDetails, t('First Name'), firstName), (0, _defineProperty2["default"])(_userDetails, t('Last Name'), lastName), (0, _defineProperty2["default"])(_userDetails, t('Username'), username), (0, _defineProperty2["default"])(_userDetails, t('Email'), email), _userDetails);
  var status = {
    title: t('Disabled'),
    color: 'red'
  };

  if (enabled) {
    status = {
      title: t('Enabled'),
      color: 'green'
    };
  }

  var resourceDetailsProp = {
    title: user.username,
    headerLeftData: (_headerLeftData2 = {}, (0, _defineProperty2["default"])(_headerLeftData2, t('ID'), id), (0, _defineProperty2["default"])(_headerLeftData2, t('Verified'), emailVerified ? t('True') : t('False')), _headerLeftData2),
    status: status,
    headerActions: _react["default"].createElement(_antd.Button, {
      "data-testid": "cancel",
      icon: _react["default"].createElement(_icons.CloseOutlined, null),
      shape: "circle",
      type: "text",
      onClick: function onClick() {
        return removeParam(_reactUtils.viewDetailsQuery);
      }
    }),
    bodyData: function bodyData() {
      return _react["default"].createElement(_react["default"].Fragment, null, _react["default"].createElement(_reactUtils.KeyValuesDescriptions, {
        data: userDetails,
        column: 2,
        theme: "default"
      }));
    },
    footer: _react["default"].createElement(_reactRouterDom.Link, {
      to: "".concat(_constants.USER_DETAILS_URL, "/").concat(id),
      className: "m-0 p-1"
    }, t('View full details'))
  };
  return _react["default"].createElement(_reactUtils.ResourceDetails, resourceDetailsProp);
};

exports.UserDetailsOverview = UserDetailsOverview;