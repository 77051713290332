"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.UserDeleteBtn = void 0;

var _regenerator = _interopRequireDefault(require("@babel/runtime/regenerator"));

var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));

var _antd = require("antd");

var _userManagement = require("@opensrp/user-management");

var _notifications = require("@opensrp/notifications");

var _utils = require("../UserList/ListView/utils");

var _mls = require("../../mls");

var _reactQuery = require("react-query");

var _react = _interopRequireDefault(require("react"));

var UserDeleteBtn = function UserDeleteBtn(props) {
  var afterActions = props.afterActions,
      keycloakBaseUrl = props.keycloakBaseUrl,
      fhirBaseUrl = props.fhirBaseUrl,
      resourceId = props.resourceId;

  var _useTranslation = (0, _mls.useTranslation)(),
      t = _useTranslation.t;

  var queryClient = (0, _reactQuery.useQueryClient)();
  return _react["default"].createElement(_antd.Popconfirm, {
    key: "delete-user",
    title: t('Are you sure you want to delete this user?'),
    okText: t('Yes'),
    cancelText: t('No'),
    onConfirm: (0, _asyncToGenerator2["default"])(_regenerator["default"].mark(function _callee() {
      return _regenerator["default"].wrap(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              _context.next = 2;
              return (0, _utils.deleteUser)(keycloakBaseUrl, fhirBaseUrl, resourceId, t);

            case 2:
              _context.prev = 2;
              _context.next = 5;
              return queryClient.invalidateQueries({
                queryKey: [_userManagement.KEYCLOAK_URL_USERS],
                exact: true
              });

            case 5:
              return _context.abrupt("return", _context.sent);

            case 8:
              _context.prev = 8;
              _context.t0 = _context["catch"](2);
              return _context.abrupt("return", (0, _notifications.sendErrorNotification)(t('Failed to update data, please refresh the page to see the most recent changes')));

            case 11:
              _context.prev = 11;
              afterActions === null || afterActions === void 0 ? void 0 : afterActions();
              return _context.finish(11);

            case 14:
            case "end":
              return _context.stop();
          }
        }
      }, _callee, null, [[2, 8, 11, 14]]);
    }))
  }, _react["default"].createElement(_antd.Button, {
    "data-testid": "delete-user",
    danger: true,
    type: "link"
  }, t('Delete')));
};

exports.UserDeleteBtn = UserDeleteBtn;