"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getResourceDetailsProps = exports.defaultSearchParamsFactory = void 0;
exports.queryParamsFactory = queryParamsFactory;
exports.sidePreviewDetailsExtractor = sidePreviewDetailsExtractor;

var _regenerator = _interopRequireDefault(require("@babel/runtime/regenerator"));

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));

var _react = _interopRequireDefault(require("react"));

var _reactUtils = require("@opensrp/react-utils");

var _antd = require("antd");

var _icons = require("@ant-design/icons");

var _reactRouterDom = require("react-router-dom");

var _constants = require("../../constants");

var _Patient = require("./ResourceSchema/Patient");

var _Condition = require("./ResourceSchema/Condition");

var _CarePlan = require("./ResourceSchema/CarePlan");

var _Immunization = require("./ResourceSchema/Immunization");

var _Encounter = require("./ResourceSchema/Encounter");

var _Task = require("./ResourceSchema/Task");

function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);

  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);
    enumerableOnly && (symbols = symbols.filter(function (sym) {
      return Object.getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }

  return keys;
}

function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      (0, _defineProperty2["default"])(target, key, source[key]);
    }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
    });
  }

  return target;
}

var defaultSearchParamsFactory = function defaultSearchParamsFactory(resourceId) {
  return {
    'subject:Patient': resourceId
  };
};

exports.defaultSearchParamsFactory = defaultSearchParamsFactory;

function queryParamsFactory(fhirBaseURL, resourceType, resourceId) {
  var resourceQueryParams = {
    queryKey: [resourceType, resourceId],
    queryFn: function () {
      var _queryFn = (0, _asyncToGenerator2["default"])(_regenerator["default"].mark(function _callee() {
        return _regenerator["default"].wrap(function _callee$(_context) {
          while (1) {
            switch (_context.prev = _context.next) {
              case 0:
                return _context.abrupt("return", new _reactUtils.FHIRServiceClass(fhirBaseURL, resourceType).read(resourceId));

              case 1:
              case "end":
                return _context.stop();
            }
          }
        }, _callee);
      }));

      function queryFn() {
        return _queryFn.apply(this, arguments);
      }

      return queryFn;
    }()
  };
  return resourceQueryParams;
}

function sidePreviewDetailsExtractor(patientId, dataExtractor, cancelHanlder) {
  return function (resource, t) {
    var resourceType = resource.resourceType,
        id = resource.id;
    var data = dataExtractor(resource, t);

    var headerActions = _react["default"].createElement(_antd.Button, {
      "data-testid": "close-button",
      icon: _react["default"].createElement(_icons.CloseOutlined, null),
      shape: "circle",
      type: "text",
      size: "small",
      onClick: function onClick() {
        return cancelHanlder();
      }
    });

    return _objectSpread({
      headerActions: headerActions,
      footer: _react["default"].createElement(_reactRouterDom.Link, {
        to: "".concat(_constants.LIST_PATIENTS_URL, "/").concat(patientId, "/").concat(resourceType, "/").concat(id)
      }, t('View full details'))
    }, data);
  };
}

var getResourceDetailsProps = function getResourceDetailsProps(resourceType) {
  var _resourceDetailProps;

  var resourceDetailProps = (_resourceDetailProps = {}, (0, _defineProperty2["default"])(_resourceDetailProps, _constants.carePlanResourceType, _CarePlan.carePlanDetailsProps), (0, _defineProperty2["default"])(_resourceDetailProps, _constants.patientResourceType, _Patient.patientDetailsProps), (0, _defineProperty2["default"])(_resourceDetailProps, _constants.conditionResourceType, _Condition.conditionDetailsProps), (0, _defineProperty2["default"])(_resourceDetailProps, _constants.immunizationResourceType, _Immunization.immunizationDetailProps), (0, _defineProperty2["default"])(_resourceDetailProps, _constants.encounterResourceType, _Encounter.encounterDetailProps), (0, _defineProperty2["default"])(_resourceDetailProps, _constants.taskResourceType, _Task.taskDetailsProps), _resourceDetailProps);
  var targetResourceDetailProps = resourceDetailProps[resourceType];
  return targetResourceDetailProps !== null && targetResourceDetailProps !== void 0 ? targetResourceDetailProps : _Patient.patientDetailsProps;
};

exports.getResourceDetailsProps = getResourceDetailsProps;