"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TabsTitle = void 0;

var _regenerator = _interopRequireDefault(require("@babel/runtime/regenerator"));

var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _react = _interopRequireDefault(require("react"));

var _dataLoaders = require("../../../helpers/dataLoaders");

var _reactQuery = require("react-query");

var _antd = require("antd");

require("./index.css");

function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);

  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);
    enumerableOnly && (symbols = symbols.filter(function (sym) {
      return Object.getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }

  return keys;
}

function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      (0, _defineProperty2["default"])(target, key, source[key]);
    }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
    });
  }

  return target;
}

var TabsTitle = function TabsTitle(props) {
  var _data$total;

  var title = props.title,
      resourceType = props.resourceType,
      fhirBaseURL = props.fhirBaseURL,
      resourceFilters = props.resourceFilters,
      hasResourcePermissions = props.hasResourcePermissions;

  var summaryFilters = _objectSpread({
    _summary: 'count'
  }, resourceFilters);

  var filterString = Object.values(summaryFilters).join(',');

  var _useQuery = (0, _reactQuery.useQuery)({
    queryKey: [resourceType, filterString],
    queryFn: function () {
      var _queryFn = (0, _asyncToGenerator2["default"])(_regenerator["default"].mark(function _callee() {
        return _regenerator["default"].wrap(function _callee$(_context) {
          while (1) {
            switch (_context.prev = _context.next) {
              case 0:
                _context.next = 2;
                return new _dataLoaders.FHIRServiceClass(fhirBaseURL, resourceType).list(summaryFilters);

              case 2:
                return _context.abrupt("return", _context.sent);

              case 3:
              case "end":
                return _context.stop();
            }
          }
        }, _callee);
      }));

      function queryFn() {
        return _queryFn.apply(this, arguments);
      }

      return queryFn;
    }(),
    enabled: hasResourcePermissions
  }),
      data = _useQuery.data,
      error = _useQuery.error,
      isLoading = _useQuery.isLoading;

  if (isLoading) {
    return _react["default"].createElement(_antd.Spin, {
      size: "small"
    });
  }

  if (error || !data) {
    return _react["default"].createElement("div", null, title);
  }

  var count = (_data$total = data.total) !== null && _data$total !== void 0 ? _data$total : 0;
  return _react["default"].createElement("div", null, title, " ", _react["default"].createElement(_antd.Badge, {
    count: count,
    showZero: true
  }));
};

exports.TabsTitle = TabsTitle;
var defaultProps = {
  hasResourcePermissions: true
};
TabsTitle.defaultProps = defaultProps;