"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.valuesetResourceType = exports.unitOfMeasure = exports.unicefSection = exports.type = exports.serialNumber = exports.quantity = exports.productImage = exports.product = exports.name = exports.materialNumber = exports.locationResourceType = exports.listResourceType = exports.isAttractiveItem = exports.identifier = exports.id = exports.groupResourceType = exports.expiryDate = exports.donor = exports.deliveryDate = exports.condition = exports.binaryResourceType = exports.availability = exports.appropriateUsage = exports.actual = exports.active = exports.accountabilityPeriod = exports.accountabilityEndDate = exports.PONumber = exports.LIST_GROUP_URL = exports.LIST_COMMODITY_URL = exports.ADD_LOCATION_INVENTORY = exports.ADD_EDIT_GROUP_URL = exports.ADD_EDIT_COMMODITY_URL = void 0;
var ADD_EDIT_GROUP_URL = '/group/add-edit';
exports.ADD_EDIT_GROUP_URL = ADD_EDIT_GROUP_URL;
var ADD_EDIT_COMMODITY_URL = '/commodity/add-edit';
exports.ADD_EDIT_COMMODITY_URL = ADD_EDIT_COMMODITY_URL;
var LIST_GROUP_URL = '/groups/list';
exports.LIST_GROUP_URL = LIST_GROUP_URL;
var LIST_COMMODITY_URL = '/commodity/list';
exports.LIST_COMMODITY_URL = LIST_COMMODITY_URL;
var ADD_LOCATION_INVENTORY = '/location/inventory';
exports.ADD_LOCATION_INVENTORY = ADD_LOCATION_INVENTORY;
var groupResourceType = 'Group';
exports.groupResourceType = groupResourceType;
var listResourceType = 'List';
exports.listResourceType = listResourceType;
var binaryResourceType = 'Binary';
exports.binaryResourceType = binaryResourceType;
var valuesetResourceType = 'ValueSet';
exports.valuesetResourceType = valuesetResourceType;
var locationResourceType = 'Location';
exports.locationResourceType = locationResourceType;
var id = 'id';
exports.id = id;
var identifier = 'identifier';
exports.identifier = identifier;
var name = 'name';
exports.name = name;
var active = 'active';
exports.active = active;
var type = 'type';
exports.type = type;
var unitOfMeasure = 'unitOfMeasure';
exports.unitOfMeasure = unitOfMeasure;
var materialNumber = 'materialNumber';
exports.materialNumber = materialNumber;
var isAttractiveItem = 'isAttractiveItem';
exports.isAttractiveItem = isAttractiveItem;
var availability = 'availability';
exports.availability = availability;
var condition = 'condition';
exports.condition = condition;
var appropriateUsage = 'appropriateUsage';
exports.appropriateUsage = appropriateUsage;
var accountabilityPeriod = 'accountabilityPeriod';
exports.accountabilityPeriod = accountabilityPeriod;
var productImage = 'productImage';
exports.productImage = productImage;
var product = 'product';
exports.product = product;
var quantity = 'quantity';
exports.quantity = quantity;
var deliveryDate = 'deliveryDate';
exports.deliveryDate = deliveryDate;
var accountabilityEndDate = 'accountabilityEndDate';
exports.accountabilityEndDate = accountabilityEndDate;
var expiryDate = 'expiryDate';
exports.expiryDate = expiryDate;
var unicefSection = 'unicefSection';
exports.unicefSection = unicefSection;
var serialNumber = 'serialNumber';
exports.serialNumber = serialNumber;
var donor = 'donor';
exports.donor = donor;
var PONumber = 'poNumber';
exports.PONumber = PONumber;
var actual = 'actual';
exports.actual = actual;