"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ClientSideActionsBaseListView = ClientSideActionsBaseListView;

var _extends2 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _react = _interopRequireDefault(require("react"));

var _reactHelmet = require("react-helmet");

var _antd = require("antd");

var _reactUtils = require("@opensrp/react-utils");

var _GroupDetail = require("../GroupDetail");

var _constants = require("../../../constants");

var _mls = require("../../../mls");

function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);

  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);
    enumerableOnly && (symbols = symbols.filter(function (sym) {
      return Object.getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }

  return keys;
}

function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      (0, _defineProperty2["default"])(target, key, source[key]);
    }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
    });
  }

  return target;
}

function ClientSideActionsBaseListView(props) {
  var _sParams$get, _viewDetailsRender;

  var fhirBaseURL = props.fhirBaseURL,
      extraQueryFilters = props.extraQueryFilters,
      filterRowRender = props.filterRowRender,
      getColumns = props.getColumns,
      addGroupBtnRender = props.addGroupBtnRender,
      keyValueMapperRenderProp = props.keyValueMapperRenderProp,
      pageTitle = props.pageTitle,
      viewDetailsRender = props.viewDetailsRender,
      dataTransformer = props.dataTransformer;

  var _useSearchParams = (0, _reactUtils.useSearchParams)(),
      sParams = _useSearchParams.sParams;

  var resourceId = (_sParams$get = sParams.get(_reactUtils.viewDetailsQuery)) !== null && _sParams$get !== void 0 ? _sParams$get : undefined;

  var _useTranslation = (0, _mls.useTranslation)(),
      t = _useTranslation.t;

  var _useClientSideActions = (0, _reactUtils.useClientSideActionsDataGrid)(fhirBaseURL, _constants.groupResourceType, extraQueryFilters, dataTransformer),
      _useClientSideActions2 = _useClientSideActions.queryValues,
      data = _useClientSideActions2.data,
      isFetching = _useClientSideActions2.isFetching,
      isLoading = _useClientSideActions2.isLoading,
      error = _useClientSideActions2.error,
      tablePaginationProps = _useClientSideActions.tablePaginationProps,
      searchFormProps = _useClientSideActions.searchFormProps,
      _useClientSideActions3 = _useClientSideActions.filterOptions,
      registerFilter = _useClientSideActions3.registerFilter,
      filterRegistry = _useClientSideActions3.filterRegistry,
      deregisterFilter = _useClientSideActions3.deregisterFilter;

  if (error && !data.length) {
    return _react["default"].createElement(_reactUtils.BrokenPage, {
      errorMessage: error.message
    });
  }

  var tableData = data;
  var columns = getColumns(t);
  var tableProps = {
    datasource: tableData,
    columns: columns,
    loading: isFetching || isLoading,
    pagination: tablePaginationProps
  };
  var headerProps = {
    pageHeaderProps: {
      title: pageTitle,
      onBack: undefined
    }
  };
  var nameFilterKey = 'name';

  var searchInputProps = _objectSpread(_objectSpread({}, searchFormProps), {}, {
    wrapperClassName: 'elongate-search-bar',
    onChangeHandler: function onChangeHandler(event) {
      searchFormProps.onChangeHandler(event);
      var searchText = event.target.value;

      if (searchText) {
        registerFilter(nameFilterKey, function (el) {
          var _el$name;

          return ((_el$name = el.name) !== null && _el$name !== void 0 ? _el$name : '').toLowerCase().includes(searchText.toLowerCase());
        }, searchText);
      } else {
        deregisterFilter(nameFilterKey);
      }
    }
  });

  return _react["default"].createElement(_reactUtils.BodyLayout, {
    headerProps: headerProps
  }, _react["default"].createElement(_reactHelmet.Helmet, null, _react["default"].createElement("title", null, pageTitle)), _react["default"].createElement(_antd.Row, {
    className: "list-view"
  }, _react["default"].createElement(_antd.Col, {
    className: "main-content"
  }, _react["default"].createElement("div", {
    className: "main-content__header"
  }, _react["default"].createElement(_reactUtils.SearchForm, (0, _extends2["default"])({
    "data-testid": "search-form"
  }, searchInputProps)), addGroupBtnRender === null || addGroupBtnRender === void 0 ? void 0 : addGroupBtnRender()), filterRowRender === null || filterRowRender === void 0 ? void 0 : filterRowRender(registerFilter, filterRegistry), _react["default"].createElement(_reactUtils.TableLayout, tableProps)), (_viewDetailsRender = viewDetailsRender === null || viewDetailsRender === void 0 ? void 0 : viewDetailsRender(fhirBaseURL, resourceId)) !== null && _viewDetailsRender !== void 0 ? _viewDetailsRender : _react["default"].createElement(_GroupDetail.ViewDetailsWrapper, {
    resourceId: resourceId,
    fhirBaseURL: fhirBaseURL,
    keyValueMapperRenderProp: keyValueMapperRenderProp
  })));
}