"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ImportStatusTag = void 0;
exports.getStatusColor = getStatusColor;

var _react = _interopRequireDefault(require("react"));

var _antd = require("antd");

var ImportStatusTag = function ImportStatusTag(_ref) {
  var statusString = _ref.statusString;
  var tagStatusColor = getStatusColor(statusString);
  return _react["default"].createElement(_antd.Tag, {
    color: tagStatusColor
  }, statusString);
};

exports.ImportStatusTag = ImportStatusTag;

function getStatusColor(statusString) {
  switch (statusString) {
    case 'completed':
      return 'success';

    case 'active':
      return 'processing';

    case 'failed':
      return 'error';

    case 'paused':
      return 'warning';

    default:
      return 'default';
  }
}