"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.removeGroupFromUser = exports.KeycloakGroupDetails = void 0;

var _regenerator = _interopRequireDefault(require("@babel/runtime/regenerator"));

var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));

var _react = _interopRequireDefault(require("react"));

var _antd = require("antd");

var _mls = require("../../../../mls");

var _reactUtils = require("@opensrp/react-utils");

var _userManagement = require("@opensrp/user-management");

var _keycloakService = require("@opensrp/keycloak-service");

var _reactQuery = require("react-query");

var _notifications = require("@opensrp/notifications");

var _rbac = require("@opensrp/rbac");

var KeycloakGroupDetails = function KeycloakGroupDetails(props) {
  var _useTranslation = (0, _mls.useTranslation)(),
      t = _useTranslation.t;

  var keycloakBaseUrl = props.keycloakBaseUrl,
      resourceId = props.resourceId;
  var query = (0, _reactQuery.useQueryClient)();

  var _useQuery = (0, _reactQuery.useQuery)([_userManagement.KEYCLOAK_URL_USERS, _userManagement.KEYCLOAK_URL_USER_GROUPS], function () {
    return new _keycloakService.KeycloakService("".concat(_userManagement.KEYCLOAK_URL_USERS, "/").concat(resourceId).concat(_userManagement.KEYCLOAK_URL_USER_GROUPS), keycloakBaseUrl).list();
  }),
      data = _useQuery.data,
      error = _useQuery.error,
      isLoading = _useQuery.isLoading;

  if (error && !data) {
    return _react["default"].createElement(_antd.Alert, {
      type: "error"
    }, t('An error occurred while fetching user groups that the user is assigned to'));
  }

  var columns = [{
    title: t('Name'),
    dataIndex: 'name'
  }, {
    title: t('Path'),
    dataIndex: 'path'
  }, {
    title: t('Actions'),
    dataIndex: 'id',
    render: function render(id) {
      return _react["default"].createElement(_rbac.RbacCheck, {
        permissions: ['iam_user.update', 'Group.update']
      }, _react["default"].createElement(_antd.Button, {
        onClick: function onClick() {
          return removeGroupFromUser(keycloakBaseUrl, id, resourceId, query, t);
        },
        type: "link",
        danger: true
      }, t('Leave')));
    }
  }];
  var tableProps = {
    datasource: data !== null && data !== void 0 ? data : [],
    columns: columns,
    loading: isLoading,
    size: 'small'
  };
  return _react["default"].createElement(_reactUtils.TableLayout, tableProps);
};

exports.KeycloakGroupDetails = KeycloakGroupDetails;

var removeGroupFromUser = function () {
  var _ref = (0, _asyncToGenerator2["default"])(_regenerator["default"].mark(function _callee(baseUrl, groupId, userId, query, t) {
    var endpoint, server;
    return _regenerator["default"].wrap(function _callee$(_context) {
      while (1) {
        switch (_context.prev = _context.next) {
          case 0:
            endpoint = "".concat(_userManagement.KEYCLOAK_URL_USERS, "/").concat(userId).concat(_userManagement.KEYCLOAK_URL_USER_GROUPS, "/").concat(groupId);
            server = new _keycloakService.KeycloakService(endpoint, baseUrl);
            return _context.abrupt("return", server["delete"]().then(function () {
              query.refetchQueries([_userManagement.KEYCLOAK_URL_USERS, _userManagement.KEYCLOAK_URL_USER_GROUPS])["catch"](function () {
                (0, _notifications.sendInfoNotification)(t('Failed to refresh data, please refresh the page'));
              });
              (0, _notifications.sendSuccessNotification)(t('User has been successfully removed from the keycloak group'));
            }));

          case 3:
          case "end":
            return _context.stop();
        }
      }
    }, _callee);
  }));

  return function removeGroupFromUser(_x, _x2, _x3, _x4, _x5) {
    return _ref.apply(this, arguments);
  };
}();

exports.removeGroupFromUser = removeGroupFromUser;