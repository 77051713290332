"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

var _typeof = require("@babel/runtime/helpers/typeof");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getTableColumns = void 0;

var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var React = _interopRequireWildcard(require("react"));

var _antd = require("antd");

var _icons = require("@ant-design/icons");

var _reactRouterDom = require("react-router-dom");

var _userManagement = require("@opensrp/user-management");

var _reactUtils = require("@opensrp/react-utils");

var _rbac = require("@opensrp/rbac");

var _UserDeleteBtn = require("../../UserDeleteBtn");

var _excluded = ["permissions"];

function _getRequireWildcardCache(nodeInterop) {
  if (typeof WeakMap !== "function") return null;
  var cacheBabelInterop = new WeakMap();
  var cacheNodeInterop = new WeakMap();
  return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) {
    return nodeInterop ? cacheNodeInterop : cacheBabelInterop;
  })(nodeInterop);
}

function _interopRequireWildcard(obj, nodeInterop) {
  if (!nodeInterop && obj && obj.__esModule) {
    return obj;
  }

  if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") {
    return {
      "default": obj
    };
  }

  var cache = _getRequireWildcardCache(nodeInterop);

  if (cache && cache.has(obj)) {
    return cache.get(obj);
  }

  var newObj = {};
  var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor;

  for (var key in obj) {
    if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) {
      var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null;

      if (desc && (desc.get || desc.set)) {
        Object.defineProperty(newObj, key, desc);
      } else {
        newObj[key] = obj[key];
      }
    }
  }

  newObj["default"] = obj;

  if (cache) {
    cache.set(obj, newObj);
  }

  return newObj;
}

var getTableColumns = function getTableColumns(keycloakBaseUrl, baseUrl, extraData, t, userRole, history) {
  var user_id = extraData.user_id;
  var headerItems = [t('First name'), t('Last name'), t('Username')];
  var dataElements = [];
  var fields = ['firstName', 'lastName', 'username'];
  fields.forEach(function (field, index) {
    dataElements.push({
      title: headerItems[index],
      dataIndex: field,
      key: field,
      sorter: function sorter(a, b) {
        if (a[field] > b[field]) return -1;else if (a[field] < b[field]) return 1;
        return 0;
      },
      ellipsis: true
    });
  });

  var getItems = function getItems(record) {
    var viewDetailsOverviewSParams = new URLSearchParams((0, _defineProperty2["default"])({}, _reactUtils.viewDetailsQuery, record.id));
    var items = [{
      key: '1',
      permissions: ['iam_user.read'],
      label: React.createElement(_antd.Button, {
        onClick: function onClick() {
          return history.push("".concat(_userManagement.URL_USER, "?").concat(viewDetailsOverviewSParams.toString()));
        },
        type: "link"
      }, t('View Details'))
    }, {
      key: '3',
      permissions: ['iam_user.update', 'Practitioner.update'],
      label: React.createElement(_antd.Button, {
        type: "link",
        "data-testid": "credentials",
        onClick: function onClick() {
          history.push("".concat(_userManagement.URL_USER_CREDENTIALS, "/").concat(record.id, "/").concat(record.username));
        }
      }, t('Credentials'))
    }];

    if (user_id && record.id !== user_id) {
      items.push({
        key: '2',
        permissions: ['iam_user.delete', 'Practitioner.delete'],
        label: React.createElement(_UserDeleteBtn.UserDeleteBtn, {
          keycloakBaseUrl: keycloakBaseUrl,
          fhirBaseUrl: baseUrl,
          resourceId: record.id
        })
      });
    }

    return items.filter(function (item) {
      return userRole.hasPermissions(item.permissions);
    }).map(function (item) {
      var permissions = item.permissions,
          rest = (0, _objectWithoutProperties2["default"])(item, _excluded);
      return rest;
    });
  };

  dataElements.push({
    title: t('Actions'),
    width: '120px',
    render: function render(_, record) {
      var items = getItems(record);
      return React.createElement(React.Fragment, null, React.createElement(_rbac.RbacCheck, {
        permissions: ['iam_user.update', 'Practitioner.update']
      }, React.createElement(React.Fragment, null, React.createElement(_reactRouterDom.Link, {
        to: "".concat(_userManagement.URL_USER_EDIT, "/").concat(record.id),
        key: "actions"
      }, t('Edit')), React.createElement(_antd.Divider, {
        type: "vertical"
      }))), React.createElement(_antd.Dropdown, {
        placement: "bottomRight",
        arrow: true,
        trigger: ['click'],
        menu: {
          items: items
        },
        disabled: !items || items.length < 1
      }, React.createElement(_icons.MoreOutlined, {
        "data-testid": "action-dropdown",
        className: "more-options"
      })));
    }
  });
  return dataElements;
};

exports.getTableColumns = getTableColumns;