"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.conditionCharacteristicCoding = exports.conditionCharacteristicCode = exports.conditionCharacteristic = exports.characteristicUnitMeasureCode = exports.availabilityCharacteristicCoding = exports.availabilityCharacteristicCode = exports.availabilityCharacteristic = exports.attractiveCharacteristicCoding = exports.attractiveCharacteristicCode = exports.attractiveCharacteristic = exports.appropriateUsageCharacteristicCoding = exports.appropriateUsageCharacteristicCode = exports.appropriateUsageCharacteristic = exports.accountabilityCharacteristicCoding = exports.accountabilityCharacteristicCode = exports.accountabilityCharacteristic = void 0;
exports.getCharacteristicWithCoding = getCharacteristicWithCoding;
exports.unitOfMeasureCharacteristicCoding = exports.unitOfMeasureCharacteristic = exports.supplyMgSnomedCode = exports.snomedCodeSystem = exports.smartRegisterCodeSystem = exports.photoUploadCharacteristicCoding = exports.photoUploadCharacteristicCode = exports.getUnitMeasureCharacteristic = void 0;
exports.useGetGroupAndBinary = useGetGroupAndBinary;

var _reactUtils = require("@opensrp/react-utils");

var _constants = require("../constants");

var _lodash = require("lodash");

var _reactQuery = require("react-query");

function _createForOfIteratorHelper(o, allowArrayLike) {
  var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"];

  if (!it) {
    if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") {
      if (it) o = it;
      var i = 0;

      var F = function F() {};

      return {
        s: F,
        n: function n() {
          if (i >= o.length) return {
            done: true
          };
          return {
            done: false,
            value: o[i++]
          };
        },
        e: function e(_e) {
          throw _e;
        },
        f: F
      };
    }

    throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
  }

  var normalCompletion = true,
      didErr = false,
      err;
  return {
    s: function s() {
      it = it.call(o);
    },
    n: function n() {
      var step = it.next();
      normalCompletion = step.done;
      return step;
    },
    e: function e(_e2) {
      didErr = true;
      err = _e2;
    },
    f: function f() {
      try {
        if (!normalCompletion && it["return"] != null) it["return"]();
      } finally {
        if (didErr) throw err;
      }
    }
  };
}

function _unsupportedIterableToArray(o, minLen) {
  if (!o) return;
  if (typeof o === "string") return _arrayLikeToArray(o, minLen);
  var n = Object.prototype.toString.call(o).slice(8, -1);
  if (n === "Object" && o.constructor) n = o.constructor.name;
  if (n === "Map" || n === "Set") return Array.from(o);
  if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen);
}

function _arrayLikeToArray(arr, len) {
  if (len == null || len > arr.length) len = arr.length;

  for (var i = 0, arr2 = new Array(len); i < len; i++) {
    arr2[i] = arr[i];
  }

  return arr2;
}

var getUnitMeasureCharacteristic = function getUnitMeasureCharacteristic(obj) {
  var _obj$characteristic;

  var _iterator = _createForOfIteratorHelper((_obj$characteristic = obj.characteristic) !== null && _obj$characteristic !== void 0 ? _obj$characteristic : []),
      _step;

  try {
    for (_iterator.s(); !(_step = _iterator.n()).done;) {
      var _characteristic$code$;

      var characteristic = _step.value;
      var characteristicCoding = (_characteristic$code$ = characteristic.code.coding) !== null && _characteristic$code$ !== void 0 ? _characteristic$code$ : [];

      var _iterator2 = _createForOfIteratorHelper(characteristicCoding),
          _step2;

      try {
        for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
          var _coding$system;

          var coding = _step2.value;

          if (((_coding$system = coding.system) === null || _coding$system === void 0 ? void 0 : _coding$system.toLowerCase()) === snomedCodeSystem.toLowerCase() && coding.code === characteristicUnitMeasureCode) {
            return characteristic;
          }
        }
      } catch (err) {
        _iterator2.e(err);
      } finally {
        _iterator2.f();
      }
    }
  } catch (err) {
    _iterator.e(err);
  } finally {
    _iterator.f();
  }
};

exports.getUnitMeasureCharacteristic = getUnitMeasureCharacteristic;
var snomedCodeSystem = 'http://snomed.info/sct';
exports.snomedCodeSystem = snomedCodeSystem;
var smartRegisterCodeSystem = 'http://smartregister.org/codes';
exports.smartRegisterCodeSystem = smartRegisterCodeSystem;
var supplyMgSnomedCode = '386452003';
exports.supplyMgSnomedCode = supplyMgSnomedCode;
var characteristicUnitMeasureCode = '767524001';
exports.characteristicUnitMeasureCode = characteristicUnitMeasureCode;
var photoUploadCharacteristicCode = '1231415';
exports.photoUploadCharacteristicCode = photoUploadCharacteristicCode;
var accountabilityCharacteristicCode = '67869606';
exports.accountabilityCharacteristicCode = accountabilityCharacteristicCode;
var appropriateUsageCharacteristicCode = '56758595';
exports.appropriateUsageCharacteristicCode = appropriateUsageCharacteristicCode;
var conditionCharacteristicCode = '45647484';
exports.conditionCharacteristicCode = conditionCharacteristicCode;
var availabilityCharacteristicCode = '34536373';
exports.availabilityCharacteristicCode = availabilityCharacteristicCode;
var attractiveCharacteristicCode = '23435363';
exports.attractiveCharacteristicCode = attractiveCharacteristicCode;
var photoUploadCharacteristicCoding = {
  system: smartRegisterCodeSystem,
  code: photoUploadCharacteristicCode,
  display: 'Product Image code'
};
exports.photoUploadCharacteristicCoding = photoUploadCharacteristicCoding;
var accountabilityCharacteristicCoding = {
  system: smartRegisterCodeSystem,
  code: accountabilityCharacteristicCode,
  display: 'Accountability period (in months)'
};
exports.accountabilityCharacteristicCoding = accountabilityCharacteristicCoding;
var appropriateUsageCharacteristicCoding = {
  system: smartRegisterCodeSystem,
  code: appropriateUsageCharacteristicCode,
  display: 'Is it being used appropriately? (optional)'
};
exports.appropriateUsageCharacteristicCoding = appropriateUsageCharacteristicCoding;
var conditionCharacteristicCoding = {
  system: smartRegisterCodeSystem,
  code: conditionCharacteristicCode,
  display: 'Is it in good condition? (optional)'
};
exports.conditionCharacteristicCoding = conditionCharacteristicCoding;
var availabilityCharacteristicCoding = {
  system: smartRegisterCodeSystem,
  code: availabilityCharacteristicCode,
  display: 'Is it there code'
};
exports.availabilityCharacteristicCoding = availabilityCharacteristicCoding;
var attractiveCharacteristicCoding = {
  system: smartRegisterCodeSystem,
  code: attractiveCharacteristicCode,
  display: 'Attractive Item code'
};
exports.attractiveCharacteristicCoding = attractiveCharacteristicCoding;
var unitOfMeasureCharacteristicCoding = {
  system: snomedCodeSystem,
  code: '767524001',
  display: 'Unit of measure'
};
exports.unitOfMeasureCharacteristicCoding = unitOfMeasureCharacteristicCoding;
var unitOfMeasureCharacteristic = {
  code: {
    coding: [unitOfMeasureCharacteristicCoding]
  },
  valueCodeableConcept: {
    coding: [{
      system: snomedCodeSystem,
      code: '767525000',
      display: 'Unit'
    }],
    text: undefined
  }
};
exports.unitOfMeasureCharacteristic = unitOfMeasureCharacteristic;
var accountabilityCharacteristic = {
  code: {
    coding: [accountabilityCharacteristicCoding]
  },
  valueQuantity: {
    value: undefined
  }
};
exports.accountabilityCharacteristic = accountabilityCharacteristic;
var appropriateUsageCharacteristic = {
  code: {
    coding: [appropriateUsageCharacteristicCoding]
  },
  valueCodeableConcept: {
    coding: [{
      system: smartRegisterCodeSystem,
      code: '56758595-1',
      display: 'Value entered on the Is it being used appropriately? (optional)'
    }],
    text: undefined
  }
};
exports.appropriateUsageCharacteristic = appropriateUsageCharacteristic;
var conditionCharacteristic = {
  code: {
    coding: [conditionCharacteristicCoding]
  },
  valueCodeableConcept: {
    coding: [{
      system: smartRegisterCodeSystem,
      code: '45647484-1',
      display: 'Value entered on the Is it in good condition? (optional)'
    }],
    text: undefined
  }
};
exports.conditionCharacteristic = conditionCharacteristic;
var availabilityCharacteristic = {
  code: {
    coding: [availabilityCharacteristicCoding]
  },
  valueCodeableConcept: {
    coding: [{
      system: smartRegisterCodeSystem,
      code: '34536373-1',
      display: 'Value entered on the It is there code'
    }],
    text: undefined
  }
};
exports.availabilityCharacteristic = availabilityCharacteristic;
var attractiveCharacteristic = {
  code: {
    coding: [attractiveCharacteristicCoding]
  },
  valueBoolean: undefined
};
exports.attractiveCharacteristic = attractiveCharacteristic;

function getCharacteristicWithCoding(characteristics, coding) {
  var _iterator3 = _createForOfIteratorHelper(characteristics),
      _step3;

  try {
    for (_iterator3.s(); !(_step3 = _iterator3.n()).done;) {
      var _characteristic$code$2;

      var characteristic = _step3.value;
      var codings = (_characteristic$code$2 = characteristic.code.coding) !== null && _characteristic$code$2 !== void 0 ? _characteristic$code$2 : [];

      var _iterator4 = _createForOfIteratorHelper(codings),
          _step4;

      try {
        for (_iterator4.s(); !(_step4 = _iterator4.n()).done;) {
          var thisCoding = _step4.value;

          if ((0, _lodash.isEqual)(thisCoding, coding)) {
            return characteristic;
          }
        }
      } catch (err) {
        _iterator4.e(err);
      } finally {
        _iterator4.f();
      }
    }
  } catch (err) {
    _iterator3.e(err);
  } finally {
    _iterator3.f();
  }
}

function useGetGroupAndBinary(baseUrl, resourceId) {
  var _groupQuery$data$char, _groupQuery$data, _photoCharacteristic$, _photoCharacteristic$2;

  var groupQuery = (0, _reactQuery.useQuery)([_constants.groupResourceType, resourceId], function () {
    return new _reactUtils.FHIRServiceClass(baseUrl, _constants.groupResourceType).read(resourceId);
  }, {
    enabled: !!resourceId
  });
  var photoCharacteristic = getCharacteristicWithCoding((_groupQuery$data$char = (_groupQuery$data = groupQuery.data) === null || _groupQuery$data === void 0 ? void 0 : _groupQuery$data.characteristic) !== null && _groupQuery$data$char !== void 0 ? _groupQuery$data$char : [], photoUploadCharacteristicCoding);
  var binaryReference = (_photoCharacteristic$ = photoCharacteristic === null || photoCharacteristic === void 0 ? void 0 : (_photoCharacteristic$2 = photoCharacteristic.valueReference) === null || _photoCharacteristic$2 === void 0 ? void 0 : _photoCharacteristic$2.reference) !== null && _photoCharacteristic$ !== void 0 ? _photoCharacteristic$ : '';
  var referenceParts = binaryReference.split('/');
  var referenceId = referenceParts[referenceParts.length - 1];
  var binaryQuery = (0, _reactQuery.useQuery)([_constants.binaryResourceType, resourceId], function () {
    return new _reactUtils.FHIRServiceClass(baseUrl, _constants.binaryResourceType).read(referenceId);
  }, {
    enabled: !!referenceId,
    cacheTime: 0
  });
  return {
    groupQuery: groupQuery,
    binaryQuery: binaryQuery
  };
}