"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CodeableConcept = void 0;

var _antd = require("antd");

var _Coding = require("../Coding");

var _react = _interopRequireDefault(require("react"));

var Text = _antd.Typography.Text;

var CodeableConcept = function CodeableConcept(props) {
  var concept = props.concept;
  var coding = concept.coding,
      text = concept.text;

  var codingsTitle = _react["default"].createElement(_react["default"].Fragment, null, (coding !== null && coding !== void 0 ? coding : []).map(function (coding, index) {
    return _react["default"].createElement(_react["default"].Fragment, null, _react["default"].createElement(_Coding.Coding, {
      key: "coding-".concat(index),
      coding: coding
    }), ",", ' ');
  }));

  return _react["default"].createElement(_antd.Tooltip, {
    title: codingsTitle,
    color: "white"
  }, text ? _react["default"].createElement(Text, null, text) : codingsTitle);
};

exports.CodeableConcept = CodeableConcept;