"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CommodityAddEdit = void 0;

var _regenerator = _interopRequireDefault(require("@babel/runtime/regenerator"));

var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));

var _react = _interopRequireDefault(require("react"));

var _reactHelmet = require("react-helmet");

var _ProductForm = require("../../ProductForm");

var _reactRouter = require("react-router");

var _constants = require("../../../constants");

var _antd = require("antd");

var _reactUtils = require("@opensrp/react-utils");

var _utils = require("./utils");

var _mls = require("../../../mls");

var _utils2 = require("../../../helpers/utils");

var CommodityAddEdit = function CommodityAddEdit(props) {
  var _groupQuery$data$name;

  var fhirBaseUrl = props.fhirBaseURL,
      listId = props.listId;

  var _useParams = (0, _reactRouter.useParams)(),
      resourceId = _useParams.id;

  var _useTranslation = (0, _mls.useTranslation)(),
      t = _useTranslation.t;

  var _useGetGroupAndBinary = (0, _utils2.useGetGroupAndBinary)(fhirBaseUrl, resourceId),
      groupQuery = _useGetGroupAndBinary.groupQuery,
      binaryQuery = _useGetGroupAndBinary.binaryQuery;

  if (!groupQuery.isIdle && groupQuery.isLoading || !binaryQuery.isIdle && binaryQuery.isLoading) {
    return _react["default"].createElement(_antd.Spin, {
      size: "large",
      className: "custom-spinner"
    });
  }

  if (groupQuery.error && !groupQuery.data) {
    return _react["default"].createElement(_reactUtils.BrokenPage, {
      errorMessage: groupQuery.error.message
    });
  }

  var initialValues = (0, _utils.getGroupFormFields)(groupQuery.data, binaryQuery.data);
  var pageTitle = groupQuery.data ? t('Edit commodity | {{name}}', {
    name: (_groupQuery$data$name = groupQuery.data.name) !== null && _groupQuery$data$name !== void 0 ? _groupQuery$data$name : ''
  }) : t('Create commodity');
  var postSuccess = (0, _utils.updateListReferencesFactory)(fhirBaseUrl, listId, binaryQuery.data);
  var headerProps = {
    pageHeaderProps: {
      title: pageTitle,
      onBack: undefined
    }
  };
  return _react["default"].createElement(_reactUtils.BodyLayout, {
    headerProps: headerProps
  }, _react["default"].createElement(_reactHelmet.Helmet, null, _react["default"].createElement("title", null, pageTitle)), _react["default"].createElement("div", {
    className: "bg-white p-5"
  }, _react["default"].createElement(_ProductForm.CommodityForm, {
    hidden: [_constants.unitOfMeasure, _constants.type],
    fhirBaseUrl: fhirBaseUrl,
    initialValues: initialValues,
    cancelUrl: _constants.LIST_COMMODITY_URL,
    successUrl: _constants.LIST_COMMODITY_URL,
    postSuccess: postSuccess,
    validationRulesFactory: _utils.validationRulesFactory,
    mutationEffect: function () {
      var _ref = (0, _asyncToGenerator2["default"])(_regenerator["default"].mark(function _callee(initialValues, values) {
        var _yield$generateGroupP, group, binary, binaryChanged, binaryResponse, groupResponse;

        return _regenerator["default"].wrap(function _callee$(_context) {
          while (1) {
            switch (_context.prev = _context.next) {
              case 0:
                _context.next = 2;
                return (0, _utils.generateGroupPayload)(values, initialValues);

              case 2:
                _yield$generateGroupP = _context.sent;
                group = _yield$generateGroupP.group;
                binary = _yield$generateGroupP.binary;
                binaryChanged = _yield$generateGroupP.binaryChanged;

                if (!binary) {
                  _context.next = 10;
                  break;
                }

                _context.next = 9;
                return (0, _utils.postPutBinary)(fhirBaseUrl, binary);

              case 9:
                binaryResponse = _context.sent;

              case 10:
                _context.next = 12;
                return (0, _utils.postPutGroup)(fhirBaseUrl, group);

              case 12:
                groupResponse = _context.sent;
                return _context.abrupt("return", {
                  group: groupResponse,
                  binary: binaryResponse,
                  binaryChanged: binaryChanged
                });

              case 14:
              case "end":
                return _context.stop();
            }
          }
        }, _callee);
      }));

      return function (_x, _x2) {
        return _ref.apply(this, arguments);
      };
    }()
  })));
};

exports.CommodityAddEdit = CommodityAddEdit;