"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ChildLocations = void 0;
exports.parseTableData = parseTableData;

var _extends2 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _react = _interopRequireDefault(require("react"));

var _mls = require("../../../mls");

var _reactUtils = require("@opensrp/react-utils");

var _antd = require("antd");

var _constants = require("../../../constants");

var _rbac = require("@opensrp/rbac");

var _icons = require("@ant-design/icons");

var _reactRouter = require("react-router");

var _reactRouterDom = require("react-router-dom");

var _lodash = require("lodash");

var _EditLink = require("../../EditLink");

function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);

  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);
    enumerableOnly && (symbols = symbols.filter(function (sym) {
      return Object.getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }

  return keys;
}

function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      (0, _defineProperty2["default"])(target, key, source[key]);
    }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
    });
  }

  return target;
}

var searchParamsFactory = function searchParamsFactory(locationId) {
  return function (search) {
    var searchParams = {};

    if (search) {
      searchParams = {
        'name:contains': search
      };
    }

    return _objectSpread(_objectSpread({}, searchParams), {}, {
      partof: locationId
    });
  };
};

var ChildLocations = function ChildLocations(_ref) {
  var _data$records;

  var fhirBaseUrl = _ref.fhirBaseUrl,
      locationId = _ref.locationId;

  var _useMls = (0, _mls.useMls)(),
      t = _useMls.t;

  var history = (0, _reactRouter.useHistory)();
  var location = (0, _reactRouter.useLocation)();
  var searchParams = searchParamsFactory(locationId);

  var _useSimpleTabularView = (0, _reactUtils.useSimpleTabularView)(fhirBaseUrl, _constants.locationResourceType, searchParams),
      _useSimpleTabularView2 = _useSimpleTabularView.queryValues,
      data = _useSimpleTabularView2.data,
      isLoading = _useSimpleTabularView2.isLoading,
      error = _useSimpleTabularView2.error,
      tablePaginationProps = _useSimpleTabularView.tablePaginationProps,
      searchFormProps = _useSimpleTabularView.searchFormProps;

  if (error && !data) {
    return _react["default"].createElement(_antd.Alert, {
      type: "error"
    }, t('An error occurred while fetching child locations'));
  }

  var tableData = parseTableData((_data$records = data === null || data === void 0 ? void 0 : data.records) !== null && _data$records !== void 0 ? _data$records : []);
  var backParamObj = (0, _defineProperty2["default"])({}, _constants.BACK_SEARCH_PARAM, location.pathname);

  var getItems = function getItems(_) {
    return [{
      key: '1',
      label: _react["default"].createElement(_reactRouterDom.Link, {
        to: "".concat(_constants.URL_LOCATION_VIEW_DETAILS, "/").concat(_.id),
        className: "m-0 p-1"
      }, t('View details'))
    }];
  };

  var columns = [{
    title: t('Name'),
    dataIndex: 'name',
    editable: true
  }, {
    title: t('Physical Type'),
    dataIndex: 'type',
    editable: true
  }, {
    title: t('Status'),
    dataIndex: 'status',
    editable: true
  }, {
    title: t('Actions'),
    width: '10%',
    render: function render(_, record) {
      return _react["default"].createElement("span", {
        className: "d-flex align-items-center"
      }, _react["default"].createElement(_rbac.RbacCheck, {
        permissions: ['Location.update']
      }, _react["default"].createElement(_react["default"].Fragment, null, _react["default"].createElement(_EditLink.EditLink, {
        location: record.location,
        editLinkText: t('Edit')
      }), _react["default"].createElement(_antd.Divider, {
        type: "vertical"
      }))), _react["default"].createElement(_antd.Dropdown, {
        menu: {
          items: getItems(record)
        },
        placement: "bottomRight",
        arrow: true,
        trigger: ['click']
      }, _react["default"].createElement(_icons.MoreOutlined, {
        className: "more-options",
        "data-testid": "action-dropdown"
      })));
    }
  }];
  var tableProps = {
    datasource: tableData,
    columns: columns,
    loading: isLoading,
    size: 'small',
    pagination: tablePaginationProps
  };
  return _react["default"].createElement(_antd.Row, {
    "data-testid": "child-location-tab",
    className: "list-view"
  }, _react["default"].createElement(_antd.Col, {
    style: {
      width: '100%'
    }
  }, _react["default"].createElement("div", {
    className: "main-content__header"
  }, _react["default"].createElement(_reactUtils.SearchForm, (0, _extends2["default"])({
    "data-testid": "search-form"
  }, searchFormProps)), _react["default"].createElement(_rbac.RbacCheck, {
    permissions: ['Location.create']
  }, _react["default"].createElement("div", null, _react["default"].createElement(_antd.Button, {
    type: "primary",
    onClick: function onClick() {
      var queryParams = _objectSpread((0, _defineProperty2["default"])({}, _constants.parentIdQueryParam, "".concat(_constants.locationResourceType, "/").concat(locationId)), backParamObj);

      var searchString = new URLSearchParams(queryParams).toString();
      history.push("".concat(_constants.URL_LOCATION_UNIT_ADD, "?").concat(searchString));
    }
  }, _react["default"].createElement(_icons.PlusOutlined, null), t('Add Location Unit'))))), _react["default"].createElement(_reactUtils.TableLayout, tableProps)));
};

exports.ChildLocations = ChildLocations;

function parseTableData(locations) {
  return locations.map(function (loc) {
    var _loc$partOf$display, _loc$partOf;

    return {
      id: loc.id,
      name: loc.name,
      partOf: (_loc$partOf$display = (_loc$partOf = loc.partOf) === null || _loc$partOf === void 0 ? void 0 : _loc$partOf.display) !== null && _loc$partOf$display !== void 0 ? _loc$partOf$display : '-',
      description: loc.description,
      status: loc.status,
      type: (0, _lodash.get)(loc, 'physicalType.coding.0.display'),
      location: loc
    };
  });
}