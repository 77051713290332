"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ViewDetails = void 0;

var _constants = require("../../constants");

var _mls = require("../../mls");

var _react = _interopRequireDefault(require("react"));

var _reactHelmet = require("react-helmet");

var _reactQuery = require("react-query");

var _reactRouter = require("react-router");

var _reactUtils = require("@opensrp/react-utils");

var _antd = require("antd");

var _DetailsTabs = require("./DetailsTabs");

var _utils = require("./utils");

var _LocationPathBreadCrumb = require("./LocationPathBreadCrumb");

var _LocationDetails = require("./LocationDetails");

var ViewDetails = function ViewDetails(props) {
  var fhirBaseURL = props.fhirBaseURL;

  var _useParams = (0, _reactRouter.useParams)(),
      locationId = _useParams.id;

  var _useMls = (0, _mls.useMls)(),
      t = _useMls.t;

  var locationAncestorQueryNamespace = 'location-parents';

  var _useQuery = (0, _reactQuery.useQuery)([locationAncestorQueryNamespace, _constants.locationResourceType, locationId], function () {
    return (0, _utils.getLocationsAncestors)(fhirBaseURL, locationId);
  }),
      data = _useQuery.data,
      isLoading = _useQuery.isLoading,
      error = _useQuery.error;

  if (isLoading) {
    return _react["default"].createElement(_antd.Spin, {
      size: "large",
      className: "custom-spinner"
    });
  }

  if (error && !data) {
    return _react["default"].createElement(_reactUtils.BrokenPage, {
      errorMessage: "".concat(error.message)
    });
  }

  if (!(data !== null && data !== void 0 && data.length)) {
    return _react["default"].createElement(_reactUtils.Resource404, null);
  }

  var normalizedData = data;
  var theInterestingLoc = normalizedData[normalizedData.length - 1];
  var pageTitle = t("View details | {{locName}}", {
    locName: theInterestingLoc.name
  });
  var headerProps = {
    pageHeaderProps: {
      title: pageTitle,
      onBack: undefined
    }
  };
  return _react["default"].createElement(_reactUtils.BodyLayout, {
    headerProps: headerProps
  }, _react["default"].createElement(_reactHelmet.Helmet, null, _react["default"].createElement("title", null, pageTitle, " ")), _react["default"].createElement("div", {
    style: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      padding: '16px',
      gap: '14px',
      background: '#FFF',
      borderRadius: '12px'
    }
  }, _react["default"].createElement(_LocationPathBreadCrumb.LocationPathBreadCrumb, {
    locationPath: data
  }), _react["default"].createElement(_LocationDetails.LocationDetails, {
    location: theInterestingLoc
  }), _react["default"].createElement(_DetailsTabs.ViewDetailsTabs, {
    fhirBaseUrl: fhirBaseURL,
    location: theInterestingLoc
  })));
};

exports.ViewDetails = ViewDetails;