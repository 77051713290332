"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.InstructionStepTwoTitle = exports.InstructionStepTwo = exports.InstructionStepOneTitle = exports.InstructionStepOne = exports.ImporterFormInstructions = void 0;

var _regenerator = _interopRequireDefault(require("@babel/runtime/regenerator"));

var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));

var _react = _interopRequireDefault(require("react"));

var _antd = require("antd");

var _Upload = _interopRequireDefault(require("@2fd/ant-design-icons/lib/Upload"));

var _ArrowDownThick = _interopRequireDefault(require("@2fd/ant-design-icons/lib/ArrowDownThick"));

var _reactUtils = require("@opensrp/react-utils");

var _constants = require("../../constants");

var _mls = require("../../mls");

var _notifications = require("@opensrp/notifications");

var Title = _antd.Typography.Title,
    Text = _antd.Typography.Text;

var ImporterFormInstructions = function ImporterFormInstructions() {
  var _useTranslation = (0, _mls.useTranslation)(),
      t = _useTranslation.t;

  return _react["default"].createElement("div", {
    "data-testid": "form-instructions"
  }, _react["default"].createElement(_antd.Space, {
    direction: "vertical"
  }, _react["default"].createElement(Title, {
    level: 4
  }, _react["default"].createElement(_Upload["default"], null), t('Step by step guide for bulk upload')), _react["default"].createElement(Text, {
    type: "secondary"
  }, t('Follow these simple instructions to help you prepare, upload, and verify your data smoothly and efficiently')), _react["default"].createElement(_antd.Steps, {
    className: "form-instructions-steps",
    direction: "vertical",
    size: "small",
    items: [{
      title: _react["default"].createElement(InstructionStepOneTitle, null),
      description: _react["default"].createElement(InstructionStepOne, null)
    }, {
      title: _react["default"].createElement(InstructionStepTwoTitle, null),
      description: _react["default"].createElement(InstructionStepTwo, null)
    }]
  })));
};

exports.ImporterFormInstructions = ImporterFormInstructions;

var InstructionStepOneTitle = function InstructionStepOneTitle() {
  var _useTranslation2 = (0, _mls.useTranslation)(),
      t = _useTranslation2.t;

  return _react["default"].createElement(Text, {
    strong: true
  }, t('Prepare your data file'));
};

exports.InstructionStepOneTitle = InstructionStepOneTitle;

var InstructionStepOne = function InstructionStepOne() {
  var _useTranslation3 = (0, _mls.useTranslation)(),
      t = _useTranslation3.t;

  return _react["default"].createElement("ol", null, _react["default"].createElement("li", null, t('Click the button below to download the bulk upload template file(s).'), _react["default"].createElement("br", null), _react["default"].createElement(_antd.Button, {
    type: "primary",
    onClick: (0, _asyncToGenerator2["default"])(_regenerator["default"].mark(function _callee2() {
      var service;
      return _regenerator["default"].wrap(function _callee2$(_context2) {
        while (1) {
          switch (_context2.prev = _context2.next) {
            case 0:
              service = new _reactUtils.OpenSRPService('/$import/templates', _constants.IMPORT_DOMAIN_URI);
              _context2.next = 3;
              return service.download().then(function () {
                var _ref2 = (0, _asyncToGenerator2["default"])(_regenerator["default"].mark(function _callee(response) {
                  var contentDispositionHeader, fileName, blob;
                  return _regenerator["default"].wrap(function _callee$(_context) {
                    while (1) {
                      switch (_context.prev = _context.next) {
                        case 0:
                          contentDispositionHeader = response.headers.get('content-disposition');
                          fileName = contentDispositionHeader ? (0, _reactUtils.getFileNameFromCDHHeader)(contentDispositionHeader) : _constants.defaultImportTemplateName;
                          _context.next = 4;
                          return response.blob();

                        case 4:
                          blob = _context.sent;
                          (0, _reactUtils.downloadFile)(blob, fileName);

                        case 6:
                        case "end":
                          return _context.stop();
                      }
                    }
                  }, _callee);
                }));

                return function (_x) {
                  return _ref2.apply(this, arguments);
                };
              }())["catch"](function () {
                return (0, _notifications.sendErrorNotification)(t('An error occurred while fetching the csv templates'));
              });

            case 3:
            case "end":
              return _context2.stop();
          }
        }
      }, _callee2);
    }))
  }, t('Download Template'), _react["default"].createElement(_ArrowDownThick["default"], null))), _react["default"].createElement("li", null, t('Enter your data into the template file. Ensure all required fields are filled and follow the specified format(e.g. date format)')), _react["default"].createElement("li", null, t('Check for any data inconsistencies or errors (e.g. missing values, incorrect data types) before uploading')));
};

exports.InstructionStepOne = InstructionStepOne;

var InstructionStepTwoTitle = function InstructionStepTwoTitle() {
  var _useTranslation4 = (0, _mls.useTranslation)(),
      t = _useTranslation4.t;

  return _react["default"].createElement(Text, {
    strong: true
  }, t('Upload your data file'));
};

exports.InstructionStepTwoTitle = InstructionStepTwoTitle;

var InstructionStepTwo = function InstructionStepTwo() {
  var _useTranslation5 = (0, _mls.useTranslation)(),
      t = _useTranslation5.t;

  return _react["default"].createElement("ol", null, _react["default"].createElement("li", null, t('Click the "Attach" button to select your prepared data file.')), _react["default"].createElement("li", null, t('Once the file or files are selected, click "Start Import" to begin the upload')));
};

exports.InstructionStepTwo = InstructionStepTwo;