"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.thatiMinutes = exports.status = exports.productName = exports.locationName = exports.comments = exports.URL_CLOSE_FLAGS = exports.URL_ADMIN = void 0;
var URL_ADMIN = '/admin';
exports.URL_ADMIN = URL_ADMIN;
var URL_CLOSE_FLAGS = "".concat(URL_ADMIN, "/close-flags");
exports.URL_CLOSE_FLAGS = URL_CLOSE_FLAGS;
var thatiMinutes = 30 * 60 * 1000;
exports.thatiMinutes = thatiMinutes;
var locationName = 'locationName';
exports.locationName = locationName;
var productName = 'productName';
exports.productName = productName;
var status = 'status';
exports.status = status;
var comments = 'comments';
exports.comments = comments;