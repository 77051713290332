"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.putCloseFlagResources = exports.postPutObservation = exports.postPutFlag = exports.postPutEncounter = exports.generateObservationPayload = exports.generateEncounterPayload = exports.generateCommonProperties = void 0;

var _regenerator = _interopRequireDefault(require("@babel/runtime/regenerator"));

var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _uuid = require("uuid");

var _payloadConfigs = require("./payloadConfigs");

var _reactUtils = require("@opensrp/react-utils");

var _fhirHelpers = require("@opensrp/fhir-helpers");

function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);

  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);
    enumerableOnly && (symbols = symbols.filter(function (sym) {
      return Object.getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }

  return keys;
}

function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      (0, _defineProperty2["default"])(target, key, source[key]);
    }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
    });
  }

  return target;
}

var generateCommonProperties = function generateCommonProperties(id, flag) {
  var _flag$meta;

  return {
    id: (0, _uuid.v5)(id, flag.id),
    meta: {
      tag: (_flag$meta = flag.meta) === null || _flag$meta === void 0 ? void 0 : _flag$meta.tag
    }
  };
};

exports.generateCommonProperties = generateCommonProperties;

var postPutEncounter = function postPutEncounter(baseUrl, payload) {
  var serve = new _reactUtils.FHIRServiceClass(baseUrl, _fhirHelpers.EncounterResourceType);
  return serve.update(payload);
};

exports.postPutEncounter = postPutEncounter;

var postPutObservation = function postPutObservation(baseUrl, payload) {
  var serve = new _reactUtils.FHIRServiceClass(baseUrl, _fhirHelpers.ObservationResourceType);
  return serve.update(payload);
};

exports.postPutObservation = postPutObservation;

var postPutFlag = function postPutFlag(baseUrl, payload) {
  var serve = new _reactUtils.FHIRServiceClass(baseUrl, _fhirHelpers.FlagResourceType);
  return serve.update(payload);
};

exports.postPutFlag = postPutFlag;

var generateEncounterPayload = function generateEncounterPayload(encounter, flag, practitionerId, locationReference) {
  var _flag$encounter;

  var commonProperties = generateCommonProperties(_fhirHelpers.EncounterResourceType, flag);
  var reference = (0, _fhirHelpers.conceptsHaveCodings)(flag.category, [_fhirHelpers.servicePointCheckCoding, _fhirHelpers.consultBeneficiaryCoding]) ? flag.subject.reference : locationReference;
  return _objectSpread(_objectSpread(_objectSpread({}, encounter), commonProperties), {}, {
    partOf: {
      reference: (_flag$encounter = flag.encounter) === null || _flag$encounter === void 0 ? void 0 : _flag$encounter.reference
    },
    location: [{
      location: {
        reference: reference
      },
      status: 'active'
    }],
    participant: [{
      individual: {
        reference: "".concat(_fhirHelpers.PractitionerResourceType, "/").concat(practitionerId)
      }
    }]
  });
};

exports.generateEncounterPayload = generateEncounterPayload;

var generateObservationPayload = function generateObservationPayload(observation, flag, practitionerId, locationReference, values) {
  var commonProperties = generateCommonProperties(_fhirHelpers.ObservationResourceType, flag);
  var encounterProperties = generateCommonProperties(_fhirHelpers.EncounterResourceType, flag);
  var isSPCHECKOrCNBEN = (0, _fhirHelpers.conceptsHaveCodings)(flag.category, [_fhirHelpers.servicePointCheckCoding, _fhirHelpers.consultBeneficiaryCoding]);
  return _objectSpread(_objectSpread(_objectSpread({}, observation), commonProperties), {}, {
    subject: {
      reference: flag.subject.reference
    },
    encounter: {
      reference: "Encounter/".concat(encounterProperties.id)
    },
    focus: isSPCHECKOrCNBEN ? [{
      reference: "Flag/".concat(flag.id)
    }] : [{
      reference: locationReference
    }, {
      reference: "".concat(_fhirHelpers.FlagResourceType, "/").concat(flag.id)
    }],
    performer: isSPCHECKOrCNBEN ? [{
      reference: "".concat(_fhirHelpers.PractitionerResourceType, "/").concat(practitionerId)
    }] : undefined,
    note: [{
      time: new Date().toISOString(),
      text: values.comments
    }]
  });
};

exports.generateObservationPayload = generateObservationPayload;

var putCloseFlagResources = function () {
  var _ref = (0, _asyncToGenerator2["default"])(_regenerator["default"].mark(function _callee(initialValues, values, activeFlag, fhirBaseUrl) {
    var listSubject, practitionerId, encounterPayload, observationPayload, updatedFlag, flagPromise, encounterObservationPromise;
    return _regenerator["default"].wrap(function _callee$(_context) {
      while (1) {
        switch (_context.prev = _context.next) {
          case 0:
            listSubject = initialValues.listSubject, practitionerId = initialValues.practitionerId;
            encounterPayload = generateEncounterPayload(_payloadConfigs.encounter, activeFlag, practitionerId, listSubject);
            observationPayload = generateObservationPayload(_payloadConfigs.observation, activeFlag, practitionerId, listSubject, values);
            updatedFlag = _objectSpread(_objectSpread({}, activeFlag), {}, {
              status: 'inactive'
            });
            flagPromise = postPutFlag(fhirBaseUrl, updatedFlag);
            encounterObservationPromise = new Promise(function (resolve, reject) {
              postPutEncounter(fhirBaseUrl, encounterPayload).then(function (encounterRes) {
                postPutObservation(fhirBaseUrl, observationPayload).then(function (observationRes) {
                  resolve({
                    encounterRes: encounterRes,
                    observationRes: observationRes
                  });
                })["catch"](function (err) {
                  reject(err);
                });
              })["catch"](function (err) {
                reject(err);
              });
            });
            _context.next = 8;
            return Promise.all([flagPromise, encounterObservationPromise]);

          case 8:
            return _context.abrupt("return", _context.sent);

          case 9:
          case "end":
            return _context.stop();
        }
      }
    }, _callee);
  }));

  return function putCloseFlagResources(_x, _x2, _x3, _x4) {
    return _ref.apply(this, arguments);
  };
}();

exports.putCloseFlagResources = putCloseFlagResources;