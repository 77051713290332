"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

var _typeof = require("@babel/runtime/helpers/typeof");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TabsTable = TabsTable;
exports.sideViewQuery = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _react = _interopRequireWildcard(require("react"));

var _useServerSideActionsDataGrid = require("../../../hooks/useServerSideActionsDataGrid");

var _BrokenPage = require("../../BrokenPage");

var _TableLayout = _interopRequireDefault(require("../../TableLayout"));

var _Search = require("../../Search");

var _antd = require("antd");

var _mls = require("../../../mls");

var _useSearchParams2 = require("../../../hooks/useSearchParams");

var _ResourceDetails = require("../ResourceDetails");

function _getRequireWildcardCache(nodeInterop) {
  if (typeof WeakMap !== "function") return null;
  var cacheBabelInterop = new WeakMap();
  var cacheNodeInterop = new WeakMap();
  return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) {
    return nodeInterop ? cacheNodeInterop : cacheBabelInterop;
  })(nodeInterop);
}

function _interopRequireWildcard(obj, nodeInterop) {
  if (!nodeInterop && obj && obj.__esModule) {
    return obj;
  }

  if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") {
    return {
      "default": obj
    };
  }

  var cache = _getRequireWildcardCache(nodeInterop);

  if (cache && cache.has(obj)) {
    return cache.get(obj);
  }

  var newObj = {};
  var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor;

  for (var key in obj) {
    if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) {
      var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null;

      if (desc && (desc.get || desc.set)) {
        Object.defineProperty(newObj, key, desc);
      } else {
        newObj[key] = obj[key];
      }
    }
  }

  newObj["default"] = obj;

  if (cache) {
    cache.set(obj, newObj);
  }

  return newObj;
}

function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);

  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);
    enumerableOnly && (symbols = symbols.filter(function (sym) {
      return Object.getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }

  return keys;
}

function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      (0, _defineProperty2["default"])(target, key, source[key]);
    }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
    });
  }

  return target;
}

var sideViewQuery = 'sideView';
exports.sideViewQuery = sideViewQuery;

function TabsTable(props) {
  var _sParams$get;

  var resourceId = props.resourceId,
      fhirBaseURL = props.fhirBaseURL,
      resourceType = props.resourceType,
      tableDataGetter = props.tableDataGetter,
      extractResourceFn = props.extractResourceFn,
      tableColumns = props.tableColumns,
      enableSearch = props.enableSearch,
      searchParamsFactory = props.searchParamsFactory,
      extractSideViewDetails = props.extractSideViewDetails;
  var getSearchParams = searchParamsFactory(resourceId);

  var _useSearchParams = (0, _useSearchParams2.useSearchParams)(),
      sParams = _useSearchParams.sParams;

  var tableRowDataId = (_sParams$get = sParams.get(sideViewQuery)) !== null && _sParams$get !== void 0 ? _sParams$get : undefined;

  var _useTranslation = (0, _mls.useTranslation)(),
      t = _useTranslation.t;

  var _useSimpleTabularView = (0, _useServerSideActionsDataGrid.useSimpleTabularView)(fhirBaseURL, resourceType, getSearchParams, extractResourceFn),
      _useSimpleTabularView2 = _useSimpleTabularView.queryValues,
      data = _useSimpleTabularView2.data,
      isFetching = _useSimpleTabularView2.isFetching,
      isLoading = _useSimpleTabularView2.isLoading,
      error = _useSimpleTabularView2.error,
      tablePaginationProps = _useSimpleTabularView.tablePaginationProps,
      searchFormProps = _useSimpleTabularView.searchFormProps;

  var tableData = (0, _react.useMemo)(function () {
    var _data$records;

    return tableDataGetter((_data$records = data === null || data === void 0 ? void 0 : data.records) !== null && _data$records !== void 0 ? _data$records : []);
  }, [data, tableDataGetter]);

  if (error && !data) {
    return _react["default"].createElement(_BrokenPage.BrokenPage, {
      errorMessage: error.message
    });
  }

  var tableProps = {
    datasource: tableData,
    columns: tableColumns,
    loading: isFetching || isLoading,
    pagination: tablePaginationProps
  };
  var sideViewData = ((data === null || data === void 0 ? void 0 : data.records) || []).filter(function (row) {
    return tableRowDataId === row.id;
  })[0];
  var parsedSideViewData;
  var resourceDetailsProps;

  if (sideViewData && extractSideViewDetails) {
    parsedSideViewData = extractSideViewDetails(sideViewData, t);
    resourceDetailsProps = _objectSpread({
      column: {
        xs: 1,
        sm: 1,
        md: 2,
        lg: 2,
        xl: 2,
        xxl: 2
      }
    }, parsedSideViewData);
  }

  return _react["default"].createElement(_antd.Row, {
    className: "list-view"
  }, _react["default"].createElement(_antd.Col, {
    className: "main-content"
  }, enableSearch && _react["default"].createElement(_Search.SearchForm, searchFormProps), _react["default"].createElement(_TableLayout["default"], tableProps)), tableRowDataId && resourceDetailsProps && _react["default"].createElement(_antd.Col, {
    className: "view-details-content"
  }, _react["default"].createElement(_antd.Space, {
    direction: "vertical"
  }, _react["default"].createElement(_ResourceDetails.ResourceDetails, resourceDetailsProps))));
}