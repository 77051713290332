"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ViewDetailsTabs = ViewDetailsTabs;

var _mls = require("../../../mls");

var _react = _interopRequireDefault(require("react"));

var _antd = require("antd");

var _Inventory = require("./Inventory");

var _ChildLocations = require("./ChildLocations");

var _pkgConfig = require("@opensrp/pkg-config");

var _utils = require("../utils");

var _reactUtils = require("@opensrp/react-utils");

var _rbac = require("@opensrp/rbac");

var _fhirHelpers = require("@opensrp/fhir-helpers");

function ViewDetailsTabs(props) {
  var _sParams$get;

  var fhirBaseUrl = props.fhirBaseUrl,
      location = props.location;

  var _useMls = (0, _mls.useMls)(),
      t = _useMls.t;

  var projectCode = (0, _pkgConfig.getConfig)('projectCode');
  var userRole = (0, _rbac.useUserRole)();

  var _useSearchParams = (0, _reactUtils.useSearchParams)(),
      sParams = _useSearchParams.sParams,
      addParam = _useSearchParams.addParam;

  var _parseLocationDetails = (0, _utils.parseLocationDetails)(location),
      id = _parseLocationDetails.id,
      physicalType = _parseLocationDetails.physicalType;

  var locationId = id;
  var viewDetailsQuery = 'view-details';
  var locationKey = 'locations';
  var inventoryKey = 'inventory';
  var defaultActiveKey = 'locations';
  var activeKey = (_sParams$get = sParams.get(viewDetailsQuery)) !== null && _sParams$get !== void 0 ? _sParams$get : defaultActiveKey;
  var items = [{
    label: t('Locations'),
    key: locationKey,
    children: _react["default"].createElement(_ChildLocations.ChildLocations, {
      fhirBaseUrl: fhirBaseUrl,
      locationId: locationId
    }),
    enabled: userRole.hasPermissions('Location.read') && physicalType.code === _fhirHelpers.PhysicalTypeCodes.JURISDICTION
  }, {
    label: t('Inventory'),
    key: inventoryKey,
    children: _react["default"].createElement(_Inventory.InventoryView, {
      fhirBaseUrl: fhirBaseUrl,
      locationId: locationId
    }),
    enabled: userRole.hasPermissions('Group.read') && projectCode === 'eusm' && physicalType.code === _fhirHelpers.PhysicalTypeCodes.BUILDING
  }].filter(function (item) {
    return item.enabled;
  });
  return _react["default"].createElement(_antd.Tabs, {
    "data-testid": "details-tab",
    onChange: function onChange(activeKey) {
      addParam(viewDetailsQuery, activeKey);
    },
    style: {
      width: '100%'
    },
    defaultActiveKey: activeKey,
    size: 'small',
    items: items
  });
}