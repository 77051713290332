"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.columns = void 0;
exports.immunizationDetailProps = immunizationDetailProps;
exports.parseImmunizationList = exports.parseImmunization = exports.immunizationSideViewData = exports.immunizationSearchParams = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _react = _interopRequireDefault(require("react"));

var _lodash = require("lodash");

var _utils = require("../../../helpers/utils");

var _reactUtils = require("@opensrp/react-utils");

var parseImmunization = function parseImmunization(obj) {
  return {
    status: (0, _lodash.get)(obj, 'status'),
    vaccineCode: (0, _utils.getCodeableConcepts)((0, _lodash.get)(obj, 'vaccineCode')),
    statusReason: (0, _utils.getCodeableConcepts)((0, _lodash.get)(obj, 'statusReason')),
    occurrenceDateTime: (0, _lodash.get)(obj, 'occurrenceDateTime'),
    reportOrigin: (0, _utils.getCodeableConcepts)((0, _lodash.get)(obj, 'reportOrigin')),
    reasonCode: (0, _utils.getCodeableConcepts)((0, _lodash.get)(obj, 'reasonCode')),
    dateRecorded: (0, _lodash.get)(obj, 'recorded'),
    protocolApplied: (0, _lodash.get)(obj, 'protocolApplied'),
    primarySource: (0, _lodash.get)(obj, 'primarySource'),
    doseQuantity: (0, _lodash.get)(obj, 'doseQuantity'),
    expirationDate: (0, _lodash.get)(obj, 'expirationDate'),
    id: (0, _lodash.get)(obj, 'id')
  };
};

exports.parseImmunization = parseImmunization;

var parseImmunizationList = function parseImmunizationList(list) {
  return list.map(parseImmunization);
};

exports.parseImmunizationList = parseImmunizationList;
var occuredDateTimeSortFn = (0, _utils.sorterFn)('occurrenceDateTime', true);

var columns = function columns(t) {
  return [{
    title: t('Vaccine Admnistered'),
    dataIndex: 'vaccineCode',
    render: function render(value) {
      return _react["default"].createElement(_utils.FhirCodesTooltips, {
        codings: value
      });
    }
  }, {
    title: t('Status'),
    dataIndex: 'status',
    sorter: (0, _utils.sorterFn)('status')
  }, {
    title: t('Administration Date'),
    dataIndex: 'occurrenceDateTime',
    sorter: occuredDateTimeSortFn,
    render: function render(value) {
      return t('{{val, datetime}}', {
        val: (0, _reactUtils.dateToLocaleString)(value, true)
      });
    }
  }];
};

exports.columns = columns;

var immunizationSearchParams = function immunizationSearchParams(patientId) {
  return {
    patient: patientId
  };
};

exports.immunizationSearchParams = immunizationSearchParams;

var immunizationSideViewData = function immunizationSideViewData(resource, t) {
  var _protocolApplied$, _bodyData;

  var _parseImmunization = parseImmunization(resource),
      id = _parseImmunization.id,
      reasonCode = _parseImmunization.reasonCode,
      status = _parseImmunization.status,
      vaccineCode = _parseImmunization.vaccineCode,
      protocolApplied = _parseImmunization.protocolApplied,
      dateRecorded = _parseImmunization.dateRecorded;

  var headerLeftData = (0, _defineProperty2["default"])({}, t('ID'), id);
  var bodyData = (_bodyData = {}, (0, _defineProperty2["default"])(_bodyData, t('Date recorded'), (0, _reactUtils.dateToLocaleString)(dateRecorded, true)), (0, _defineProperty2["default"])(_bodyData, t('protocol applied'), protocolApplied === null || protocolApplied === void 0 ? void 0 : (_protocolApplied$ = protocolApplied[0]) === null || _protocolApplied$ === void 0 ? void 0 : _protocolApplied$.doseNumberPositiveInt), (0, _defineProperty2["default"])(_bodyData, t('status'), status), (0, _defineProperty2["default"])(_bodyData, t('Reason'), _react["default"].createElement(_utils.FhirCodesTooltips, {
    codings: reasonCode
  })), _bodyData);
  return {
    title: _react["default"].createElement(_utils.FhirCodesTooltips, {
      codings: vaccineCode
    }),
    headerLeftData: headerLeftData,
    bodyData: bodyData,
    status: {
      title: status !== null && status !== void 0 ? status : '',
      color: 'green'
    }
  };
};

exports.immunizationSideViewData = immunizationSideViewData;

function immunizationDetailProps(resource, t) {
  var _protocolApplied$2, _bodyData2;

  var _parseImmunization2 = parseImmunization(resource),
      id = _parseImmunization2.id,
      reasonCode = _parseImmunization2.reasonCode,
      status = _parseImmunization2.status,
      vaccineCode = _parseImmunization2.vaccineCode,
      protocolApplied = _parseImmunization2.protocolApplied,
      dateRecorded = _parseImmunization2.dateRecorded,
      expirationDate = _parseImmunization2.expirationDate,
      occurrenceDateTime = _parseImmunization2.occurrenceDateTime,
      doseQuantity = _parseImmunization2.doseQuantity,
      primarySource = _parseImmunization2.primarySource,
      reportOrigin = _parseImmunization2.reportOrigin;

  var bodyData = (_bodyData2 = {}, (0, _defineProperty2["default"])(_bodyData2, t('Vaccine Admnistered'), _react["default"].createElement(_utils.FhirCodesTooltips, {
    codings: vaccineCode
  })), (0, _defineProperty2["default"])(_bodyData2, t('Administration Date'), (0, _reactUtils.dateToLocaleString)(occurrenceDateTime, true)), (0, _defineProperty2["default"])(_bodyData2, t('Vaccine expiry date'), (0, _reactUtils.dateToLocaleString)(expirationDate, true)), (0, _defineProperty2["default"])(_bodyData2, t('protocol applied'), protocolApplied === null || protocolApplied === void 0 ? void 0 : (_protocolApplied$2 = protocolApplied[0]) === null || _protocolApplied$2 === void 0 ? void 0 : _protocolApplied$2.doseNumberPositiveInt), (0, _defineProperty2["default"])(_bodyData2, t('Dose quantity'), doseQuantity === null || doseQuantity === void 0 ? void 0 : doseQuantity.unit), (0, _defineProperty2["default"])(_bodyData2, t('status'), status), (0, _defineProperty2["default"])(_bodyData2, t('Primary source'), primarySource), (0, _defineProperty2["default"])(_bodyData2, t('Report origin'), _react["default"].createElement(_utils.FhirCodesTooltips, {
    codings: reportOrigin
  })), (0, _defineProperty2["default"])(_bodyData2, t('Reason'), _react["default"].createElement(_utils.FhirCodesTooltips, {
    codings: reasonCode
  })), _bodyData2);
  return {
    title: _react["default"].createElement(_utils.FhirCodesTooltips, {
      codings: vaccineCode
    }),
    headerRightData: (0, _defineProperty2["default"])({}, t('Date created'), (0, _reactUtils.dateToLocaleString)(dateRecorded)),
    headerLeftData: (0, _defineProperty2["default"])({}, t('Id'), id),
    bodyData: bodyData,
    status: {
      title: status,
      color: 'green'
    }
  };
}