"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.GroupGridFilerRow = void 0;

var _antd = require("antd");

var _react = _interopRequireDefault(require("react"));

var _mls = require("../../../mls");

var _i18n = require("@opensrp/i18n");

var isAnAssetDataIdx = 'isAnAsset';
var statusFilterDataIdx = 'status';

var GroupGridFilerRow = function GroupGridFilerRow(props) {
  var _currentFilters$isAnA, _currentFilters$statu;

  var _useTranslation = (0, _mls.useTranslation)(),
      t = _useTranslation.t;

  var updateFilterParams = props.updateFilterParams,
      currentFilters = props.currentFilters;
  return _react["default"].createElement("div", {
    className: "filter-row",
    "data-testid": "filter-row"
  }, _react["default"].createElement(_antd.Space, {
    size: 'large'
  }, _react["default"].createElement(_i18n.Trans, {
    t: t,
    i18nKey: "attractiveFilter"
  }, _react["default"].createElement(_antd.Space, null, "Asset:", _react["default"].createElement(_antd.Radio.Group, {
    size: "small",
    value: (_currentFilters$isAnA = currentFilters[isAnAssetDataIdx]) === null || _currentFilters$isAnA === void 0 ? void 0 : _currentFilters$isAnA.value,
    buttonStyle: "solid",
    onChange: function onChange(event) {
      var val = event.target.value;

      if (val !== undefined) {
        updateFilterParams(isAnAssetDataIdx, function (el) {
          return el.attractive === val;
        }, val);
      } else {
        updateFilterParams(isAnAssetDataIdx, undefined);
      }
    }
  }, _react["default"].createElement(_antd.Radio.Button, {
    value: true
  }, t('Yes')), _react["default"].createElement(_antd.Radio.Button, {
    value: false
  }, t('No')), _react["default"].createElement(_antd.Radio.Button, {
    value: undefined
  }, t('Show all'))))), _react["default"].createElement(_i18n.Trans, {
    t: t,
    i18nKey: "groupStatusFilter"
  }, _react["default"].createElement(_antd.Space, null, "Status:", _react["default"].createElement(_antd.Radio.Group, {
    size: "small",
    value: (_currentFilters$statu = currentFilters[statusFilterDataIdx]) === null || _currentFilters$statu === void 0 ? void 0 : _currentFilters$statu.value,
    buttonStyle: "solid",
    onChange: function onChange(event) {
      var val = event.target.value;

      if (val !== undefined) {
        updateFilterParams(statusFilterDataIdx, function (el) {
          return el.active === val;
        }, val);
      } else {
        updateFilterParams(statusFilterDataIdx, undefined);
      }
    }
  }, _react["default"].createElement(_antd.Radio.Button, {
    value: true
  }, t('Active')), _react["default"].createElement(_antd.Radio.Button, {
    value: false
  }, t('Inactive')), _react["default"].createElement(_antd.Radio.Button, {
    value: undefined
  }, t('Show all')))))));
};

exports.GroupGridFilerRow = GroupGridFilerRow;