"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.parseEusmCommodity = exports.ViewDetailsWrapper = exports.EusmViewDetails = void 0;

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _react = _interopRequireDefault(require("react"));

var _icons = require("@ant-design/icons");

var _antd = require("antd");

var _reactUtils = require("@opensrp/react-utils");

var _lodash = require("lodash");

var _mls = require("../../../mls");

var _utils = require("../../../helpers/utils");

var EusmViewDetails = function EusmViewDetails(props) {
  var _keyValues;

  var resourceId = props.resourceId,
      fhirBaseURL = props.fhirBaseURL;

  var _useTranslation = (0, _mls.useTranslation)(),
      t = _useTranslation.t;

  var _useGetGroupAndBinary = (0, _utils.useGetGroupAndBinary)(fhirBaseURL, resourceId),
      _useGetGroupAndBinary2 = _useGetGroupAndBinary.groupQuery,
      data = _useGetGroupAndBinary2.data,
      isLoading = _useGetGroupAndBinary2.isLoading,
      error = _useGetGroupAndBinary2.error,
      binaryQuery = _useGetGroupAndBinary.binaryQuery;

  if (isLoading) {
    return _react["default"].createElement(_antd.Spin, {
      size: "large",
      className: "custom-spinner"
    });
  }

  if (error && !data) {
    return _react["default"].createElement(_antd.Alert, {
      type: "error",
      message: "".concat(error)
    });
  }

  var group = data;

  var _parseEusmCommodity = parseEusmCommodity(group, binaryQuery.data),
      accountabilityPeriod = _parseEusmCommodity.accountabilityPeriod,
      appropriateUsage = _parseEusmCommodity.appropriateUsage,
      condition = _parseEusmCommodity.condition,
      availability = _parseEusmCommodity.availability,
      attractive = _parseEusmCommodity.attractive,
      photoDataUrl = _parseEusmCommodity.photoDataUrl,
      name = _parseEusmCommodity.name,
      active = _parseEusmCommodity.active,
      id = _parseEusmCommodity.id,
      identifier = _parseEusmCommodity.identifier;

  var keyValues = (_keyValues = {}, (0, _defineProperty2["default"])(_keyValues, t('Product Id'), id), (0, _defineProperty2["default"])(_keyValues, t('Material Number'), identifier), (0, _defineProperty2["default"])(_keyValues, t('Name'), name), (0, _defineProperty2["default"])(_keyValues, t('Active'), active ? t('Active') : t('Disabled')), (0, _defineProperty2["default"])(_keyValues, t('Is it an Asset'), attractive ? t('Yes') : t('No')), (0, _defineProperty2["default"])(_keyValues, t('Is it there'), availability), (0, _defineProperty2["default"])(_keyValues, t('Is it in good condition'), condition), (0, _defineProperty2["default"])(_keyValues, t('Is it being used appropriately'), appropriateUsage), (0, _defineProperty2["default"])(_keyValues, t('Accountability period (in months)'), accountabilityPeriod), _keyValues);
  return _react["default"].createElement(_antd.Space, {
    direction: "vertical"
  }, _react["default"].createElement("div", {
    style: {
      display: 'flex',
      justifyContent: 'center',
      paddingBottom: '16px',
      paddingTop: '16px'
    }
  }, photoDataUrl ? _react["default"].createElement("img", {
    style: {
      maxHeight: '192px',
      width: 'auto'
    },
    "data-testid": "e-img",
    src: photoDataUrl,
    alt: "product"
  }) : _react["default"].createElement(FallbackImage, {
    active: binaryQuery.isLoading
  })), Object.entries(keyValues).map(function (_ref) {
    var _ref2 = (0, _slicedToArray2["default"])(_ref, 2),
        key = _ref2[0],
        value = _ref2[1];

    var props = (0, _defineProperty2["default"])({}, key, value);
    return value ? _react["default"].createElement("div", {
      key: key,
      "data-testid": "key-value"
    }, _react["default"].createElement(_reactUtils.SingleKeyNestedValue, {
      data: props
    })) : null;
  }));
};

exports.EusmViewDetails = EusmViewDetails;

var ViewDetailsWrapper = function ViewDetailsWrapper(props) {
  var resourceId = props.resourceId,
      fhirBaseURL = props.fhirBaseURL;

  var _useSearchParams = (0, _reactUtils.useSearchParams)(),
      removeParam = _useSearchParams.removeParam;

  if (!resourceId) {
    return null;
  }

  return _react["default"].createElement(_antd.Col, {
    className: "view-details-content"
  }, _react["default"].createElement("div", {
    className: "flex-right"
  }, _react["default"].createElement(_antd.Button, {
    "data-testid": "close-button",
    icon: _react["default"].createElement(_icons.CloseOutlined, null),
    shape: "circle",
    type: "text",
    onClick: function onClick() {
      return removeParam(_reactUtils.viewDetailsQuery);
    }
  })), _react["default"].createElement(EusmViewDetails, {
    resourceId: resourceId,
    fhirBaseURL: fhirBaseURL
  }));
};

exports.ViewDetailsWrapper = ViewDetailsWrapper;

var FallbackImage = function FallbackImage(_ref3) {
  var active = _ref3.active;
  return _react["default"].createElement(_antd.Skeleton.Image, {
    className: "fallback-img",
    style: {
      height: '192px',
      width: '192px'
    },
    active: active
  });
};

var parseEusmCommodity = function parseEusmCommodity(obj, binary) {
  var _obj$characteristic, _getCharacteristicWit, _getCharacteristicWit2, _getCharacteristicWit3, _getCharacteristicWit4, _getCharacteristicWit5, _getCharacteristicWit6, _getCharacteristicWit7, _getCharacteristicWit8, _getCharacteristicWit9;

  var name = obj.name,
      active = obj.active,
      id = obj.id,
      type = obj.type,
      rawIdentifier = obj.identifier;
  var identifierObj = (0, _reactUtils.getObjLike)(rawIdentifier, 'use', _reactUtils.IdentifierUseCodes.OFFICIAL);
  var identifier = (0, _lodash.get)(identifierObj, '0.value');
  var characteristic = (_obj$characteristic = obj.characteristic) !== null && _obj$characteristic !== void 0 ? _obj$characteristic : [];
  var accountabilityPeriod = (_getCharacteristicWit = (0, _utils.getCharacteristicWithCoding)(characteristic, _utils.accountabilityCharacteristicCoding)) === null || _getCharacteristicWit === void 0 ? void 0 : (_getCharacteristicWit2 = _getCharacteristicWit.valueQuantity) === null || _getCharacteristicWit2 === void 0 ? void 0 : _getCharacteristicWit2.value;
  var appropriateUsage = (_getCharacteristicWit3 = (0, _utils.getCharacteristicWithCoding)(characteristic, _utils.appropriateUsageCharacteristicCoding)) === null || _getCharacteristicWit3 === void 0 ? void 0 : (_getCharacteristicWit4 = _getCharacteristicWit3.valueCodeableConcept) === null || _getCharacteristicWit4 === void 0 ? void 0 : _getCharacteristicWit4.text;
  var condition = (_getCharacteristicWit5 = (0, _utils.getCharacteristicWithCoding)(characteristic, _utils.conditionCharacteristicCoding)) === null || _getCharacteristicWit5 === void 0 ? void 0 : (_getCharacteristicWit6 = _getCharacteristicWit5.valueCodeableConcept) === null || _getCharacteristicWit6 === void 0 ? void 0 : _getCharacteristicWit6.text;
  var availability = (_getCharacteristicWit7 = (0, _utils.getCharacteristicWithCoding)(characteristic, _utils.availabilityCharacteristicCoding)) === null || _getCharacteristicWit7 === void 0 ? void 0 : (_getCharacteristicWit8 = _getCharacteristicWit7.valueCodeableConcept) === null || _getCharacteristicWit8 === void 0 ? void 0 : _getCharacteristicWit8.text;
  var attractive = (_getCharacteristicWit9 = (0, _utils.getCharacteristicWithCoding)(characteristic, _utils.attractiveCharacteristicCoding)) === null || _getCharacteristicWit9 === void 0 ? void 0 : _getCharacteristicWit9.valueBoolean;
  var photoDataUrl = binary ? "data:".concat(binary.contentType, ";base64,").concat(binary.data) : undefined;
  return {
    accountabilityPeriod: accountabilityPeriod,
    appropriateUsage: appropriateUsage,
    condition: condition,
    availability: availability,
    attractive: attractive,
    photoDataUrl: photoDataUrl,
    name: name,
    active: active,
    id: id,
    identifier: identifier,
    lastUpdated: (0, _lodash.get)(obj, 'meta.lastUpdated'),
    type: type,
    obj: obj
  };
};

exports.parseEusmCommodity = parseEusmCommodity;