"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

var _typeof = require("@babel/runtime/helpers/typeof");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ValueSetAsyncSelect = ValueSetAsyncSelect;
exports.getValueSetOptionsValue = void 0;
exports.getValueSetSelectOptions = getValueSetSelectOptions;
exports.valueSetResourceType = exports.useSanitizedValueSelectValue = exports.selectFilterFunction = void 0;

var _regenerator = _interopRequireDefault(require("@babel/runtime/regenerator"));

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));

var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));

var _react = _interopRequireWildcard(require("react"));

var _select = _interopRequireDefault(require("antd/lib/select"));

var _BaseAsyncSelect = require("../BaseAsyncSelect");

var _dataLoaders = require("../../../helpers/dataLoaders");

var _reactQuery = require("react-query");

var _mls = require("../../../mls");

var _excluded = ["valueSetURL", "fhirBaseUrl", "value", "defaultValue"];

function _getRequireWildcardCache(nodeInterop) {
  if (typeof WeakMap !== "function") return null;
  var cacheBabelInterop = new WeakMap();
  var cacheNodeInterop = new WeakMap();
  return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) {
    return nodeInterop ? cacheNodeInterop : cacheBabelInterop;
  })(nodeInterop);
}

function _interopRequireWildcard(obj, nodeInterop) {
  if (!nodeInterop && obj && obj.__esModule) {
    return obj;
  }

  if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") {
    return {
      "default": obj
    };
  }

  var cache = _getRequireWildcardCache(nodeInterop);

  if (cache && cache.has(obj)) {
    return cache.get(obj);
  }

  var newObj = {};
  var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor;

  for (var key in obj) {
    if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) {
      var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null;

      if (desc && (desc.get || desc.set)) {
        Object.defineProperty(newObj, key, desc);
      } else {
        newObj[key] = obj[key];
      }
    }
  }

  newObj["default"] = obj;

  if (cache) {
    cache.set(obj, newObj);
  }

  return newObj;
}

function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);

  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);
    enumerableOnly && (symbols = symbols.filter(function (sym) {
      return Object.getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }

  return keys;
}

function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      (0, _defineProperty2["default"])(target, key, source[key]);
    }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
    });
  }

  return target;
}

var valueSetResourceType = 'ValueSet';
exports.valueSetResourceType = valueSetResourceType;

function ValueSetAsyncSelect(props) {
  var valueSetURL = props.valueSetURL,
      fhirBaseUrl = props.fhirBaseUrl,
      value = props.value,
      defaultValue = props.defaultValue,
      rawSelectProps = (0, _objectWithoutProperties2["default"])(props, _excluded);

  var _useTranslation = (0, _mls.useTranslation)(),
      t = _useTranslation.t;

  var queryParams = {
    queryKey: [valueSetResourceType, valueSetURL],
    queryFn: function () {
      var _queryFn = (0, _asyncToGenerator2["default"])(_regenerator["default"].mark(function _callee() {
        return _regenerator["default"].wrap(function _callee$(_context) {
          while (1) {
            switch (_context.prev = _context.next) {
              case 0:
                return _context.abrupt("return", new _dataLoaders.FHIRServiceClass(fhirBaseUrl, valueSetResourceType).read("$expand?url=".concat(valueSetURL)));

              case 1:
              case "end":
                return _context.stop();
            }
          }
        }, _callee);
      }));

      function queryFn() {
        return _queryFn.apply(this, arguments);
      }

      return queryFn;
    }(),
    select: function select(data) {
      return getValueSetSelectOptions(data);
    }
  };

  var _useQuery = (0, _reactQuery.useQuery)(queryParams),
      data = _useQuery.data,
      isLoading = _useQuery.isLoading,
      error = _useQuery.error;

  var optionsByCodeAndSystem = (0, _react.useMemo)(function () {
    return (data !== null && data !== void 0 ? data : []).reduce(function (acc, opt) {
      try {
        var _opt$value;

        var optionObj = JSON.parse((_opt$value = opt.value) !== null && _opt$value !== void 0 ? _opt$value : '{}');
        var key = "".concat(optionObj.code, "-").concat(optionObj.system);
        acc[key] = opt;
        return acc;
      } catch (_) {
        return acc;
      }
    }, {});
  }, [data]);
  var sanitizedValue = useSanitizedValueSelectValue(optionsByCodeAndSystem, value);
  var sanitizedDefValue = useSanitizedValueSelectValue(optionsByCodeAndSystem, defaultValue);
  var selectDropDownRender = (0, _BaseAsyncSelect.dropDownFactory)(t, data, error);

  var selectProps = _objectSpread(_objectSpread({
    className: 'asyncSelect',
    dropdownRender: selectDropDownRender,
    options: data,
    loading: isLoading,
    disabled: isLoading
  }, rawSelectProps), {}, {
    filterOption: selectFilterFunction,
    value: sanitizedValue,
    defaultValue: sanitizedDefValue
  });

  return _react["default"].createElement(_select["default"], selectProps);
}

var selectFilterFunction = function selectFilterFunction(inputValue, option) {
  var _option$label, _option$label$toStrin;

  return !!(option !== null && option !== void 0 && (_option$label = option.label) !== null && _option$label !== void 0 && (_option$label$toStrin = _option$label.toString()) !== null && _option$label$toStrin !== void 0 && _option$label$toStrin.toLowerCase().includes(inputValue.toLowerCase()));
};

exports.selectFilterFunction = selectFilterFunction;

var getValueSetOptionsValue = function getValueSetOptionsValue(record) {
  if (record) {
    return JSON.stringify({
      code: record.code,
      display: record.display,
      system: record.system
    });
  }
};

exports.getValueSetOptionsValue = getValueSetOptionsValue;

function getValueSetSelectOptions(data) {
  var _data$compose, _data$expansion, _data$expansion$conta;

  var valueSetsByCode = {};
  (_data$compose = data.compose) === null || _data$compose === void 0 ? void 0 : _data$compose.include.forEach(function (item) {
    var _item$concept;

    (_item$concept = item.concept) === null || _item$concept === void 0 ? void 0 : _item$concept.forEach(function (record) {
      var code = record.code;
      valueSetsByCode[code] = _objectSpread(_objectSpread({}, record), {}, {
        system: item.system
      });
    });
  });
  (_data$expansion = data.expansion) === null || _data$expansion === void 0 ? void 0 : (_data$expansion$conta = _data$expansion.contains) === null || _data$expansion$conta === void 0 ? void 0 : _data$expansion$conta.forEach(function (item) {
    var code = item.code;
    valueSetsByCode[code] = _objectSpread({}, item);
  });
  var valueSets = Object.values(valueSetsByCode);
  var options = valueSets.map(function (record) {
    return {
      value: getValueSetOptionsValue(record),
      label: record.display
    };
  });
  return options;
}

var useSanitizedValueSelectValue = function useSanitizedValueSelectValue(optionsByCodeAndSystem, value) {
  return (0, _react.useMemo)(function () {
    try {
      if (value) {
        var _optionsByCodeAndSyst;

        var valueOb = JSON.parse(value);
        var key = "".concat(valueOb.code, "-").concat(valueOb.system);
        return (_optionsByCodeAndSyst = optionsByCodeAndSystem[key].value) !== null && _optionsByCodeAndSyst !== void 0 ? _optionsByCodeAndSyst : value;
      }
    } catch (_) {
      return value;
    }
  }, [optionsByCodeAndSystem, value]);
};

exports.useSanitizedValueSelectValue = useSanitizedValueSelectValue;