"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

var _typeof = require("@babel/runtime/helpers/typeof");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PopulatedResourceDetails = PopulatedResourceDetails;

var _extends2 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));

var _react = _interopRequireWildcard(require("react"));

var _antd = require("antd");

var _reactQuery = require("react-query");

var _mls = require("../../../mls");

var _ResourceDetails = require("../ResourceDetails");

var _BrokenPage = require("../../BrokenPage");

function _getRequireWildcardCache(nodeInterop) {
  if (typeof WeakMap !== "function") return null;
  var cacheBabelInterop = new WeakMap();
  var cacheNodeInterop = new WeakMap();
  return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) {
    return nodeInterop ? cacheNodeInterop : cacheBabelInterop;
  })(nodeInterop);
}

function _interopRequireWildcard(obj, nodeInterop) {
  if (!nodeInterop && obj && obj.__esModule) {
    return obj;
  }

  if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") {
    return {
      "default": obj
    };
  }

  var cache = _getRequireWildcardCache(nodeInterop);

  if (cache && cache.has(obj)) {
    return cache.get(obj);
  }

  var newObj = {};
  var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor;

  for (var key in obj) {
    if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) {
      var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null;

      if (desc && (desc.get || desc.set)) {
        Object.defineProperty(newObj, key, desc);
      } else {
        newObj[key] = obj[key];
      }
    }
  }

  newObj["default"] = obj;

  if (cache) {
    cache.set(obj, newObj);
  }

  return newObj;
}

function PopulatedResourceDetails(props) {
  var resourceDetailsPropsGetter = props.resourceDetailsPropsGetter,
      resourceQueryParams = props.resourceQueryParams,
      column = props.column,
      fhirBaseURL = props.fhirBaseURL;

  var _useTranslation = (0, _mls.useTranslation)(),
      t = _useTranslation.t;

  var _useQuery = (0, _reactQuery.useQuery)(resourceQueryParams),
      data = _useQuery.data,
      isLoading = _useQuery.isLoading,
      error = _useQuery.error;

  var resourceDetailsProps = (0, _react.useMemo)(function () {
    return data ? resourceDetailsPropsGetter(data, t, fhirBaseURL) : undefined;
  }, [data, resourceDetailsPropsGetter]);

  if (isLoading) {
    return _react["default"].createElement(_antd.Spin, {
      size: "small",
      className: "custom-spinner"
    });
  }

  if (error) {
    return _react["default"].createElement(_BrokenPage.BrokenPage, {
      errorMessage: error.message
    });
  }

  return _react["default"].createElement(_react["default"].Fragment, null, resourceDetailsProps && _react["default"].createElement(_ResourceDetails.ResourceDetails, (0, _extends2["default"])({
    column: column
  }, resourceDetailsProps)));
}