"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Coding = void 0;

var _antd = require("antd");

var _react = _interopRequireDefault(require("react"));

var Text = _antd.Typography.Text;

var Coding = function Coding(props) {
  var _props$coding = props.coding,
      display = _props$coding.display,
      system = _props$coding.system,
      code = _props$coding.code;
  var valueStr = '';

  if (display) {
    valueStr += display;
  }

  if (system) {
    valueStr += "(".concat(system, "|").concat(code ? code : '', ")");
  }

  return _react["default"].createElement(Text, null, valueStr);
};

exports.Coding = Coding;