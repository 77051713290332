"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ResourceItemLoader = ResourceItemLoader;

var _antd = require("antd");

var _react = _interopRequireDefault(require("react"));

var _reactQuery = require("react-query");

function ResourceItemLoader(props) {
  var resourceQueryParams = props.resourceQueryParams,
      itemGetter = props.itemGetter;

  var _useQuery = (0, _reactQuery.useQuery)(resourceQueryParams),
      data = _useQuery.data,
      isLoading = _useQuery.isLoading,
      error = _useQuery.error;

  if (isLoading) {
    return _react["default"].createElement(_antd.Spin, {
      size: "small"
    });
  }

  if (error || !data) {
    return null;
  }

  var item = itemGetter(data);
  return _react["default"].createElement(_react["default"].Fragment, null, item);
}