"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getLocationsAncestors = getLocationsAncestors;
exports.hasCode = void 0;
exports.parseLocationDetails = parseLocationDetails;

var _regenerator = _interopRequireDefault(require("@babel/runtime/regenerator"));

var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));

var _constants = require("../../constants");

var _reactUtils = require("@opensrp/react-utils");

var _lodash = require("lodash");

var _fhirHelpers = require("@opensrp/fhir-helpers");

function _createForOfIteratorHelper(o, allowArrayLike) {
  var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"];

  if (!it) {
    if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") {
      if (it) o = it;
      var i = 0;

      var F = function F() {};

      return {
        s: F,
        n: function n() {
          if (i >= o.length) return {
            done: true
          };
          return {
            done: false,
            value: o[i++]
          };
        },
        e: function e(_e) {
          throw _e;
        },
        f: F
      };
    }

    throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
  }

  var normalCompletion = true,
      didErr = false,
      err;
  return {
    s: function s() {
      it = it.call(o);
    },
    n: function n() {
      var step = it.next();
      normalCompletion = step.done;
      return step;
    },
    e: function e(_e2) {
      didErr = true;
      err = _e2;
    },
    f: function f() {
      try {
        if (!normalCompletion && it["return"] != null) it["return"]();
      } finally {
        if (didErr) throw err;
      }
    }
  };
}

function _unsupportedIterableToArray(o, minLen) {
  if (!o) return;
  if (typeof o === "string") return _arrayLikeToArray(o, minLen);
  var n = Object.prototype.toString.call(o).slice(8, -1);
  if (n === "Object" && o.constructor) n = o.constructor.name;
  if (n === "Map" || n === "Set") return Array.from(o);
  if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen);
}

function _arrayLikeToArray(arr, len) {
  if (len == null || len > arr.length) len = arr.length;

  for (var i = 0, arr2 = new Array(len); i < len; i++) {
    arr2[i] = arr[i];
  }

  return arr2;
}

function getLocationsAncestors(_x, _x2) {
  return _getLocationsAncestors.apply(this, arguments);
}

function _getLocationsAncestors() {
  _getLocationsAncestors = (0, _asyncToGenerator2["default"])(_regenerator["default"].mark(function _callee(fhirBaseUrl, locationId) {
    var foundParent, locationPath, currentLocationId, _yield$fetchLocAndPar, parent, child, _parent$partOf$refere, _parent$partOf, grandParentReferenceParts, grandParentReference, rootFirstLocations;

    return _regenerator["default"].wrap(function _callee$(_context) {
      while (1) {
        switch (_context.prev = _context.next) {
          case 0:
            foundParent = false;
            locationPath = [];
            currentLocationId = locationId;

          case 3:
            if (foundParent) {
              _context.next = 13;
              break;
            }

            _context.next = 6;
            return fetchLocAndParent(fhirBaseUrl, currentLocationId);

          case 6:
            _yield$fetchLocAndPar = _context.sent;
            parent = _yield$fetchLocAndPar.parent;
            child = _yield$fetchLocAndPar.child;

            if (child) {
              locationPath.push(child);
            }

            if (!parent) {
              foundParent = true;
            } else {
              locationPath.push(parent);
              grandParentReferenceParts = ((_parent$partOf$refere = (_parent$partOf = parent.partOf) === null || _parent$partOf === void 0 ? void 0 : _parent$partOf.reference) !== null && _parent$partOf$refere !== void 0 ? _parent$partOf$refere : '').split('/');
              grandParentReference = grandParentReferenceParts[grandParentReferenceParts.length - 1];
              currentLocationId = grandParentReference;

              if (!grandParentReference) {
                foundParent = true;
              }
            }

            _context.next = 3;
            break;

          case 13:
            rootFirstLocations = (0, _lodash.reverse)(locationPath);
            return _context.abrupt("return", rootFirstLocations);

          case 15:
          case "end":
            return _context.stop();
        }
      }
    }, _callee);
  }));
  return _getLocationsAncestors.apply(this, arguments);
}

function fetchLocAndParent(_x3, _x4) {
  return _fetchLocAndParent.apply(this, arguments);
}

function _fetchLocAndParent() {
  _fetchLocAndParent = (0, _asyncToGenerator2["default"])(_regenerator["default"].mark(function _callee2(fhirBaseUrl, locationId) {
    var service, response, parentId, parent, child, _iterator2, _step2, _loc$partOf, loc, thisLocParentId, thisLocId;

    return _regenerator["default"].wrap(function _callee2$(_context2) {
      while (1) {
        switch (_context2.prev = _context2.next) {
          case 0:
            service = new _reactUtils.FHIRServiceClass(fhirBaseUrl, _constants.locationResourceType);
            _context2.next = 3;
            return service.list({
              _id: locationId,
              _include: 'Location:partof'
            }).then(function (res) {
              return (0, _reactUtils.getResourcesFromBundle)(res);
            });

          case 3:
            response = _context2.sent;
            parent = undefined;
            child = undefined;
            _iterator2 = _createForOfIteratorHelper(response);

            try {
              for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
                loc = _step2.value;
                thisLocParentId = (_loc$partOf = loc.partOf) === null || _loc$partOf === void 0 ? void 0 : _loc$partOf.reference;
                thisLocId = "".concat(_constants.locationResourceType, "/").concat(loc.id);

                if (parentId === undefined) {
                  parentId = thisLocParentId;
                }

                if (parentId === thisLocId) {
                  parent = loc;
                } else {
                  child = loc;
                }
              }
            } catch (err) {
              _iterator2.e(err);
            } finally {
              _iterator2.f();
            }

            return _context2.abrupt("return", {
              child: child,
              parent: parent
            });

          case 9:
          case "end":
            return _context2.stop();
        }
      }
    }, _callee2);
  }));
  return _fetchLocAndParent.apply(this, arguments);
}

function parseLocationDetails(node) {
  var _geoJSonExtens$valueA, _type$flatMap, _node$partOf$display, _node$partOf, _updatedLast;

  var id = node.id,
      name = node.name,
      status = node.status,
      description = node.description,
      identifier = node.identifier,
      meta = node.meta,
      extension = node.extension,
      position = node.position,
      alias = node.alias,
      type = node.type;
  var updatedLastRaw = meta === null || meta === void 0 ? void 0 : meta.lastUpdated;
  var updatedLast = updatedLastRaw ? new Date(updatedLastRaw) : undefined;

  if (!(0, _reactUtils.isValidDate)(updatedLast)) {
    updatedLast = undefined;
  }

  var geoJSonExtens = (0, _reactUtils.getObjLike)(extension, 'url', _fhirHelpers.locationGeometryExtensionUri)[0];
  var geoJsonExtensionData = geoJSonExtens === null || geoJSonExtens === void 0 ? void 0 : (_geoJSonExtens$valueA = geoJSonExtens.valueAttachment) === null || _geoJSonExtens$valueA === void 0 ? void 0 : _geoJSonExtens$valueA.data;
  var geometry;

  if (geoJsonExtensionData) {
    geometry = btoa(geoJsonExtensionData);
  }

  var typeCodingFlatMap = (_type$flatMap = type === null || type === void 0 ? void 0 : type.flatMap(function (concept) {
    return concept.coding;
  })) !== null && _type$flatMap !== void 0 ? _type$flatMap : [];
  var servicePointTypeCodings = typeCodingFlatMap.filter(function (x) {
    return x !== undefined;
  });
  var servicePointCode = (0, _reactUtils.getObjLike)(servicePointTypeCodings, 'system', _fhirHelpers.eusmServicePointCodeSystemUri)[0];
  var administrativeLevel = (0, _fhirHelpers.getLocationAdmLevel)(type);
  return {
    identifier: identifier,
    id: id,
    name: name,
    partOf: (_node$partOf$display = (_node$partOf = node.partOf) === null || _node$partOf === void 0 ? void 0 : _node$partOf.display) !== null && _node$partOf$display !== void 0 ? _node$partOf$display : '-',
    description: description,
    status: status,
    physicalType: {
      code: (0, _lodash.get)(node, 'physicalType.coding.0.code'),
      display: (0, _lodash.get)(node, 'physicalType.coding.0.display')
    },
    lastUpdated: (_updatedLast = updatedLast) === null || _updatedLast === void 0 ? void 0 : _updatedLast.toLocaleString(),
    geometry: geometry,
    longitude: position === null || position === void 0 ? void 0 : position.longitude,
    latitude: position === null || position === void 0 ? void 0 : position.latitude,
    version: meta === null || meta === void 0 ? void 0 : meta.versionId,
    alias: alias,
    servicePointType: servicePointCode === null || servicePointCode === void 0 ? void 0 : servicePointCode.display,
    administrativeLevel: administrativeLevel
  };
}

var hasCode = function hasCode(codeList, coding) {
  var _iterator = _createForOfIteratorHelper(codeList),
      _step;

  try {
    for (_iterator.s(); !(_step = _iterator.n()).done;) {
      var code = _step.value;

      if ((0, _lodash.isEqual)(code, coding)) {
        return true;
      }
    }
  } catch (err) {
    _iterator.e(err);
  } finally {
    _iterator.f();
  }

  return false;
};

exports.hasCode = hasCode;