"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CONTACT_FORM_FIELD = void 0;

var _constants = require("../../../constants");

var CONTACT_FORM_FIELD = 'contact';
exports.CONTACT_FORM_FIELD = CONTACT_FORM_FIELD;