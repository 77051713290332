"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.usernameField = exports.userTypeField = exports.userGroupsField = exports.lastNameField = exports.firstNameField = exports.fhirCoreAppIdField = exports.enabledField = exports.emailField = exports.compositionResourceType = exports.UserQueryId = exports.URL_USER_ROLES = exports.URL_USER_GROUP_EDIT = exports.URL_USER_GROUP_CREATE = exports.URL_USER_GROUPS = exports.URL_USER_EDIT = exports.URL_USER_CREDENTIALS = exports.URL_USER_CREATE = exports.URL_USER = exports.URL_ADMIN = exports.SUPERVISOR_USER_TYPE_CODE = exports.SUPERVISOR = exports.SNOMED_CODEABLE_SYSTEM = exports.SEARCH_QUERY_PARAM = exports.ROUTE_PARAM_USER_ID = exports.ROUTE_PARAM_USER_GROUP_ID = exports.ROUTE_PARAM_USERNAME = exports.PRACTITIONER_USER_TYPE_CODE = exports.PRACTITIONER = exports.PHONE_NUMBER_FORM_FIELD = exports.ORGANIZATION_BY_PRACTITIONER = exports.OPENSRP_CREATE_PRACTITIONER_ENDPOINT = exports.NATIONAL_ID_FORM_FIELD = exports.KEYCLOAK_URL_USER_ROLES = exports.KEYCLOAK_URL_USER_GROUPS = exports.KEYCLOAK_URL_USERS_COUNT = exports.KEYCLOAK_URL_USERS = exports.KEYCLOAK_URL_RESET_PASSWORD = exports.KEYCLOAK_URL_REQUIRED_USER_ACTIONS = exports.KEYCLOAK_URL_EFFECTIVE_ROLES = exports.KEYCLOAK_URL_AVAILABLE_ROLES = exports.KEYCLOAK_URL_ASSIGNED_ROLES = exports.DEVICE_SETTING_CODEABLE_CODE = exports.DELETE_PRACTITIONER_ROLE = void 0;
var URL_ADMIN = '/admin';
exports.URL_ADMIN = URL_ADMIN;
var URL_USER_EDIT = URL_ADMIN + '/users/edit';
exports.URL_USER_EDIT = URL_USER_EDIT;
var URL_USER = URL_ADMIN + '/users';
exports.URL_USER = URL_USER;
var URL_USER_GROUPS = "".concat(URL_ADMIN, "/users/groups");
exports.URL_USER_GROUPS = URL_USER_GROUPS;
var URL_USER_ROLES = "".concat(URL_ADMIN, "/users/roles");
exports.URL_USER_ROLES = URL_USER_ROLES;
var URL_USER_GROUP_EDIT = "".concat(URL_ADMIN, "/users/groups/edit");
exports.URL_USER_GROUP_EDIT = URL_USER_GROUP_EDIT;
var URL_USER_CREATE = URL_ADMIN + '/users/new';
exports.URL_USER_CREATE = URL_USER_CREATE;
var URL_USER_GROUP_CREATE = "".concat(URL_ADMIN, "/users/groups/new");
exports.URL_USER_GROUP_CREATE = URL_USER_GROUP_CREATE;
var URL_USER_CREDENTIALS = URL_ADMIN + '/users/credentials';
exports.URL_USER_CREDENTIALS = URL_USER_CREDENTIALS;
var ORGANIZATION_BY_PRACTITIONER = 'organization/by-practitioner';
exports.ORGANIZATION_BY_PRACTITIONER = ORGANIZATION_BY_PRACTITIONER;
var ROUTE_PARAM_USER_ID = 'userId';
exports.ROUTE_PARAM_USER_ID = ROUTE_PARAM_USER_ID;
var ROUTE_PARAM_USERNAME = 'username';
exports.ROUTE_PARAM_USERNAME = ROUTE_PARAM_USERNAME;
var ROUTE_PARAM_USER_GROUP_ID = 'userGroupId';
exports.ROUTE_PARAM_USER_GROUP_ID = ROUTE_PARAM_USER_GROUP_ID;
var KEYCLOAK_URL_USERS = '/users';
exports.KEYCLOAK_URL_USERS = KEYCLOAK_URL_USERS;
var KEYCLOAK_URL_USERS_COUNT = '/users/count';
exports.KEYCLOAK_URL_USERS_COUNT = KEYCLOAK_URL_USERS_COUNT;
var KEYCLOAK_URL_USER_GROUPS = '/groups';
exports.KEYCLOAK_URL_USER_GROUPS = KEYCLOAK_URL_USER_GROUPS;
var KEYCLOAK_URL_USER_ROLES = '/roles';
exports.KEYCLOAK_URL_USER_ROLES = KEYCLOAK_URL_USER_ROLES;
var KEYCLOAK_URL_AVAILABLE_ROLES = '/role-mappings/realm/available';
exports.KEYCLOAK_URL_AVAILABLE_ROLES = KEYCLOAK_URL_AVAILABLE_ROLES;
var KEYCLOAK_URL_ASSIGNED_ROLES = '/role-mappings/realm';
exports.KEYCLOAK_URL_ASSIGNED_ROLES = KEYCLOAK_URL_ASSIGNED_ROLES;
var KEYCLOAK_URL_EFFECTIVE_ROLES = '/role-mappings/realm/composite';
exports.KEYCLOAK_URL_EFFECTIVE_ROLES = KEYCLOAK_URL_EFFECTIVE_ROLES;
var KEYCLOAK_URL_RESET_PASSWORD = '/reset-password';
exports.KEYCLOAK_URL_RESET_PASSWORD = KEYCLOAK_URL_RESET_PASSWORD;
var KEYCLOAK_URL_REQUIRED_USER_ACTIONS = '/authentication/required-actions/';
exports.KEYCLOAK_URL_REQUIRED_USER_ACTIONS = KEYCLOAK_URL_REQUIRED_USER_ACTIONS;
var PRACTITIONER = 'practitioner';
exports.PRACTITIONER = PRACTITIONER;
var compositionResourceType = 'Composition';
exports.compositionResourceType = compositionResourceType;
var OPENSRP_CREATE_PRACTITIONER_ENDPOINT = "".concat(PRACTITIONER, "/user");
exports.OPENSRP_CREATE_PRACTITIONER_ENDPOINT = OPENSRP_CREATE_PRACTITIONER_ENDPOINT;
var DELETE_PRACTITIONER_ROLE = 'practitionerRole/delete/';
exports.DELETE_PRACTITIONER_ROLE = DELETE_PRACTITIONER_ROLE;
var SEARCH_QUERY_PARAM = 'searchQuery';
exports.SEARCH_QUERY_PARAM = SEARCH_QUERY_PARAM;
var UserQueryId = 'User';
exports.UserQueryId = UserQueryId;
var SUPERVISOR = 'supervisor';
exports.SUPERVISOR = SUPERVISOR;
var SUPERVISOR_USER_TYPE_CODE = '236321002';
exports.SUPERVISOR_USER_TYPE_CODE = SUPERVISOR_USER_TYPE_CODE;
var PRACTITIONER_USER_TYPE_CODE = '405623001';
exports.PRACTITIONER_USER_TYPE_CODE = PRACTITIONER_USER_TYPE_CODE;
var SNOMED_CODEABLE_SYSTEM = 'http://snomed.info/sct';
exports.SNOMED_CODEABLE_SYSTEM = SNOMED_CODEABLE_SYSTEM;
var DEVICE_SETTING_CODEABLE_CODE = '1156600005';
exports.DEVICE_SETTING_CODEABLE_CODE = DEVICE_SETTING_CODEABLE_CODE;
var NATIONAL_ID_FORM_FIELD = 'nationalId';
exports.NATIONAL_ID_FORM_FIELD = NATIONAL_ID_FORM_FIELD;
var PHONE_NUMBER_FORM_FIELD = 'phoneNumber';
exports.PHONE_NUMBER_FORM_FIELD = PHONE_NUMBER_FORM_FIELD;
var firstNameField = 'firstName';
exports.firstNameField = firstNameField;
var lastNameField = 'lastName';
exports.lastNameField = lastNameField;
var emailField = 'email';
exports.emailField = emailField;
var usernameField = 'username';
exports.usernameField = usernameField;
var userTypeField = 'userType';
exports.userTypeField = userTypeField;
var enabledField = 'enabled';
exports.enabledField = enabledField;
var userGroupsField = 'userGroups';
exports.userGroupsField = userGroupsField;
var fhirCoreAppIdField = 'fhirCoreAppId';
exports.fhirCoreAppIdField = fhirCoreAppIdField;