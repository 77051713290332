"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.LocationDetails = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _mls = require("../../../mls");

var _react = _interopRequireDefault(require("react"));

var _reactUtils = require("@opensrp/react-utils");

var _utils = require("../utils");

var _rbac = require("@opensrp/rbac");

var _EditLink = require("../../EditLink");

var GeometryRender = function GeometryRender(_ref) {
  var geometry = _ref.geometry;
  var formattedGeo = geometry !== null && geometry !== void 0 ? geometry : '';

  try {
    formattedGeo = JSON.stringify(JSON.parse(formattedGeo), undefined, 2);
  } catch (_unused) {}

  return _react["default"].createElement("pre", {
    style: {
      maxHeight: '60px',
      maxWidth: '120px',
      overflow: 'auto',
      border: '1px solid #ddd',
      padding: '4px'
    }
  }, formattedGeo);
};

var LocationDetails = function LocationDetails(_ref2) {
  var _otherDetailsMap;

  var location = _ref2.location;

  var _useMls = (0, _mls.useMls)(),
      t = _useMls.t;

  var _parseLocationDetails = (0, _utils.parseLocationDetails)(location),
      id = _parseLocationDetails.id,
      name = _parseLocationDetails.name,
      status = _parseLocationDetails.status,
      physicalType = _parseLocationDetails.physicalType,
      lastUpdated = _parseLocationDetails.lastUpdated,
      geometry = _parseLocationDetails.geometry,
      longitude = _parseLocationDetails.longitude,
      latitude = _parseLocationDetails.latitude,
      version = _parseLocationDetails.version,
      alias = _parseLocationDetails.alias,
      description = _parseLocationDetails.description,
      administrativeLevel = _parseLocationDetails.administrativeLevel;

  var otherDetailsMap = (_otherDetailsMap = {}, (0, _defineProperty2["default"])(_otherDetailsMap, t('Location Name'), name), (0, _defineProperty2["default"])(_otherDetailsMap, t('Status'), status), (0, _defineProperty2["default"])(_otherDetailsMap, t('alias'), alias), (0, _defineProperty2["default"])(_otherDetailsMap, t('Latitude & Longitude'), latitude && longitude ? "".concat(latitude, ", ").concat(longitude) : undefined), (0, _defineProperty2["default"])(_otherDetailsMap, t('Physical Type'), physicalType.display), (0, _defineProperty2["default"])(_otherDetailsMap, t('Geometry'), geometry ? _react["default"].createElement(GeometryRender, {
    geometry: geometry
  }) : undefined), (0, _defineProperty2["default"])(_otherDetailsMap, t('Administrative Level'), administrativeLevel), (0, _defineProperty2["default"])(_otherDetailsMap, t('Description'), description), _otherDetailsMap);
  var dateCreatedKeyPairing = (0, _defineProperty2["default"])({}, t('Date Last Updated'), lastUpdated);
  var headerLeftData = {
    ID: id,
    Version: version
  };
  return _react["default"].createElement(_reactUtils.ResourceDetails, {
    title: name,
    headerLeftData: headerLeftData,
    headerRightData: dateCreatedKeyPairing,
    headerActions: _react["default"].createElement(_rbac.RbacCheck, {
      permissions: ['Location.update']
    }, _react["default"].createElement(_EditLink.EditLink, {
      location: location,
      editLinkText: t('Edit details')
    })),
    bodyData: otherDetailsMap
  });
};

exports.LocationDetails = LocationDetails;