"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ClientSideActionsSelect = ClientSideActionsSelect;

var _regenerator = _interopRequireDefault(require("@babel/runtime/regenerator"));

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));

var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));

var _react = _interopRequireDefault(require("react"));

var _reactQuery = require("react-query");

var _antd = require("antd");

var _utils = require("../../../helpers/utils");

var _mls = require("../../../mls");

var _fhirUtils = require("../../../helpers/fhir-utils");

var _utils2 = require("../utils");

var _excluded = ["fhirBaseUrl", "resourceType", "extraQueryParams", "transformOption", "onChange", "getFullOptionOnChange"];

function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);

  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);
    enumerableOnly && (symbols = symbols.filter(function (sym) {
      return Object.getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }

  return keys;
}

function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      (0, _defineProperty2["default"])(target, key, source[key]);
    }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
    });
  }

  return target;
}

function ClientSideActionsSelect(props) {
  var fhirBaseUrl = props.fhirBaseUrl,
      resourceType = props.resourceType,
      _props$extraQueryPara = props.extraQueryParams,
      extraQueryParams = _props$extraQueryPara === void 0 ? {} : _props$extraQueryPara,
      transformOption = props.transformOption,
      onChange = props.onChange,
      getFullOptionOnChange = props.getFullOptionOnChange,
      restProps = (0, _objectWithoutProperties2["default"])(props, _excluded);

  var _useTranslation = (0, _mls.useTranslation)(),
      t = _useTranslation.t;

  var _useQuery = (0, _reactQuery.useQuery)({
    queryKey: [ClientSideActionsSelect.name, resourceType],
    queryFn: function () {
      var _queryFn = (0, _asyncToGenerator2["default"])(_regenerator["default"].mark(function _callee() {
        return _regenerator["default"].wrap(function _callee$(_context) {
          while (1) {
            switch (_context.prev = _context.next) {
              case 0:
                _context.next = 2;
                return (0, _fhirUtils.loadAllResources)(fhirBaseUrl, resourceType, extraQueryParams);

              case 2:
                return _context.abrupt("return", _context.sent);

              case 3:
              case "end":
                return _context.stop();
            }
          }
        }, _callee);
      }));

      function queryFn() {
        return _queryFn.apply(this, arguments);
      }

      return queryFn;
    }(),
    refetchOnWindowFocus: false,
    select: function select(bundle) {
      var options = (0, _utils.getResourcesFromBundle)(bundle).map(function (resource) {
        return transformOption(resource);
      });
      return options;
    }
  }),
      options = _useQuery.data,
      isLoading = _useQuery.isLoading,
      error = _useQuery.error;

  var changeHandler = function changeHandler(value, fullOption) {
    var _props$onChange;

    var saneFullOption = Array.isArray(fullOption) ? fullOption.slice() : fullOption;
    (_props$onChange = props.onChange) === null || _props$onChange === void 0 ? void 0 : _props$onChange.call(props, value, saneFullOption);
    getFullOptionOnChange === null || getFullOptionOnChange === void 0 ? void 0 : getFullOptionOnChange(saneFullOption);
  };

  var propsToSelect = _objectSpread(_objectSpread({
    className: 'asyncSelect',
    filterOption: _utils2.defaultSelectFilterFunction
  }, restProps), {}, {
    onChange: changeHandler,
    loading: isLoading,
    notFoundContent: isLoading ? _react["default"].createElement(_antd.Spin, {
      size: "small"
    }) : _react["default"].createElement(_antd.Empty, {
      description: t('No data')
    }),
    options: options,
    dropdownRender: function dropdownRender(menu) {
      return _react["default"].createElement(_react["default"].Fragment, null, !error && (options === null || options === void 0 ? void 0 : options.length) && menu, _react["default"].createElement(_antd.Divider, {
        style: {
          margin: '8px 0'
        }
      }), error && _react["default"].createElement(_antd.Alert, {
        message: t('Unable to load dropdown options.'),
        type: "error",
        showIcon: true
      }));
    }
  });

  return _react["default"].createElement(_antd.Select, propsToSelect);
}