"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.physicalTypeValueSetUrl = exports.R4GroupTypeCodesValueSetUrl = exports.R4GroupTypeCodes = exports.PhysicalTypeCodes = void 0;
var physicalTypeValueSetUrl = 'http://hl7.org/fhir/ValueSet/location-physical-type';
exports.physicalTypeValueSetUrl = physicalTypeValueSetUrl;
var PhysicalTypeCodes;
exports.PhysicalTypeCodes = PhysicalTypeCodes;

(function (PhysicalTypeCodes) {
  PhysicalTypeCodes["JURISDICTION"] = "jdn";
  PhysicalTypeCodes["BUILDING"] = "bu";
})(PhysicalTypeCodes || (exports.PhysicalTypeCodes = PhysicalTypeCodes = {}));

var R4GroupTypeCodesValueSetUrl = 'http://hl7.org/fhir/ValueSet/group-type';
exports.R4GroupTypeCodesValueSetUrl = R4GroupTypeCodesValueSetUrl;
var R4GroupTypeCodes;
exports.R4GroupTypeCodes = R4GroupTypeCodes;

(function (R4GroupTypeCodes) {
  R4GroupTypeCodes["MEDICATION"] = "medication";
  R4GroupTypeCodes["DEVICE"] = "device";
  R4GroupTypeCodes["SUBSTANCE"] = "substance";
})(R4GroupTypeCodes || (exports.R4GroupTypeCodes = R4GroupTypeCodes = {}));