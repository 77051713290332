"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.renderObjectAsKeyvalue = exports.SingleKeyNestedValue = exports.SingleFlatKeyValue = exports.ListFlatKeyValues = exports.KeyValuesDescriptions = exports.KeyValueGrid = void 0;

var _extends2 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _react = _interopRequireDefault(require("react"));

require("./index.css");

var _antd = require("antd");

var Text = _antd.Typography.Text;
var singleKeyValueClass = {
  light: 'singleKeyValue-pair__light',
  "default": 'singleKeyValue-pair__default'
};

var KeyValueGrid = function KeyValueGrid(props) {
  return _react["default"].createElement("dl", {
    className: "keyValueGrid"
  }, Object.entries(props).map(function (_ref) {
    var _ref2 = (0, _slicedToArray2["default"])(_ref, 2),
        key = _ref2[0],
        value = _ref2[1];

    return _react["default"].createElement("div", {
      key: key,
      className: "keyValueGrid-pair"
    }, _react["default"].createElement("dt", {
      className: "keyValueGrid-pair__label"
    }, key), _react["default"].createElement("dd", {
      className: "keyValueGrid-pair__value"
    }, value));
  }));
};

exports.KeyValueGrid = KeyValueGrid;

var SingleKeyNestedValue = function SingleKeyNestedValue(props) {
  var data = props.data,
      _props$theme = props.theme,
      theme = _props$theme === void 0 ? 'default' : _props$theme;
  var firstPair = Object.entries(data)[0];
  if (firstPair === undefined) return null;

  var _firstPair = (0, _slicedToArray2["default"])(firstPair, 2),
      key = _firstPair[0],
      value = _firstPair[1];

  var keyValueClass = singleKeyValueClass[theme];
  return _react["default"].createElement("dl", {
    className: "singleKeyValue"
  }, _react["default"].createElement("div", {
    className: keyValueClass
  }, _react["default"].createElement("dt", null, key), _react["default"].createElement("dd", null, value)));
};

exports.SingleKeyNestedValue = SingleKeyNestedValue;

var renderObjectAsKeyvalue = function renderObjectAsKeyvalue(obj) {
  return _react["default"].createElement(_react["default"].Fragment, null, Object.entries(obj).map(function (_ref3) {
    var _ref4 = (0, _slicedToArray2["default"])(_ref3, 2),
        key = _ref4[0],
        value = _ref4[1];

    var props = {
      data: (0, _defineProperty2["default"])({}, key, value)
    };
    return value ? _react["default"].createElement("div", {
      key: key,
      "data-testid": "key-value"
    }, _react["default"].createElement(SingleKeyNestedValue, props)) : null;
  }));
};

exports.renderObjectAsKeyvalue = renderObjectAsKeyvalue;

var KeyValuesDescriptions = function KeyValuesDescriptions(props) {
  var data = props.data,
      theme = props.theme,
      _props$column = props.column,
      column = _props$column === void 0 ? {
    xs: 1,
    sm: 1,
    md: 2,
    lg: 2,
    xl: 3,
    xxl: 3
  } : _props$column;
  return _react["default"].createElement(_antd.Descriptions, {
    size: "small",
    column: column
  }, Object.entries(data).map(function (_ref5) {
    var _ref6 = (0, _slicedToArray2["default"])(_ref5, 2),
        key = _ref6[0],
        value = _ref6[1];

    var keyValuePairing = (0, _defineProperty2["default"])({}, key, value);
    return _react["default"].createElement(_antd.Descriptions.Item, {
      key: key
    }, _react["default"].createElement(SingleKeyNestedValue, {
      theme: theme,
      data: keyValuePairing
    }));
  }));
};

exports.KeyValuesDescriptions = KeyValuesDescriptions;

var SingleFlatKeyValue = function SingleFlatKeyValue(props) {
  var data = props.data,
      _props$theme2 = props.theme,
      theme = _props$theme2 === void 0 ? 'default' : _props$theme2;
  var firstPair = Object.entries(data)[0];
  if (firstPair === undefined) return null;

  var _firstPair2 = (0, _slicedToArray2["default"])(firstPair, 2),
      key = _firstPair2[0],
      value = _firstPair2[1];

  var keyClass = "singleFlat-key__".concat(theme);
  var valueClass = "singleFlat-value__".concat(theme);
  return _react["default"].createElement(Text, null, _react["default"].createElement("span", {
    className: keyClass
  }, key), ": ", _react["default"].createElement("span", {
    className: valueClass
  }, value));
};

exports.SingleFlatKeyValue = SingleFlatKeyValue;

var ListFlatKeyValues = function ListFlatKeyValues(props) {
  var data = props.data,
      classnames = props.classnames,
      theme = props.theme;
  return _react["default"].createElement("div", {
    className: classnames
  }, Object.entries(data).map(function (_ref7) {
    var _ref8 = (0, _slicedToArray2["default"])(_ref7, 2),
        key = _ref8[0],
        value = _ref8[1];

    var keyValuePairing = {
      data: (0, _defineProperty2["default"])({}, key, value)
    };
    return _react["default"].createElement(_react["default"].Fragment, {
      key: key
    }, _react["default"].createElement(SingleFlatKeyValue, (0, _extends2["default"])({
      theme: theme
    }, keyValuePairing)), _react["default"].createElement("br", null));
  }));
};

exports.ListFlatKeyValues = ListFlatKeyValues;