"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.NewEditLocationUnit = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _react = _interopRequireDefault(require("react"));

var _base = require("./base");

var _constants = require("../../constants");

function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);

  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);
    enumerableOnly && (symbols = symbols.filter(function (sym) {
      return Object.getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }

  return keys;
}

function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      (0, _defineProperty2["default"])(target, key, source[key]);
    }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
    });
  }

  return target;
}

var NewEditLocationUnit = function NewEditLocationUnit(props) {
  var baseNewEditViewProps = _objectSpread(_objectSpread({}, props), {}, {
    successURLGenerator: function successURLGenerator() {
      return _constants.URL_LOCATION_UNIT;
    },
    cancelURLGenerator: function cancelURLGenerator() {
      return _constants.URL_LOCATION_UNIT;
    },
    hidden: [_constants.serviceType]
  });

  return _react["default"].createElement(_base.BaseNewEditLocationUnit, baseNewEditViewProps);
};

exports.NewEditLocationUnit = NewEditLocationUnit;