"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.renderExtraFields = exports.practitionerRoleResourceType = exports.practitionerResourceType = exports.practitionerDetailsResourceType = exports.organizationResourceType = exports.keycloakRoleMappingsEndpoint = exports.keycloakMembersEndpoint = exports.keycloakGroupEndpoint = exports.keycloakCountEndpoint = exports.groupResourceType = exports.careTeamResourceType = exports.USER_DETAILS_URL = exports.PHONE_NUMBER_FORM_FIELD = exports.NATIONAL_ID_FORM_FIELD = void 0;

var _userManagement = require("@opensrp/user-management");

var practitionerResourceType = 'Practitioner';
exports.practitionerResourceType = practitionerResourceType;
var careTeamResourceType = 'CareTeam';
exports.careTeamResourceType = careTeamResourceType;
var organizationResourceType = 'Organization';
exports.organizationResourceType = organizationResourceType;
var groupResourceType = 'Group';
exports.groupResourceType = groupResourceType;
var practitionerRoleResourceType = 'PractitionerRole';
exports.practitionerRoleResourceType = practitionerRoleResourceType;
var keycloakRoleMappingsEndpoint = 'role-mappings';
exports.keycloakRoleMappingsEndpoint = keycloakRoleMappingsEndpoint;
var practitionerDetailsResourceType = 'PractitionerDetail';
exports.practitionerDetailsResourceType = practitionerDetailsResourceType;
var keycloakCountEndpoint = 'count';
exports.keycloakCountEndpoint = keycloakCountEndpoint;
var keycloakGroupEndpoint = 'groups';
exports.keycloakGroupEndpoint = keycloakGroupEndpoint;
var keycloakMembersEndpoint = 'members';
exports.keycloakMembersEndpoint = keycloakMembersEndpoint;
var USER_DETAILS_URL = "".concat(_userManagement.URL_USER, "/details");
exports.USER_DETAILS_URL = USER_DETAILS_URL;
var NATIONAL_ID_FORM_FIELD = 'nationalId';
exports.NATIONAL_ID_FORM_FIELD = NATIONAL_ID_FORM_FIELD;
var PHONE_NUMBER_FORM_FIELD = 'phoneNumber';
exports.PHONE_NUMBER_FORM_FIELD = PHONE_NUMBER_FORM_FIELD;
var renderExtraFields = [NATIONAL_ID_FORM_FIELD, PHONE_NUMBER_FORM_FIELD];
exports.renderExtraFields = renderExtraFields;