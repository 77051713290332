"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SortDescToSearchParams = SortDescToSearchParams;
exports.filterDescToSearchParams = filterDescToSearchParams;
exports.viewDetailsQuery = exports.startingPageSize = exports.startingPage = exports.searchQuery = exports.sanitizeParams = exports.pageSizeQuery = exports.pageQuery = exports.matchesOnName = exports.loadResources = exports.getStringParam = exports.getNumberParam = exports.getNextUrlOnSearch = void 0;

var _regenerator = _interopRequireDefault(require("@babel/runtime/regenerator"));

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));

var _dataLoaders = require("../helpers/dataLoaders");

function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);

  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);
    enumerableOnly && (symbols = symbols.filter(function (sym) {
      return Object.getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }

  return keys;
}

function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      (0, _defineProperty2["default"])(target, key, source[key]);
    }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
    });
  }

  return target;
}

var pageSizeQuery = 'pageSize';
exports.pageSizeQuery = pageSizeQuery;
var pageQuery = 'page';
exports.pageQuery = pageQuery;
var searchQuery = 'search';
exports.searchQuery = searchQuery;
var viewDetailsQuery = 'viewDetails';
exports.viewDetailsQuery = viewDetailsQuery;

var getStringParam = function getStringParam(location, paramKey) {
  var sParams = new URLSearchParams(location.search);
  return sParams.get(paramKey);
};

exports.getStringParam = getStringParam;

var getNumberParam = function getNumberParam(location, paramKey) {
  var fallback = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
  var sParams = new URLSearchParams(location.search);
  var rawParamVal = sParams.get(paramKey);
  var paramVal = rawParamVal ? Number(rawParamVal) : NaN;
  return isNaN(paramVal) ? fallback : paramVal;
};

exports.getNumberParam = getNumberParam;
var startingPage = 1;
exports.startingPage = startingPage;
var startingPageSize = 20;
exports.startingPageSize = startingPageSize;

var getNextUrlOnSearch = function getNextUrlOnSearch(event, location, match) {
  var searchText = event.target.value;
  var nextUrl = match.url;
  var currentSParams = new URLSearchParams(location.search);

  if (searchText) {
    currentSParams.set(searchQuery, searchText);
    currentSParams.set(pageQuery, startingPage.toString());
    currentSParams.set(pageSizeQuery, startingPageSize.toString());
  } else {
    currentSParams["delete"](searchQuery);
  }

  nextUrl = ''.concat(nextUrl, '?').concat(currentSParams.toString());
  return nextUrl;
};

exports.getNextUrlOnSearch = getNextUrlOnSearch;

var matchesOnName = function matchesOnName(obj, search) {
  var name = obj.name;

  if (name === undefined) {
    return false;
  }

  return name.toLowerCase().includes(search.toLowerCase());
};

exports.matchesOnName = matchesOnName;

var loadResources = function () {
  var _ref = (0, _asyncToGenerator2["default"])(_regenerator["default"].mark(function _callee(baseUrl, resourceType, params) {
    var service, res, countFilter, _yield$service$list, total;

    return _regenerator["default"].wrap(function _callee$(_context) {
      while (1) {
        switch (_context.prev = _context.next) {
          case 0:
            service = new _dataLoaders.FHIRServiceClass(baseUrl, resourceType);
            _context.next = 3;
            return service.list(params);

          case 3:
            res = _context.sent;

            if (!(res.total === undefined)) {
              _context.next = 12;
              break;
            }

            countFilter = _objectSpread(_objectSpread({}, params), {}, {
              _summary: 'count'
            });
            _context.next = 8;
            return service.list(countFilter);

          case 8:
            _yield$service$list = _context.sent;
            total = _yield$service$list.total;
            res.total = total;
            return _context.abrupt("return", res);

          case 12:
            return _context.abrupt("return", res);

          case 13:
          case "end":
            return _context.stop();
        }
      }
    }, _callee);
  }));

  return function loadResources(_x, _x2, _x3) {
    return _ref.apply(this, arguments);
  };
}();

exports.loadResources = loadResources;

function SortDescToSearchParams(sortDescription) {
  var sortString = Object.entries(sortDescription).reduce(function (accumulator, _ref2, currIdx, fullArray) {
    var _ref3 = (0, _slicedToArray2["default"])(_ref2, 2),
        _ = _ref3[0],
        sortDescription = _ref3[1];

    if (!sortDescription) {
      return accumulator;
    }

    var direction = sortDescription.order === 'descend' ? '-' : '';
    var sep = currIdx === fullArray.length - 1 ? '' : ',';
    accumulator += "".concat(direction).concat(sortDescription.paramAccessor).concat(sep);
    return accumulator;
  }, '');

  if (sortString) {
    return {
      _sort: sortString
    };
  } else {
    return {};
  }
}

function filterDescToSearchParams(filterDescription) {
  var filterParam = Object.entries(filterDescription).reduce(function (accumulator, _ref4) {
    var _ref5 = (0, _slicedToArray2["default"])(_ref4, 2),
        _ = _ref5[0],
        filterDescription = _ref5[1];

    if (!filterDescription) {
      return accumulator;
    }

    accumulator[filterDescription.paramAccessor] = filterDescription.paramValue;
    return accumulator;
  }, {});
  return filterParam;
}

var sanitizeParams = function sanitizeParams(origState, state) {
  var newSortState = _objectSpread(_objectSpread({}, origState), state);

  var sanitizedState = {};

  for (var _i = 0, _Object$entries = Object.entries(newSortState); _i < _Object$entries.length; _i++) {
    var _Object$entries$_i = (0, _slicedToArray2["default"])(_Object$entries[_i], 2),
        dataIdx = _Object$entries$_i[0],
        value = _Object$entries$_i[1];

    if (value !== undefined) {
      sanitizedState[dataIdx] = value;
    }
  }

  return sanitizedState;
};

exports.sanitizeParams = sanitizeParams;