"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.columns = void 0;
exports.encounterDetailProps = encounterDetailProps;
exports.parseEncounterList = exports.parseEncounter = exports.encounterPreviewExtractor = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _react = _interopRequireDefault(require("react"));

var _lodash = require("lodash");

var _utils = require("../../../helpers/utils");

var parseEncounter = function parseEncounter(encounter) {
  return {
    type: (0, _utils.getCodeableConcepts)((0, _lodash.get)(encounter, 'type')),
    reason: (0, _utils.getCodeableConcepts)((0, _lodash.get)(encounter, 'reasonCode')),
    serviceType: (0, _utils.getCodeableConcepts)((0, _lodash.get)(encounter, 'serviceType')),
    priority: (0, _utils.getCodeableConcepts)((0, _lodash.get)(encounter, 'priority')),
    status: (0, _lodash.get)(encounter, 'status'),
    classCode: (0, _lodash.get)(encounter, 'class'),
    period: (0, _lodash.get)(encounter, 'period'),
    duration: (0, _lodash.get)(encounter, 'length'),
    serviceProvider: (0, _lodash.get)(encounter, 'serviceProvider'),
    id: (0, _lodash.get)(encounter, 'id'),
    episodeOfCare: (0, _lodash.get)(encounter, 'episodeOfCare')
  };
};

exports.parseEncounter = parseEncounter;

var parseEncounterList = function parseEncounterList(list) {
  return list.map(parseEncounter);
};

exports.parseEncounterList = parseEncounterList;

var columns = function columns(t) {
  return [{
    title: t('Class'),
    dataIndex: 'classCode',
    render: function render(value) {
      var _value$display;

      return (_value$display = value.display) !== null && _value$display !== void 0 ? _value$display : value.code;
    }
  }, {
    title: t('Period'),
    dataIndex: 'period',
    render: function render(value) {
      if (value) {
        return _react["default"].createElement(_utils.FhirPeriod, value);
      }

      return '';
    }
  }, {
    title: t('Service type'),
    dataIndex: 'serviceType',
    render: function render(value) {
      return _react["default"].createElement(_utils.FhirCodesTooltips, {
        codings: value
      });
    }
  }];
};

exports.columns = columns;

var encounterPreviewExtractor = function encounterPreviewExtractor(resource, t) {
  var _episodeOfCare$0$disp, _episodeOfCare$, _episodeOfCare$2, _bodyData, _classCode$display;

  var _parseEncounter = parseEncounter(resource),
      reason = _parseEncounter.reason,
      period = _parseEncounter.period,
      classCode = _parseEncounter.classCode,
      id = _parseEncounter.id,
      status = _parseEncounter.status,
      serviceType = _parseEncounter.serviceType,
      episodeOfCare = _parseEncounter.episodeOfCare;

  var headerLeftData = (0, _defineProperty2["default"])({}, t('ID'), id);
  var bodyData = (_bodyData = {}, (0, _defineProperty2["default"])(_bodyData, t('Reason'), _react["default"].createElement(_utils.FhirCodesTooltips, {
    codings: reason
  })), (0, _defineProperty2["default"])(_bodyData, t('Period'), _react["default"].createElement(_utils.FhirPeriod, period)), (0, _defineProperty2["default"])(_bodyData, t('Service Type'), _react["default"].createElement(_utils.FhirCodesTooltips, {
    codings: serviceType
  })), (0, _defineProperty2["default"])(_bodyData, t('Episode of care'), (_episodeOfCare$0$disp = episodeOfCare === null || episodeOfCare === void 0 ? void 0 : (_episodeOfCare$ = episodeOfCare[0]) === null || _episodeOfCare$ === void 0 ? void 0 : _episodeOfCare$.display) !== null && _episodeOfCare$0$disp !== void 0 ? _episodeOfCare$0$disp : episodeOfCare === null || episodeOfCare === void 0 ? void 0 : (_episodeOfCare$2 = episodeOfCare[0]) === null || _episodeOfCare$2 === void 0 ? void 0 : _episodeOfCare$2.reference), _bodyData);
  return {
    title: (_classCode$display = classCode.display) !== null && _classCode$display !== void 0 ? _classCode$display : classCode.code,
    headerLeftData: headerLeftData,
    bodyData: bodyData,
    status: {
      title: status !== null && status !== void 0 ? status : '',
      color: 'green'
    }
  };
};

exports.encounterPreviewExtractor = encounterPreviewExtractor;

function encounterDetailProps(resource, t) {
  var _classCode$display2, _episodeOfCare$0$disp2, _episodeOfCare$3, _episodeOfCare$4, _bodyData2, _classCode$display3;

  var _parseEncounter2 = parseEncounter(resource),
      reason = _parseEncounter2.reason,
      period = _parseEncounter2.period,
      classCode = _parseEncounter2.classCode,
      id = _parseEncounter2.id,
      status = _parseEncounter2.status,
      serviceType = _parseEncounter2.serviceType,
      episodeOfCare = _parseEncounter2.episodeOfCare,
      type = _parseEncounter2.type,
      serviceProvider = _parseEncounter2.serviceProvider,
      priority = _parseEncounter2.priority,
      duration = _parseEncounter2.duration;

  var bodyData = (_bodyData2 = {}, (0, _defineProperty2["default"])(_bodyData2, t('Class'), (_classCode$display2 = classCode.display) !== null && _classCode$display2 !== void 0 ? _classCode$display2 : classCode.code), (0, _defineProperty2["default"])(_bodyData2, t('Type'), _react["default"].createElement(_utils.FhirCodesTooltips, {
    codings: type
  })), (0, _defineProperty2["default"])(_bodyData2, t('Priority'), _react["default"].createElement(_utils.FhirCodesTooltips, {
    codings: priority
  })), (0, _defineProperty2["default"])(_bodyData2, t('Reason'), _react["default"].createElement(_utils.FhirCodesTooltips, {
    codings: reason
  })), (0, _defineProperty2["default"])(_bodyData2, t('Period'), _react["default"].createElement(_utils.FhirPeriod, period)), (0, _defineProperty2["default"])(_bodyData2, t('Service provider'), serviceProvider), (0, _defineProperty2["default"])(_bodyData2, t('Encounter Duration'), duration && "".concat(duration.value, " ").concat(duration.unit)), (0, _defineProperty2["default"])(_bodyData2, t('Service Type'), _react["default"].createElement(_utils.FhirCodesTooltips, {
    codings: serviceType
  })), (0, _defineProperty2["default"])(_bodyData2, t('Episode of care'), (_episodeOfCare$0$disp2 = episodeOfCare === null || episodeOfCare === void 0 ? void 0 : (_episodeOfCare$3 = episodeOfCare[0]) === null || _episodeOfCare$3 === void 0 ? void 0 : _episodeOfCare$3.display) !== null && _episodeOfCare$0$disp2 !== void 0 ? _episodeOfCare$0$disp2 : episodeOfCare === null || episodeOfCare === void 0 ? void 0 : (_episodeOfCare$4 = episodeOfCare[0]) === null || _episodeOfCare$4 === void 0 ? void 0 : _episodeOfCare$4.reference), _bodyData2);
  return {
    title: (_classCode$display3 = classCode.display) !== null && _classCode$display3 !== void 0 ? _classCode$display3 : classCode.code,
    headerLeftData: (0, _defineProperty2["default"])({}, t('Id'), id),
    bodyData: bodyData,
    status: {
      title: status,
      color: 'green'
    }
  };
}