"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useClientSideDataGridFilters = useClientSideDataGridFilters;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _react = require("react");

function _createForOfIteratorHelper(o, allowArrayLike) {
  var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"];

  if (!it) {
    if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") {
      if (it) o = it;
      var i = 0;

      var F = function F() {};

      return {
        s: F,
        n: function n() {
          if (i >= o.length) return {
            done: true
          };
          return {
            done: false,
            value: o[i++]
          };
        },
        e: function e(_e) {
          throw _e;
        },
        f: F
      };
    }

    throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
  }

  var normalCompletion = true,
      didErr = false,
      err;
  return {
    s: function s() {
      it = it.call(o);
    },
    n: function n() {
      var step = it.next();
      normalCompletion = step.done;
      return step;
    },
    e: function e(_e2) {
      didErr = true;
      err = _e2;
    },
    f: function f() {
      try {
        if (!normalCompletion && it["return"] != null) it["return"]();
      } finally {
        if (didErr) throw err;
      }
    }
  };
}

function _unsupportedIterableToArray(o, minLen) {
  if (!o) return;
  if (typeof o === "string") return _arrayLikeToArray(o, minLen);
  var n = Object.prototype.toString.call(o).slice(8, -1);
  if (n === "Object" && o.constructor) n = o.constructor.name;
  if (n === "Map" || n === "Set") return Array.from(o);
  if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen);
}

function _arrayLikeToArray(arr, len) {
  if (len == null || len > arr.length) len = arr.length;

  for (var i = 0, arr2 = new Array(len); i < len; i++) {
    arr2[i] = arr[i];
  }

  return arr2;
}

function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);

  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);
    enumerableOnly && (symbols = symbols.filter(function (sym) {
      return Object.getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }

  return keys;
}

function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      (0, _defineProperty2["default"])(target, key, source[key]);
    }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
    });
  }

  return target;
}

function useClientSideDataGridFilters() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  var initialFilterState = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

  var _useState = (0, _react.useState)(initialFilterState),
      _useState2 = (0, _slicedToArray2["default"])(_useState, 2),
      filterRegistry = _useState2[0],
      setFilterRegistry = _useState2[1];

  var registerFilter = (0, _react.useCallback)(function (key, filterFunc, value) {
    if (filterFunc) {
      setFilterRegistry(function (prev) {
        return _objectSpread(_objectSpread({}, prev), {}, (0, _defineProperty2["default"])({}, key, {
          value: value,
          filterFunc: filterFunc
        }));
      });
    } else {
      setFilterRegistry(function (prev) {
        delete prev[key];
        return _objectSpread({}, prev);
      });
    }
  }, []);
  var deregisterFilter = (0, _react.useCallback)(function (key) {
    registerFilter(key);
  }, [registerFilter]);
  var filteredData = (0, _react.useMemo)(function () {
    var filters = Object.values(filterRegistry);
    var filteredData = [];

    var _iterator = _createForOfIteratorHelper(data),
        _step;

    try {
      dataLoop: for (_iterator.s(); !(_step = _iterator.n()).done;) {
        var item = _step.value;

        var _iterator2 = _createForOfIteratorHelper(filters),
            _step2;

        try {
          for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
            var filter = _step2.value;

            if (filter && !filter.filterFunc(item)) {
              continue dataLoop;
            }
          }
        } catch (err) {
          _iterator2.e(err);
        } finally {
          _iterator2.f();
        }

        filteredData.push(item);
      }
    } catch (err) {
      _iterator.e(err);
    } finally {
      _iterator.f();
    }

    return filteredData;
  }, [filterRegistry, data]);
  return {
    registerFilter: registerFilter,
    filteredData: filteredData,
    filterRegistry: filterRegistry,
    deregisterFilter: deregisterFilter
  };
}