"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ResourceDetails = void 0;

var _react = _interopRequireDefault(require("react"));

var _antd = require("antd");

var _KeyValuePairs = require("../../KeyValuePairs");

require("../index.css");

var Text = _antd.Typography.Text;

var ResourceDetails = function ResourceDetails(props) {
  var title = props.title,
      headerLeftData = props.headerLeftData,
      headerRightData = props.headerRightData,
      headerActions = props.headerActions,
      bodyData = props.bodyData,
      footer = props.footer,
      status = props.status,
      headerLeftDataClasses = props.headerLeftDataClasses,
      _props$theme = props.theme,
      theme = _props$theme === void 0 ? 'default' : _props$theme,
      column = props.column;
  return _react["default"].createElement("div", {
    "data-testid": "details-section",
    className: "details-section"
  }, _react["default"].createElement("div", {
    style: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%'
    }
  }, _react["default"].createElement("div", {
    className: "header-top"
  }, _react["default"].createElement(Text, {
    strong: true
  }, _react["default"].createElement("span", {
    className: "title"
  }, title), status && _react["default"].createElement(_antd.Tag, {
    className: "status",
    color: status.color
  }, status.title)), headerActions && _react["default"].createElement(_react["default"].Fragment, null, headerActions)), _react["default"].createElement("div", {
    className: "header-bottom"
  }, _react["default"].createElement(_KeyValuePairs.ListFlatKeyValues, {
    theme: "light",
    classnames: headerLeftDataClasses,
    data: headerLeftData
  }), headerRightData && _react["default"].createElement("div", {
    style: {
      textAlign: 'right'
    }
  }, _react["default"].createElement(_KeyValuePairs.SingleKeyNestedValue, {
    theme: "light",
    data: headerRightData
  })))), _react["default"].createElement(_antd.Divider, {
    className: "divider"
  }), typeof bodyData === 'function' ? bodyData() : _react["default"].createElement(_KeyValuePairs.KeyValuesDescriptions, {
    theme: theme,
    data: bodyData,
    column: column
  }), footer && _react["default"].createElement(_react["default"].Fragment, null, _react["default"].createElement(_antd.Divider, {
    className: "divider"
  }), footer));
};

exports.ResourceDetails = ResourceDetails;