"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.KeycloakRoleDetails = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _extends2 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));

var _react = _interopRequireDefault(require("react"));

var _antd = require("antd");

var _mls = require("../../../../mls");

var _reactUtils = require("@opensrp/react-utils");

var _userManagement = require("@opensrp/user-management");

var _keycloakService = require("@opensrp/keycloak-service");

var _reactQuery = require("react-query");

var _constants = require("../../../../constants");

function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);

  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);
    enumerableOnly && (symbols = symbols.filter(function (sym) {
      return Object.getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }

  return keys;
}

function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      (0, _defineProperty2["default"])(target, key, source[key]);
    }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
    });
  }

  return target;
}

function _createForOfIteratorHelper(o, allowArrayLike) {
  var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"];

  if (!it) {
    if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") {
      if (it) o = it;
      var i = 0;

      var F = function F() {};

      return {
        s: F,
        n: function n() {
          if (i >= o.length) return {
            done: true
          };
          return {
            done: false,
            value: o[i++]
          };
        },
        e: function e(_e) {
          throw _e;
        },
        f: F
      };
    }

    throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
  }

  var normalCompletion = true,
      didErr = false,
      err;
  return {
    s: function s() {
      it = it.call(o);
    },
    n: function n() {
      var step = it.next();
      normalCompletion = step.done;
      return step;
    },
    e: function e(_e2) {
      didErr = true;
      err = _e2;
    },
    f: function f() {
      try {
        if (!normalCompletion && it["return"] != null) it["return"]();
      } finally {
        if (didErr) throw err;
      }
    }
  };
}

function _unsupportedIterableToArray(o, minLen) {
  if (!o) return;
  if (typeof o === "string") return _arrayLikeToArray(o, minLen);
  var n = Object.prototype.toString.call(o).slice(8, -1);
  if (n === "Object" && o.constructor) n = o.constructor.name;
  if (n === "Map" || n === "Set") return Array.from(o);
  if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen);
}

function _arrayLikeToArray(arr, len) {
  if (len == null || len > arr.length) len = arr.length;

  for (var i = 0, arr2 = new Array(len); i < len; i++) {
    arr2[i] = arr[i];
  }

  return arr2;
}

var Text = _antd.Typography.Text;

var KeycloakRoleDetails = function KeycloakRoleDetails(props) {
  var _useTranslation = (0, _mls.useTranslation)(),
      t = _useTranslation.t;

  var keycloakBaseUrl = props.keycloakBaseUrl,
      resourceId = props.resourceId;

  var _useQuery = (0, _reactQuery.useQuery)([], function () {
    return new _keycloakService.KeycloakService("".concat(_userManagement.KEYCLOAK_URL_USERS, "/").concat(resourceId, "/").concat(_constants.keycloakRoleMappingsEndpoint), keycloakBaseUrl).list();
  }),
      data = _useQuery.data,
      error = _useQuery.error,
      isLoading = _useQuery.isLoading;

  if (error && !data) {
    return _react["default"].createElement(_antd.Alert, {
      type: "error"
    }, t("An error occurred while fetching the user's roles"));
  }

  var _ref = data !== null && data !== void 0 ? data : {},
      realmMappings = _ref.realmMappings,
      clientMappings = _ref.clientMappings;

  var realmRoles = realmMappings !== null && realmMappings !== void 0 ? realmMappings : [];
  var clientRoles = flattenClientRoles(clientMappings);
  var realmRoleTableColumn = [{
    title: t('Name'),
    dataIndex: 'name'
  }, {
    title: t('Description'),
    dataIndex: 'description'
  }];
  var clientRoleTableColumn = [{
    title: t('Client'),
    dataIndex: 'client'
  }, {
    title: t('Name'),
    dataIndex: 'name'
  }, {
    title: t('Description'),
    dataIndex: 'description'
  }];
  var realmRolesTableProps = {
    datasource: realmRoles,
    columns: realmRoleTableColumn,
    loading: isLoading,
    size: 'small',
    key: 'realms'
  };
  var clientRolesTableProps = {
    datasource: clientRoles,
    columns: clientRoleTableColumn,
    loading: isLoading,
    size: 'small',
    key: 'client'
  };
  return _react["default"].createElement(_react["default"].Fragment, null, _react["default"].createElement("div", {
    style: {
      display: 'flex',
      flexDirection: 'column',
      gap: '32px'
    }
  }, _react["default"].createElement("section", null, _react["default"].createElement(Text, {
    strong: true
  }, "Realm Roles"), _react["default"].createElement(_reactUtils.TableLayout, (0, _extends2["default"])({}, realmRolesTableProps, {
    key: "realm"
  }))), _react["default"].createElement("section", null, _react["default"].createElement(Text, {
    strong: true
  }, "Client Roles"), _react["default"].createElement(_reactUtils.TableLayout, (0, _extends2["default"])({}, clientRolesTableProps, {
    key: "client"
  })))));
};

exports.KeycloakRoleDetails = KeycloakRoleDetails;

var flattenClientRoles = function flattenClientRoles(clientRoleMapping) {
  var mapping = clientRoleMapping !== null && clientRoleMapping !== void 0 ? clientRoleMapping : {};
  var rtv = [];

  for (var _i = 0, _Object$values = Object.values(mapping); _i < _Object$values.length; _i++) {
    var roleObj = _Object$values[_i];
    var client = roleObj.client;

    var _iterator = _createForOfIteratorHelper(roleObj.mappings),
        _step;

    try {
      for (_iterator.s(); !(_step = _iterator.n()).done;) {
        var role = _step.value;
        rtv.push(_objectSpread(_objectSpread({}, role), {}, {
          client: client
        }));
      }
    } catch (err) {
      _iterator.e(err);
    } finally {
      _iterator.f();
    }
  }

  return rtv;
};