"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.eusmPhysicalLocationsFilterParams = void 0;
exports.getEntryFromBundle = getEntryFromBundle;
exports.getResourceParentName = getResourceParentName;
exports.getTableData = getTableData;

var _constants = require("../../constants");

var _fhirHelpers = require("@opensrp/fhir-helpers");

function getEntryFromBundle(bundle) {
  var _bundle$entry;

  var entry = ((_bundle$entry = bundle.entry) === null || _bundle$entry === void 0 ? void 0 : _bundle$entry.filter(function (x) {
    return x !== undefined;
  })) || [];
  return entry;
}

function getResourceParentName(resource, resourcesById) {
  var _resource$partOf, _resource$partOf2, _resourcesById$parent;

  var partOfRef = (_resource$partOf = resource.partOf) === null || _resource$partOf === void 0 ? void 0 : _resource$partOf.reference;
  var partOfDisplay = (_resource$partOf2 = resource.partOf) === null || _resource$partOf2 === void 0 ? void 0 : _resource$partOf2.display;

  if (!partOfRef) {
    return '';
  }

  var parentId = partOfRef.split('/')[1];
  var parentResource = (_resourcesById$parent = resourcesById[parentId]) !== null && _resourcesById$parent !== void 0 ? _resourcesById$parent : {};
  var parentName = parentResource.name;
  return parentName || partOfDisplay || '';
}

function getTableData(data) {
  var resourcesById = {};
  var tableData = [];
  data.forEach(function (entry) {
    var _entry$resource;

    var id = (_entry$resource = entry.resource) === null || _entry$resource === void 0 ? void 0 : _entry$resource.id;

    if (id) {
      resourcesById[id] = entry.resource;
    }
  });
  data.forEach(function (entry) {
    var _entry$search;

    if (((_entry$search = entry.search) === null || _entry$search === void 0 ? void 0 : _entry$search.mode) === _constants.MATCH_SEARCH_MODE) {
      var _resource$physicalTyp, _resource$physicalTyp2;

      var resource = entry.resource;
      var rowData = {
        key: resource.id,
        id: resource.id,
        name: resource.name,
        type: (_resource$physicalTyp = resource.physicalType) === null || _resource$physicalTyp === void 0 ? void 0 : (_resource$physicalTyp2 = _resource$physicalTyp.coding[0]) === null || _resource$physicalTyp2 === void 0 ? void 0 : _resource$physicalTyp2.display,
        status: resource.status,
        parent: getResourceParentName(resource, resourcesById),
        location: resource
      };
      tableData.push(rowData);
    }
  });
  return tableData;
}

var eusmPhysicalLocationsFilterParams = {
  type: "".concat(_fhirHelpers.hl7PhysicalTypeCodeSystemUri, "|").concat(_fhirHelpers.PhysicalTypeCodes.BUILDING)
};
exports.eusmPhysicalLocationsFilterParams = eusmPhysicalLocationsFilterParams;