"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.LocationFlag = void 0;

var _regenerator = _interopRequireDefault(require("@babel/runtime/regenerator"));

var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));

var _react = _interopRequireDefault(require("react"));

var _CloseFlagForm = require("../CloseFlagForm");

var _antd = require("antd");

var _reactQuery = require("react-query");

var _reactUtils = require("@opensrp/react-utils");

var _fhirHelpers = require("@opensrp/fhir-helpers");

var _utils = require("../Utils/utils");

var LocationFlag = function LocationFlag(props) {
  var _location$name;

  var fhirBaseUrl = props.fhirBaseUrl,
      locationReference = props.locationReference,
      flag = props.flag,
      practitionerId = props.practitionerId;
  var thatiMinutes = 30 * 60 * 1000;

  var _useQuery = (0, _reactQuery.useQuery)([_fhirHelpers.locationResourceType, locationReference], (0, _asyncToGenerator2["default"])(_regenerator["default"].mark(function _callee() {
    return _regenerator["default"].wrap(function _callee$(_context) {
      while (1) {
        switch (_context.prev = _context.next) {
          case 0:
            return _context.abrupt("return", new _reactUtils.FHIRServiceClass(fhirBaseUrl, '').read("".concat(locationReference)));

          case 1:
          case "end":
            return _context.stop();
        }
      }
    }, _callee);
  })), {
    enabled: !!locationReference,
    staleTime: thatiMinutes
  }),
      location = _useQuery.data,
      isLoading = _useQuery.isLoading,
      error = _useQuery.error;

  if (isLoading) {
    return _react["default"].createElement(_antd.Spin, {
      size: "large",
      className: "custom-spinner"
    });
  }

  if (error && !location) {
    return _react["default"].createElement(_reactUtils.BrokenPage, {
      errorMessage: error.message
    });
  }

  var initialValues = {
    locationName: (_location$name = location === null || location === void 0 ? void 0 : location.name) !== null && _location$name !== void 0 ? _location$name : location === null || location === void 0 ? void 0 : location.id,
    practitionerId: practitionerId,
    status: flag.status
  };
  return _react["default"].createElement(_CloseFlagForm.CloseFlagForm, {
    initialValues: initialValues,
    flag: flag,
    mutationEffect: function () {
      var _ref2 = (0, _asyncToGenerator2["default"])(_regenerator["default"].mark(function _callee2(initialValues, values, activeFlag) {
        return _regenerator["default"].wrap(function _callee2$(_context2) {
          while (1) {
            switch (_context2.prev = _context2.next) {
              case 0:
                return _context2.abrupt("return", (0, _utils.putCloseFlagResources)(initialValues, values, activeFlag, fhirBaseUrl));

              case 1:
              case "end":
                return _context2.stop();
            }
          }
        }, _callee2);
      }));

      return function (_x, _x2, _x3) {
        return _ref2.apply(this, arguments);
      };
    }()
  });
};

exports.LocationFlag = LocationFlag;
var _default = LocationFlag;
exports["default"] = _default;