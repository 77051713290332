"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.carePlanDetailsProps = carePlanDetailsProps;
exports.parseCareplanList = exports.parseCareplan = exports.columns = exports.carePlanSideViewData = void 0;

var _regenerator = _interopRequireDefault(require("@babel/runtime/regenerator"));

var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _react = _interopRequireDefault(require("react"));

var _lodash = require("lodash");

var _utils = require("../../../helpers/utils");

var _reactUtils = require("@opensrp/react-utils");

var _constants = require("../../../constants");

var parseCareplan = function parseCareplan(obj) {
  return {
    title: (0, _lodash.get)(obj, 'title'),
    period: (0, _lodash.get)(obj, 'period'),
    description: (0, _lodash.get)(obj, 'description'),
    intent: (0, _lodash.get)(obj, 'intent'),
    categories: (0, _utils.getCodeableConcepts)((0, _lodash.get)(obj, 'category')),
    instantiatesCanonical: (0, _lodash.get)(obj, 'instantiatesCanonical'),
    status: (0, _lodash.get)(obj, 'status'),
    created: (0, _lodash.get)(obj, 'created'),
    id: (0, _lodash.get)(obj, 'id')
  };
};

exports.parseCareplan = parseCareplan;

var parseCareplanList = function parseCareplanList(carePlans) {
  return carePlans.map(parseCareplan);
};

exports.parseCareplanList = parseCareplanList;

var columns = function columns(t) {
  return [{
    title: t('Title'),
    dataIndex: 'title',
    sorter: (0, _utils.sorterFn)('title')
  }, {
    title: t('Description'),
    dataIndex: 'description'
  }, {
    title: t('Period'),
    dataIndex: 'period',
    render: function render(value) {
      return _react["default"].createElement(_utils.FhirPeriod, value);
    }
  }];
};

exports.columns = columns;

var carePlanSideViewData = function carePlanSideViewData(resource, t) {
  var _bodyData;

  var _parseCareplan = parseCareplan(resource),
      id = _parseCareplan.id,
      categories = _parseCareplan.categories,
      period = _parseCareplan.period,
      title = _parseCareplan.title,
      intent = _parseCareplan.intent,
      status = _parseCareplan.status;

  var headerLeftData = (0, _defineProperty2["default"])({}, t('ID'), id);
  var bodyData = (_bodyData = {}, (0, _defineProperty2["default"])(_bodyData, t('Category'), _react["default"].createElement(_utils.FhirCodesTooltips, {
    codings: categories
  })), (0, _defineProperty2["default"])(_bodyData, t('Period'), _react["default"].createElement(_utils.FhirPeriod, period)), (0, _defineProperty2["default"])(_bodyData, t('Status'), status), (0, _defineProperty2["default"])(_bodyData, t('Intent'), intent), _bodyData);
  return {
    title: title,
    headerLeftData: headerLeftData,
    bodyData: bodyData,
    status: {
      title: status !== null && status !== void 0 ? status : '',
      color: 'green'
    }
  };
};

exports.carePlanSideViewData = carePlanSideViewData;

var getPlandefinitionTitle = function getPlandefinitionTitle(instantiatesCanonical, fhirBaseURL) {
  var _instantiatesCanonica;

  var planDefinitionId = (0, _lodash.last)(instantiatesCanonical === null || instantiatesCanonical === void 0 ? void 0 : (_instantiatesCanonica = instantiatesCanonical[0]) === null || _instantiatesCanonica === void 0 ? void 0 : _instantiatesCanonica.split('/'));

  if (fhirBaseURL && planDefinitionId) {
    var props = {
      resourceQueryParams: {
        queryKey: [_constants.planDefinitionType, planDefinitionId],
        queryFn: function () {
          var _queryFn = (0, _asyncToGenerator2["default"])(_regenerator["default"].mark(function _callee() {
            return _regenerator["default"].wrap(function _callee$(_context) {
              while (1) {
                switch (_context.prev = _context.next) {
                  case 0:
                    return _context.abrupt("return", new _reactUtils.FHIRServiceClass(fhirBaseURL, _constants.planDefinitionType).read(planDefinitionId));

                  case 1:
                  case "end":
                    return _context.stop();
                }
              }
            }, _callee);
          }));

          function queryFn() {
            return _queryFn.apply(this, arguments);
          }

          return queryFn;
        }()
      },
      itemGetter: function itemGetter(obj) {
        var _obj$title;

        return (_obj$title = obj.title) !== null && _obj$title !== void 0 ? _obj$title : obj.name;
      }
    };
    return _react["default"].createElement(_reactUtils.ResourceItemLoader, props);
  }

  return null;
};

function carePlanDetailsProps(resource, t, fhirBaseURL) {
  var _bodyData2;

  var _parseCareplan2 = parseCareplan(resource),
      id = _parseCareplan2.id,
      categories = _parseCareplan2.categories,
      description = _parseCareplan2.description,
      period = _parseCareplan2.period,
      title = _parseCareplan2.title,
      intent = _parseCareplan2.intent,
      status = _parseCareplan2.status,
      created = _parseCareplan2.created,
      instantiatesCanonical = _parseCareplan2.instantiatesCanonical;

  var headerRightData = (0, _defineProperty2["default"])({}, t('Date created'), (0, _reactUtils.dateToLocaleString)(created));
  var bodyData = (_bodyData2 = {}, (0, _defineProperty2["default"])(_bodyData2, t('Category'), _react["default"].createElement(_utils.FhirCodesTooltips, {
    codings: categories
  })), (0, _defineProperty2["default"])(_bodyData2, t('Period'), _react["default"].createElement(_utils.FhirPeriod, period)), (0, _defineProperty2["default"])(_bodyData2, t('Status'), status), (0, _defineProperty2["default"])(_bodyData2, t('Intent'), intent), (0, _defineProperty2["default"])(_bodyData2, t('Canonical (PlanDefinition)'), getPlandefinitionTitle(instantiatesCanonical, fhirBaseURL)), (0, _defineProperty2["default"])(_bodyData2, t('Address'), (0, _lodash.get)(resource, 'address.0.line.0') || 'N/A'), (0, _defineProperty2["default"])(_bodyData2, t('Description'), description), _bodyData2);
  return {
    title: title,
    headerRightData: headerRightData,
    headerLeftData: (0, _defineProperty2["default"])({}, t('Id'), id),
    bodyData: bodyData,
    status: {
      title: status,
      color: 'green'
    }
  };
}