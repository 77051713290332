"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _ResourceDetails = require("./ResourceDetails");

Object.keys(_ResourceDetails).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _ResourceDetails[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _ResourceDetails[key];
    }
  });
});

var _TabsView = require("./TabsView");

Object.keys(_TabsView).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _TabsView[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _TabsView[key];
    }
  });
});

var _TabsTable = require("./TabsTable");

Object.keys(_TabsTable).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _TabsTable[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _TabsTable[key];
    }
  });
});

var _PopulatedResourceDetails = require("./PopulatedResourceDetails");

Object.keys(_PopulatedResourceDetails).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _PopulatedResourceDetails[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _PopulatedResourceDetails[key];
    }
  });
});

var _TabsTitle = require("./TabsTitle");

Object.keys(_TabsTitle).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _TabsTitle[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _TabsTitle[key];
    }
  });
});

var _ResourceItemLoader = require("./ResourceItemLoader");

Object.keys(_ResourceItemLoader).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _ResourceItemLoader[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _ResourceItemLoader[key];
    }
  });
});