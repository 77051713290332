"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PatientDetailsOverview = void 0;

var _regenerator = _interopRequireDefault(require("@babel/runtime/regenerator"));

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));

var _react = _interopRequireDefault(require("react"));

var _antd = require("antd");

var _icons = require("@ant-design/icons");

var _reactQuery = require("react-query");

var _reactUtils = require("@opensrp/react-utils");

var _constants = require("../../constants");

var _mls = require("../../mls");

var _utils = require("../PatientsList/utils");

var _reactRouterDom = require("react-router-dom");

var PatientDetailsOverview = function PatientDetailsOverview(props) {
  var _sParams$get;

  var fhirBaseURL = props.fhirBaseURL;

  var _useTranslation = (0, _mls.useTranslation)(),
      t = _useTranslation.t;

  var _useSearchParams = (0, _reactUtils.useSearchParams)(),
      removeParam = _useSearchParams.removeParam,
      sParams = _useSearchParams.sParams;

  var patientId = (_sParams$get = sParams.get(_reactUtils.viewDetailsQuery)) !== null && _sParams$get !== void 0 ? _sParams$get : undefined;

  var _useQuery = (0, _reactQuery.useQuery)({
    queryKey: [_constants.patientResourceType, patientId],
    queryFn: function () {
      var _queryFn = (0, _asyncToGenerator2["default"])(_regenerator["default"].mark(function _callee() {
        return _regenerator["default"].wrap(function _callee$(_context) {
          while (1) {
            switch (_context.prev = _context.next) {
              case 0:
                return _context.abrupt("return", new _reactUtils.FHIRServiceClass(fhirBaseURL, _constants.patientResourceType).read(patientId));

              case 1:
              case "end":
                return _context.stop();
            }
          }
        }, _callee);
      }));

      function queryFn() {
        return _queryFn.apply(this, arguments);
      }

      return queryFn;
    }(),
    enabled: !!patientId
  }),
      data = _useQuery.data,
      error = _useQuery.error,
      isLoading = _useQuery.isLoading;

  if (!patientId) {
    return null;
  }

  if (error && !data) {
    return _react["default"].createElement(_reactUtils.BrokenPage, {
      errorMessage: error.message
    });
  }

  var getPreviewProps = function getPreviewProps(resourceData) {
    var _identifier$, _address$0$line$, _address$, _address$$line, _address$2, _headerLeftData;

    var id = resourceData.id,
        gender = resourceData.gender,
        birthDate = resourceData.birthDate,
        address = resourceData.address,
        telecom = resourceData.telecom,
        identifier = resourceData.identifier,
        deceasedBoolean = resourceData.deceasedBoolean,
        active = resourceData.active;
    var status = {
      title: t('Inactive'),
      color: 'gray'
    };

    if (deceasedBoolean) {
      status = {
        title: t('Deceased'),
        color: 'red'
      };
    } else if (active) {
      status = {
        title: t('Active'),
        color: 'green'
      };
    }

    var patientInfo = {
      UUID: identifier && identifier.length > 0 ? (_identifier$ = identifier[0]) === null || _identifier$ === void 0 ? void 0 : _identifier$.value : '',
      Phone: telecom && telecom.length > 0 ? telecom[0].value : '',
      Address: (_address$0$line$ = address === null || address === void 0 ? void 0 : (_address$ = address[0]) === null || _address$ === void 0 ? void 0 : (_address$$line = _address$.line) === null || _address$$line === void 0 ? void 0 : _address$$line[0]) !== null && _address$0$line$ !== void 0 ? _address$0$line$ : '',
      'Date of birth': birthDate,
      MRN: 'Unknown',
      Country: address && address.length > 0 ? (_address$2 = address[0]) === null || _address$2 === void 0 ? void 0 : _address$2.country : ''
    };
    var resourceDetailsProp = {
      title: (0, _utils.getPatientName)(data),
      headerLeftData: (_headerLeftData = {}, (0, _defineProperty2["default"])(_headerLeftData, t('ID'), id), (0, _defineProperty2["default"])(_headerLeftData, t('Gender'), gender), _headerLeftData),
      status: status,
      headerActions: _react["default"].createElement(_antd.Button, {
        "data-testid": "cancel",
        icon: _react["default"].createElement(_icons.CloseOutlined, null),
        shape: "circle",
        type: "text",
        onClick: function onClick() {
          return removeParam(_reactUtils.viewDetailsQuery);
        }
      }),
      bodyData: function bodyData() {
        return _react["default"].createElement(_reactUtils.KeyValuesDescriptions, {
          data: patientInfo,
          column: 2,
          theme: "default"
        });
      },
      footer: _react["default"].createElement(_reactRouterDom.Link, {
        to: "".concat(_constants.LIST_PATIENTS_URL, "/").concat(id),
        className: "m-0 p-1"
      }, t('View full details'))
    };
    return resourceDetailsProp;
  };

  return _react["default"].createElement(_antd.Col, {
    className: "view-details-content",
    span: 8
  }, isLoading ? _react["default"].createElement(_antd.Alert, {
    description: t('Fetching Patient details'),
    type: "info",
    showIcon: true,
    icon: _react["default"].createElement(_icons.SyncOutlined, {
      spin: true
    })
  }) : _react["default"].createElement(_react["default"].Fragment, null, data && _react["default"].createElement(_reactUtils.ResourceDetails, getPreviewProps(data))));
};

exports.PatientDetailsOverview = PatientDetailsOverview;