"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PatientDetails = void 0;

var _react = _interopRequireDefault(require("react"));

var _reactRouterDom = require("react-router-dom");

var _reactUtils = require("@opensrp/react-utils");

var _constants = require("../../constants");

var _mls = require("../../mls");

var _utils = require("./utils");

var _PopulatedTableTabs = require("./PopulatedTableTabs");

require("./index.css");

var PatientDetails = function PatientDetails(props) {
  var fhirBaseURL = props.fhirBaseURL;

  var _useParams = (0, _reactRouterDom.useParams)(),
      patientId = _useParams.id,
      resourceType = _useParams.resourceType,
      resourceId = _useParams.resourceId;

  var _useTranslation = (0, _mls.useTranslation)(),
      t = _useTranslation.t;

  var activeResourceId = resourceId !== null && resourceId !== void 0 ? resourceId : patientId;
  var activeResourceType = resourceType !== null && resourceType !== void 0 ? resourceType : _constants.patientResourceType;
  var isPatientDetailsPage = !(resourceType && resourceId);
  var pageTitle = t('View details');
  var viewDetailsItem = {
    title: t('View details')
  };
  var breadCrumbItems = [{
    title: t('Patients'),
    path: _constants.LIST_PATIENTS_URL
  }, viewDetailsItem];

  if (!isPatientDetailsPage) {
    breadCrumbItems[1] = {
      title: t('Patient profile'),
      path: "".concat(_constants.LIST_PATIENTS_URL, "/").concat(patientId)
    };
    breadCrumbItems.push(viewDetailsItem);
  }

  var headerProps = {
    pageHeaderProps: {
      title: pageTitle,
      subTitle: activeResourceId
    },
    breadCrumbProps: {
      items: breadCrumbItems
    }
  };
  var populatedResourceDetailsProps = {
    resourceQueryParams: (0, _utils.queryParamsFactory)(fhirBaseURL, activeResourceType, activeResourceId),
    resourceDetailsPropsGetter: (0, _utils.getResourceDetailsProps)(activeResourceType),
    fhirBaseURL: fhirBaseURL
  };
  var tableTabsProps = {
    fhirBaseURL: fhirBaseURL,
    patientId: patientId
  };
  return _react["default"].createElement(_reactUtils.BodyLayout, {
    headerProps: headerProps
  }, _react["default"].createElement("div", {
    className: "details-content"
  }, _react["default"].createElement(_reactUtils.PopulatedResourceDetails, populatedResourceDetailsProps), isPatientDetailsPage && _react["default"].createElement(_PopulatedTableTabs.MemoizePopulatedTableTabs, tableTabsProps)));
};

exports.PatientDetails = PatientDetails;