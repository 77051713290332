"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.users = exports.userToOrganizationAssignment = exports.products = exports.organizations = exports.orgToLocationAssignment = exports.locations = exports.inventories = exports.defaultImportTemplateName = exports.dataImportRQueryKey = exports.careteams = exports.IMPORT_TEMPLATE_ENDPOINT = exports.IMPORT_ENDPOINT = exports.IMPORT_DOMAIN_URI = exports.DATA_IMPORT_LIST_URL = exports.DATA_IMPORT_DETAIL_URL = exports.DATA_IMPORT_CREATE_URL = void 0;
var DATA_IMPORT_LIST_URL = '/import';
exports.DATA_IMPORT_LIST_URL = DATA_IMPORT_LIST_URL;
var DATA_IMPORT_DETAIL_URL = '/importDetail';
exports.DATA_IMPORT_DETAIL_URL = DATA_IMPORT_DETAIL_URL;
var DATA_IMPORT_CREATE_URL = '/importCreate';
exports.DATA_IMPORT_CREATE_URL = DATA_IMPORT_CREATE_URL;
var IMPORT_ENDPOINT = '/$import';
exports.IMPORT_ENDPOINT = IMPORT_ENDPOINT;
var IMPORT_DOMAIN_URI = '';
exports.IMPORT_DOMAIN_URI = IMPORT_DOMAIN_URI;
var IMPORT_TEMPLATE_ENDPOINT = "".concat(IMPORT_ENDPOINT, "/templates");
exports.IMPORT_TEMPLATE_ENDPOINT = IMPORT_TEMPLATE_ENDPOINT;
var dataImportRQueryKey = 'dataImport';
exports.dataImportRQueryKey = dataImportRQueryKey;
var locations = 'locations';
exports.locations = locations;
var users = 'users';
exports.users = users;
var organizations = 'organizations';
exports.organizations = organizations;
var careteams = 'careTeams';
exports.careteams = careteams;
var inventories = 'inventories';
exports.inventories = inventories;
var orgToLocationAssignment = 'orgToLocationAssignment';
exports.orgToLocationAssignment = orgToLocationAssignment;
var userToOrganizationAssignment = 'userToOrganizationAssignment';
exports.userToOrganizationAssignment = userToOrganizationAssignment;
var products = 'products';
exports.products = products;
var defaultImportTemplateName = 'import-template';
exports.defaultImportTemplateName = defaultImportTemplateName;