"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.CloseFlag = void 0;

var _regenerator = _interopRequireDefault(require("@babel/runtime/regenerator"));

var _extends2 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));

var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));

var _react = _interopRequireDefault(require("react"));

var _reactRouter = require("react-router");

var _fhirHelpers = require("@opensrp/fhir-helpers");

var _antd = require("antd");

var _reactQuery = require("react-query");

var _reactUtils = require("@opensrp/react-utils");

var _ProductFlag = _interopRequireDefault(require("../ProductFlag"));

var _LocationFlag = _interopRequireDefault(require("../LocationFlag"));

var _reactRedux = require("react-redux");

var _sessionReducer = require("@onaio/session-reducer");

var _mls = require("../../mls");

var _constants = require("../../constants");

var _rbac = require("@opensrp/rbac");

var CloseFlag = function CloseFlag(props) {
  var _practitioner$data, _flag$data, _flag$data$subject$re, _flag$data2;

  var fhirBaseUrl = props.fhirBaseURL;

  var _useTranslation = (0, _mls.useTranslation)(),
      t = _useTranslation.t;

  var extraData = (0, _reactRedux.useSelector)(function (state) {
    return (0, _sessionReducer.getExtraData)(state);
  });
  var user_id = extraData.user_id;

  var _useParams = (0, _reactRouter.useParams)(),
      flagId = _useParams.id;

  var flag = (0, _reactQuery.useQuery)([_fhirHelpers.FlagResourceType, flagId], (0, _asyncToGenerator2["default"])(_regenerator["default"].mark(function _callee() {
    return _regenerator["default"].wrap(function _callee$(_context) {
      while (1) {
        switch (_context.prev = _context.next) {
          case 0:
            return _context.abrupt("return", new _reactUtils.FHIRServiceClass(fhirBaseUrl, _fhirHelpers.FlagResourceType).read("".concat(flagId)));

          case 1:
          case "end":
            return _context.stop();
        }
      }
    }, _callee);
  })), {
    enabled: !!flagId,
    staleTime: _constants.thatiMinutes
  });
  var practitioner = (0, _reactQuery.useQuery)([_fhirHelpers.PractitionerResourceType, user_id], function () {
    return new _reactUtils.FHIRServiceClass(fhirBaseUrl, _fhirHelpers.PractitionerResourceType).list({
      identifier: user_id
    });
  }, {
    enabled: !!user_id,
    staleTime: _constants.thatiMinutes,
    select: function select(response) {
      return (0, _reactUtils.getResourcesFromBundle)(response)[0];
    }
  });

  if (flag.isLoading || practitioner.isLoading) {
    return _react["default"].createElement(_antd.Spin, {
      size: "large",
      className: "custom-spinner"
    });
  }

  if (flag.error && !flag.data) {
    return _react["default"].createElement(_reactUtils.BrokenPage, {
      errorMessage: t('Error occurred while trying to fetch flag data')
    });
  }

  if (practitioner.error && !practitioner.data) {
    return _react["default"].createElement(_reactUtils.BrokenPage, {
      errorMessage: t('Error occurred while trying to fetch practitioners data')
    });
  }

  var commonProps = {
    practitionerId: (_practitioner$data = practitioner.data) === null || _practitioner$data === void 0 ? void 0 : _practitioner$data.id,
    fhirBaseUrl: fhirBaseUrl,
    flag: flag.data
  };
  return _react["default"].createElement(_antd.Row, null, _react["default"].createElement(_antd.Col, {
    span: 24
  }, (_flag$data = flag.data) !== null && _flag$data !== void 0 && (_flag$data$subject$re = _flag$data.subject.reference) !== null && _flag$data$subject$re !== void 0 && _flag$data$subject$re.includes('Location') ? _react["default"].createElement(_rbac.RbacCheck, {
    permissions: ['Location.read'],
    fallback: t('Missing Required permissions to perform this action')
  }, _react["default"].createElement(_LocationFlag["default"], (0, _extends2["default"])({}, commonProps, {
    locationReference: flag.data.subject.reference
  }))) : _react["default"].createElement(_rbac.RbacCheck, {
    permissions: ['Group.read', 'Location.read', 'List.read'],
    fallback: t('Missing Required permissions to perform this action')
  }, _react["default"].createElement(_ProductFlag["default"], (0, _extends2["default"])({}, commonProps, {
    inventoryGroupReference: (_flag$data2 = flag.data) === null || _flag$data2 === void 0 ? void 0 : _flag$data2.subject.reference
  })))));
};

exports.CloseFlag = CloseFlag;
var _default = CloseFlag;
exports["default"] = _default;