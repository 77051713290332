"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CommodityForm = CommodityForm;

var _regenerator = _interopRequireDefault(require("@babel/runtime/regenerator"));

var _extends2 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));

var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));

var _react = _interopRequireDefault(require("react"));

var _antd = require("antd");

var _constants = require("../../constants");

var _notifications = require("@opensrp/notifications");

var _reactQuery = require("react-query");

var _reactUtils = require("@opensrp/react-utils");

var _reactRouter = require("react-router");

var _mls = require("../../mls");

var _icons = require("@ant-design/icons");

var _utils = require("./utils");

var _rbac = require("@opensrp/rbac");

var FormItem = _antd.Form.Item;
var defaultProps = {
  initialValues: {},
  disabled: [],
  hidden: []
};

function CommodityForm(props) {
  var mutationEffect = props.mutationEffect,
      initialValues = props.initialValues,
      disabled = props.disabled,
      hidden = props.hidden,
      cancelUrl = props.cancelUrl,
      successUrl = props.successUrl,
      postSuccess = props.postSuccess,
      validationRulesFactory = props.validationRulesFactory;
  var queryClient = (0, _reactQuery.useQueryClient)();
  var history = (0, _reactRouter.useHistory)();

  var _useTranslation = (0, _mls.useTranslation)(),
      t = _useTranslation.t;

  var goTo = function goTo() {
    var url = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '#';
    return history.push(url);
  };

  var userRole = (0, _rbac.useUserRole)();

  if (!userRole.hasPermissions(['Binary.create'])) {
    disabled.push(_constants.productImage);
  }

  var _useMutation = (0, _reactQuery.useMutation)(function (values) {
    return mutationEffect(initialValues, values);
  }, {
    onError: function onError(err) {
      (0, _notifications.sendErrorNotification)(err.message);
    },
    onSuccess: function () {
      var _onSuccess = (0, _asyncToGenerator2["default"])(_regenerator["default"].mark(function _callee(mutationEffectResponse) {
        var isEdit;
        return _regenerator["default"].wrap(function _callee$(_context) {
          while (1) {
            switch (_context.prev = _context.next) {
              case 0:
                (0, _notifications.sendSuccessNotification)(t('Commodity updated successfully'));
                isEdit = !!initialValues.id;
                _context.next = 4;
                return postSuccess === null || postSuccess === void 0 ? void 0 : postSuccess(mutationEffectResponse, isEdit)["catch"](function (err) {
                  (0, _notifications.sendErrorNotification)(err.message);
                });

              case 4:
                queryClient.invalidateQueries([_constants.groupResourceType])["catch"](function () {
                  (0, _notifications.sendInfoNotification)(t('Failed to refresh data, please refresh the page'));
                });
                goTo(successUrl);

              case 6:
              case "end":
                return _context.stop();
            }
          }
        }, _callee);
      }));

      function onSuccess(_x) {
        return _onSuccess.apply(this, arguments);
      }

      return onSuccess;
    }()
  }),
      mutate = _useMutation.mutate,
      isLoading = _useMutation.isLoading;

  var statusOptions = [{
    label: t('Active'),
    value: true
  }, {
    label: t('Disabled'),
    value: false
  }];
  var attractiveOptions = [{
    label: t('Yes'),
    value: true
  }, {
    label: t('No'),
    value: false
  }];
  var unitsOfMeasureOptions = (0, _utils.getUnitOfMeasureOptions)();
  var typeOptions = (0, _utils.getGroupTypeOptions)();
  var validationRules = validationRulesFactory(t);
  return _react["default"].createElement(_antd.Form, (0, _extends2["default"])({}, _reactUtils.formItemLayout, {
    onFinish: function onFinish(values) {
      mutate(values);
    },
    initialValues: initialValues
  }), _react["default"].createElement(FormItem, {
    hidden: true,
    id: "id",
    name: _constants.id,
    label: t('Commodity Id')
  }, _react["default"].createElement(_antd.Input, {
    placeholder: t('(Auto generated)'),
    disabled: true
  })), _react["default"].createElement(FormItem, {
    hidden: true,
    id: "identifier",
    name: _constants.identifier,
    label: t('Identifier')
  }, _react["default"].createElement(_antd.Input, {
    placeholder: t('(Auto generated)'),
    disabled: true
  })), _react["default"].createElement(FormItem, {
    id: _constants.name,
    hidden: hidden.includes(_constants.name),
    name: _constants.name,
    rules: validationRules[_constants.name],
    label: t('Enter commodity name')
  }, _react["default"].createElement(_antd.Input, {
    disabled: disabled.includes(_constants.name),
    placeholder: t('Name')
  })), _react["default"].createElement(FormItem, {
    id: _constants.materialNumber,
    name: _constants.materialNumber,
    label: t('Material number'),
    hidden: hidden.includes(_constants.materialNumber),
    rules: validationRules[_constants.materialNumber]
  }, _react["default"].createElement(_antd.Input, {
    disabled: disabled.includes(_constants.materialNumber),
    placeholder: t("Enter the product's material number")
  })), _react["default"].createElement(FormItem, {
    id: _constants.active,
    rules: validationRules[_constants.active],
    name: _constants.active,
    hidden: hidden.includes(_constants.active),
    label: t('Select commodity status')
  }, _react["default"].createElement(_antd.Radio.Group, {
    disabled: disabled.includes(_constants.active),
    options: statusOptions
  })), _react["default"].createElement(FormItem, {
    id: _constants.type,
    name: _constants.type,
    hidden: hidden.includes(_constants.type),
    rules: validationRules[_constants.type],
    label: t('Select commodity Type')
  }, _react["default"].createElement(_antd.Select, {
    disabled: disabled.includes(_constants.type),
    placeholder: t('Select commodity type'),
    options: typeOptions,
    showSearch: true,
    filterOption: _utils.groupSelectfilterFunction
  })), _react["default"].createElement(FormItem, {
    id: _constants.unitOfMeasure,
    name: _constants.unitOfMeasure,
    hidden: hidden.includes(_constants.unitOfMeasure),
    rules: validationRules[_constants.unitOfMeasure],
    label: t('Select the unit of measure')
  }, _react["default"].createElement(_antd.Select, {
    disabled: disabled.includes(_constants.unitOfMeasure),
    placeholder: t('Select the unit of measure'),
    options: unitsOfMeasureOptions,
    showSearch: true,
    filterOption: _utils.groupSelectfilterFunction
  })), _react["default"].createElement(FormItem, {
    id: _constants.isAttractiveItem,
    name: _constants.isAttractiveItem,
    label: t('Is it an Asset'),
    hidden: hidden.includes(_constants.isAttractiveItem),
    rules: validationRules[_constants.isAttractiveItem]
  }, _react["default"].createElement(_antd.Radio.Group, {
    disabled: disabled.includes(_constants.isAttractiveItem),
    options: attractiveOptions
  })), _react["default"].createElement(FormItem, {
    id: _constants.availability,
    name: _constants.availability,
    hidden: hidden.includes(_constants.availability),
    label: t('Is it there'),
    rules: validationRules[_constants.availability]
  }, _react["default"].createElement(_antd.Input.TextArea, {
    rows: 4,
    disabled: disabled.includes(_constants.availability),
    placeholder: t('Describe where a supply monitor can locate this product at the service point.')
  })), _react["default"].createElement(FormItem, {
    id: _constants.condition,
    name: _constants.condition,
    hidden: hidden.includes(_constants.condition),
    label: t('Is it in good condition'),
    rules: validationRules[_constants.condition]
  }, _react["default"].createElement(_antd.Input.TextArea, {
    rows: 4,
    disabled: disabled.includes(_constants.condition),
    placeholder: t('Describe how a supply monitor would assess whether the product is in good condition')
  })), _react["default"].createElement(FormItem, {
    id: _constants.appropriateUsage,
    name: _constants.appropriateUsage,
    hidden: hidden.includes(_constants.appropriateUsage),
    label: t('Is it being used appropriately'),
    rules: validationRules[_constants.appropriateUsage]
  }, _react["default"].createElement(_antd.Input.TextArea, {
    rows: 4,
    disabled: disabled.includes(_constants.appropriateUsage),
    placeholder: t("Describe the product's intended use at the service point")
  })), _react["default"].createElement(FormItem, {
    id: _constants.accountabilityPeriod,
    name: _constants.accountabilityPeriod,
    hidden: hidden.includes(_constants.accountabilityPeriod),
    label: t('Accountability period (in months)'),
    rules: validationRules[_constants.accountabilityPeriod]
  }, _react["default"].createElement(_antd.InputNumber, {
    disabled: disabled.includes(_constants.accountabilityPeriod),
    min: 0
  })), _react["default"].createElement(_antd.Form.Item, {
    noStyle: true,
    dependencies: [_constants.productImage]
  }, function (_ref) {
    var _getFieldValue;

    var getFieldValue = _ref.getFieldValue;
    return _react["default"].createElement(_antd.Form.Item, {
      rules: validationRules[_constants.productImage],
      id: _constants.productImage,
      hidden: hidden.includes(_constants.productImage),
      name: _constants.productImage,
      label: t('Photo of the product'),
      valuePropName: "fileList",
      getValueFromEvent: _utils.normalizeFileInputEvent
    }, _react["default"].createElement(_antd.Upload, {
      id: _constants.productImage,
      beforeUpload: function beforeUpload() {
        return false;
      },
      accept: "image/*",
      multiple: false,
      listType: "picture-card",
      maxCount: 1,
      disabled: disabled.includes(_constants.productImage)
    }, !((_getFieldValue = getFieldValue(_constants.productImage)) !== null && _getFieldValue !== void 0 && _getFieldValue.length) ? _react["default"].createElement("button", {
      "data-testid": "upload-button",
      style: {
        border: 0,
        background: 'none'
      },
      type: "button"
    }, _react["default"].createElement(_icons.PlusOutlined, null), _react["default"].createElement("div", {
      style: {
        marginTop: 8
      }
    }, "Upload")) : null));
  }), _react["default"].createElement(FormItem, _reactUtils.tailLayout, _react["default"].createElement(_antd.Space, null, _react["default"].createElement(_antd.Button, {
    type: "primary",
    id: "submit-button",
    disabled: isLoading,
    htmlType: "submit"
  }, isLoading ? t('Saving') : t('save')), _react["default"].createElement(_antd.Button, {
    id: "cancel-button",
    onClick: function onClick() {
      goTo(cancelUrl);
    }
  }, t('Cancel')))));
}

CommodityForm.defaultProps = defaultProps;