"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.OrganizationDetailsView = void 0;

var _react = _interopRequireDefault(require("react"));

var _mls = require("../../../../mls");

var _reactUtils = require("@opensrp/react-utils");

var _antd = require("antd");

var OrganizationDetailsView = function OrganizationDetailsView(_ref) {
  var _practitionerDetails$;

  var loading = _ref.loading,
      practitionerDetails = _ref.practitionerDetails,
      error = _ref.error;

  var _useTranslation = (0, _mls.useTranslation)(),
      t = _useTranslation.t;

  if (error) {
    return _react["default"].createElement(_antd.Alert, {
      type: "error"
    }, t('An error occurred while trying to fetch the practitioner details.'));
  }

  var organizations = (_practitionerDetails$ = practitionerDetails.teams) !== null && _practitionerDetails$ !== void 0 ? _practitionerDetails$ : [];
  var tableData = organizations.map(function (resource) {
    var id = resource.id,
        active = resource.active,
        type = resource.type,
        name = resource.name;
    return {
      id: id,
      active: active,
      type: type !== null && type !== void 0 ? type : [],
      name: name
    };
  });
  var columns = [{
    title: t('Id'),
    dataIndex: 'id'
  }, {
    title: t('Name'),
    dataIndex: 'name'
  }, {
    title: t('Active'),
    dataIndex: 'active',
    render: function render(isActive) {
      return isActive ? 'Active' : 'Inactive';
    }
  }, {
    title: t('Type'),
    dataIndex: 'type',
    render: function render(concepts) {
      return concepts.map(function (concept, index) {
        return _react["default"].createElement(_reactUtils.CodeableConcept, {
          key: index,
          concept: concept
        });
      });
    }
  }];
  var tableProps = {
    datasource: tableData,
    columns: columns,
    loading: loading,
    size: 'small'
  };
  return _react["default"].createElement(_reactUtils.TableLayout, tableProps);
};

exports.OrganizationDetailsView = OrganizationDetailsView;