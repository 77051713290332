"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createListResource = createListResource;
exports.createLocationServicePointList = createLocationServicePointList;
exports.createUpdateLocationInventoryList = createUpdateLocationInventoryList;
exports.getMember = exports.getLocationInventoryPayload = exports.getInventoryInitialValues = exports.generateIdentifier = exports.generateCharacteristics = void 0;
exports.getOrCreateList = getOrCreateList;
exports.isAttractiveProduct = exports.handleDisabledPastDates = exports.handleDisabledFutureDates = void 0;
exports.postLocationInventory = postLocationInventory;
exports.validationRulesFactory = exports.updateListReferencesFactory = exports.productAccountabilityMonths = exports.processProductOptions = exports.postPutGroup = void 0;

var _regenerator = _interopRequireDefault(require("@babel/runtime/regenerator"));

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));

var _toConsumableArray2 = _interopRequireDefault(require("@babel/runtime/helpers/toConsumableArray"));

var _reactUtils = require("@opensrp/react-utils");

var _constants = require("../../constants");

var _lodash = require("lodash");

var _uuid = require("uuid");

var _dayjs = _interopRequireDefault(require("dayjs"));

var _utils = require("../../helpers/utils");

var _fhirHelpers = require("@opensrp/fhir-helpers");

function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);

  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);
    enumerableOnly && (symbols = symbols.filter(function (sym) {
      return Object.getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }

  return keys;
}

function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      (0, _defineProperty2["default"])(target, key, source[key]);
    }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
    });
  }

  return target;
}

var handleDisabledPastDates = function handleDisabledPastDates(current) {
  if (!current) return false;
  var today = new Date();
  return current.valueOf() < today.valueOf();
};

exports.handleDisabledPastDates = handleDisabledPastDates;

var handleDisabledFutureDates = function handleDisabledFutureDates(current) {
  if (!current) return false;
  var today = new Date();
  return current.valueOf() >= today.valueOf();
};

exports.handleDisabledFutureDates = handleDisabledFutureDates;

var isAttractiveProduct = function isAttractiveProduct(product) {
  var _product$characterist;

  if (!product) {
    return false;
  }

  var attractiveCharacteristic = (0, _fhirHelpers.getCharacteristicWithCoding)((_product$characterist = product.characteristic) !== null && _product$characterist !== void 0 ? _product$characterist : [], _utils.attractiveCharacteristicCoding);
  return !!(attractiveCharacteristic !== null && attractiveCharacteristic !== void 0 && attractiveCharacteristic.valueBoolean);
};

exports.isAttractiveProduct = isAttractiveProduct;

var productAccountabilityMonths = function productAccountabilityMonths(product) {
  var _product$characterist2, _characteristic$, _characteristic$$valu;

  if (!product) {
    return undefined;
  }

  var characteristic = (_product$characterist2 = product.characteristic) === null || _product$characterist2 === void 0 ? void 0 : _product$characterist2.filter(function (_char) {
    var _char$code$coding, _char$code$coding$;

    return ((_char$code$coding = _char.code.coding) === null || _char$code$coding === void 0 ? void 0 : (_char$code$coding$ = _char$code$coding[0]) === null || _char$code$coding$ === void 0 ? void 0 : _char$code$coding$.code) === _utils.accountabilityCharacteristicCode;
  });
  return characteristic === null || characteristic === void 0 ? void 0 : (_characteristic$ = characteristic[0]) === null || _characteristic$ === void 0 ? void 0 : (_characteristic$$valu = _characteristic$.valueQuantity) === null || _characteristic$$valu === void 0 ? void 0 : _characteristic$$valu.value;
};

exports.productAccountabilityMonths = productAccountabilityMonths;

var processProductOptions = function processProductOptions(product) {
  return {
    value: product.id,
    label: product.name,
    ref: product
  };
};

exports.processProductOptions = processProductOptions;

var getMember = function getMember(productId, startDate, endDate, expiryDate) {
  var startDateToString = new Date(startDate.toDate()).toISOString();
  var endDateToString = new Date(endDate.toDate()).toISOString();
  var expiryDateToString = expiryDate ? new Date(expiryDate.toDate()).toISOString : '';
  return [{
    entity: {
      reference: "Group/".concat(productId)
    },
    period: {
      start: startDateToString,
      end: endDateToString || expiryDateToString
    },
    inactive: false
  }];
};

exports.getMember = getMember;

var generateCharacteristics = function generateCharacteristics(unicefSection, donor, quantity, listResourceObj) {
  var _listResourceObj$char;

  var knownCodes = [_fhirHelpers.sectionCharacteristicCoding.code, _fhirHelpers.donorCharacteristicCoding.code, _fhirHelpers.quantityCharacteristicCoding.code];
  var unknownCharacteristics = (listResourceObj === null || listResourceObj === void 0 ? void 0 : (_listResourceObj$char = listResourceObj.characteristic) === null || _listResourceObj$char === void 0 ? void 0 : _listResourceObj$char.filter(function (_char2) {
    var _char2$code$coding;

    var code = (_char2$code$coding = _char2.code.coding) === null || _char2$code$coding === void 0 ? void 0 : _char2$code$coding[0].code;
    return !(code && knownCodes.includes(code));
  })) || [];
  var characteristics = [].concat((0, _toConsumableArray2["default"])(unknownCharacteristics), [{
    code: {
      coding: [_fhirHelpers.sectionCharacteristicCoding]
    },
    valueCodeableConcept: {
      coding: [unicefSection],
      text: unicefSection.display
    }
  }]);

  if (donor) {
    characteristics.push({
      code: {
        coding: [_fhirHelpers.donorCharacteristicCoding]
      },
      valueCodeableConcept: {
        coding: [donor],
        text: donor.display
      }
    });
  }

  if (quantity) {
    characteristics.push({
      code: {
        coding: [_fhirHelpers.quantityCharacteristicCoding]
      },
      valueQuantity: {
        value: quantity
      }
    });
  }

  return characteristics;
};

exports.generateCharacteristics = generateCharacteristics;

var generateIdentifier = function generateIdentifier(poId, serialId, listResourceObj) {
  var _listResourceObj$iden;

  var knownCodes = [_fhirHelpers.poNumberIdentifierCoding.code, _fhirHelpers.serialNumberIdentifierCoding.code];
  var unknownIdentifiers = (listResourceObj === null || listResourceObj === void 0 ? void 0 : (_listResourceObj$iden = listResourceObj.identifier) === null || _listResourceObj$iden === void 0 ? void 0 : _listResourceObj$iden.filter(function (identifier) {
    var _identifier$type, _identifier$type$codi;

    var code = (_identifier$type = identifier.type) === null || _identifier$type === void 0 ? void 0 : (_identifier$type$codi = _identifier$type.coding) === null || _identifier$type$codi === void 0 ? void 0 : _identifier$type$codi[0].code;
    return !(code && knownCodes.includes(code));
  })) || [];
  var identifiers = [{
    use: _reactUtils.IdentifierUseCodes.SECONDARY,
    type: {
      coding: [_fhirHelpers.poNumberIdentifierCoding],
      text: _fhirHelpers.poNumberIdentifierCoding.display
    },
    value: poId
  }].concat((0, _toConsumableArray2["default"])(unknownIdentifiers));

  if (serialId) {
    identifiers.push({
      use: _reactUtils.IdentifierUseCodes.OFFICIAL,
      type: {
        coding: [_fhirHelpers.serialNumberIdentifierCoding],
        text: _fhirHelpers.serialNumberIdentifierCoding.display
      },
      value: serialId
    });
  }

  return identifiers;
};

exports.generateIdentifier = generateIdentifier;

var getLocationInventoryPayload = function getLocationInventoryPayload(values, editMode, listResourceObj) {
  var donor = values.donor ? JSON.parse(values.donor) : values.donor;
  var unicefSection = values.unicefSection ? JSON.parse(values.unicefSection) : {};
  var payload = {
    resourceType: _constants.groupResourceType,
    id: values.id || (0, _uuid.v4)(),
    active: true,
    actual: false,
    type: 'substance',
    identifier: generateIdentifier(values.poNumber, values.serialNumber, listResourceObj),
    member: getMember(values.product, values.deliveryDate, values.accountabilityEndDate),
    characteristic: generateCharacteristics(unicefSection, donor, values.quantity, listResourceObj),
    code: {
      coding: [_fhirHelpers.inventoryGroupCoding]
    }
  };

  if (editMode) {
    if (values.active) payload.active = values.active;
    if (values.actual) payload.actual = values.actual;
    if (values.type) payload.type = values.type;
    if (values.name) payload.name = values.name;
  }

  return payload;
};

exports.getLocationInventoryPayload = getLocationInventoryPayload;

var postPutGroup = function postPutGroup(baseUrl, payload) {
  var serve = new _reactUtils.FHIRServiceClass(baseUrl, _constants.groupResourceType);
  return serve.update(payload);
};

exports.postPutGroup = postPutGroup;

function getOrCreateList(_x, _x2) {
  return _getOrCreateList.apply(this, arguments);
}

function _getOrCreateList() {
  _getOrCreateList = (0, _asyncToGenerator2["default"])(_regenerator["default"].mark(function _callee2(baseUrl, listId) {
    var serve;
    return _regenerator["default"].wrap(function _callee2$(_context2) {
      while (1) {
        switch (_context2.prev = _context2.next) {
          case 0:
            serve = new _reactUtils.FHIRServiceClass(baseUrl, _constants.listResourceType);
            return _context2.abrupt("return", serve.read(listId)["catch"](function (err) {
              if (err.statusCode === 404) {
                return createListResource(baseUrl, listId);
              }

              throw err;
            }));

          case 2:
          case "end":
            return _context2.stop();
        }
      }
    }, _callee2);
  }));
  return _getOrCreateList.apply(this, arguments);
}

function createListResource(_x3, _x4, _x5, _x6) {
  return _createListResource.apply(this, arguments);
}

function _createListResource() {
  _createListResource = (0, _asyncToGenerator2["default"])(_regenerator["default"].mark(function _callee3(baseUrl, listId, entries, listResource) {
    var serve, listResourceToUse;
    return _regenerator["default"].wrap(function _callee3$(_context3) {
      while (1) {
        switch (_context3.prev = _context3.next) {
          case 0:
            serve = new _reactUtils.FHIRServiceClass(baseUrl, _constants.listResourceType);
            listResourceToUse = listResource || createLocationServicePointList(listId, entries);
            return _context3.abrupt("return", serve.update(listResourceToUse));

          case 3:
          case "end":
            return _context3.stop();
        }
      }
    }, _callee3);
  }));
  return _createListResource.apply(this, arguments);
}

function postLocationInventory(_x7, _x8, _x9, _x10, _x11) {
  return _postLocationInventory.apply(this, arguments);
}

function _postLocationInventory() {
  _postLocationInventory = (0, _asyncToGenerator2["default"])(_regenerator["default"].mark(function _callee4(baseUrl, payload, editMode, listResourceId, servicePointObj) {
    var groupResource, groupResourceId, listId, locationInventoryListBundle, locationInventoryList, combinedListResource;
    return _regenerator["default"].wrap(function _callee4$(_context4) {
      while (1) {
        switch (_context4.prev = _context4.next) {
          case 0:
            _context4.next = 2;
            return postPutGroup(baseUrl, payload);

          case 2:
            groupResource = _context4.sent;

            if (editMode) {
              _context4.next = 17;
              break;
            }

            groupResourceId = groupResource.id;
            listId = (0, _uuid.v4)();
            _context4.next = 8;
            return new _reactUtils.FHIRServiceClass(baseUrl, _constants.listResourceType).list({
              subject: servicePointObj.id,
              code: "".concat(_fhirHelpers.smartregisterSystemUri, "|").concat(_fhirHelpers.servicePointProfileInventoryListCoding.code)
            })["catch"](function (err) {
              if (err.statusCode === 404) {
                return undefined;
              }
            });

          case 8:
            locationInventoryListBundle = _context4.sent;
            locationInventoryList = locationInventoryListBundle ? (0, _reactUtils.getResourcesFromBundle)(locationInventoryListBundle)[0] : undefined;
            locationInventoryList = createUpdateLocationInventoryList(listId, groupResourceId, servicePointObj, locationInventoryList);
            _context4.next = 13;
            return createListResource(baseUrl, listId, undefined, locationInventoryList);

          case 13:
            if (!listResourceId) {
              _context4.next = 17;
              break;
            }

            combinedListResource = updateListReferencesFactory(baseUrl, listResourceId);
            _context4.next = 17;
            return combinedListResource(groupResourceId, locationInventoryList.id, editMode);

          case 17:
            return _context4.abrupt("return", groupResource);

          case 18:
          case "end":
            return _context4.stop();
        }
      }
    }, _callee4);
  }));
  return _postLocationInventory.apply(this, arguments);
}

var updateListReferencesFactory = function updateListReferencesFactory(baseUrl, globalInventoryListId) {
  return function () {
    var _ref = (0, _asyncToGenerator2["default"])(_regenerator["default"].mark(function _callee(groupResourceId, locationInventoryListId, editingGroup) {
      var _payload$entry;

      var commoditiesListResource, payload, existingEntries, serve;
      return _regenerator["default"].wrap(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              _context.next = 2;
              return getOrCreateList(baseUrl, globalInventoryListId);

            case 2:
              commoditiesListResource = _context.sent;
              payload = (0, _lodash.cloneDeep)(commoditiesListResource);
              existingEntries = (_payload$entry = payload.entry) !== null && _payload$entry !== void 0 ? _payload$entry : [];

              if (!editingGroup) {
                existingEntries.push({
                  item: {
                    reference: "".concat(_constants.groupResourceType, "/").concat(groupResourceId)
                  }
                }, {
                  item: {
                    reference: "".concat(_constants.listResourceType, "/").concat(locationInventoryListId)
                  }
                });
              }

              existingEntries = Array.from(new Map(existingEntries.map(function (entry) {
                return [entry.item.reference, entry];
              })).values());

              if (existingEntries.length) {
                payload.entry = existingEntries;
              }

              serve = new _reactUtils.FHIRServiceClass(baseUrl, _constants.listResourceType);
              return _context.abrupt("return", serve.update(payload));

            case 10:
            case "end":
              return _context.stop();
          }
        }
      }, _callee);
    }));

    return function (_x12, _x13, _x14) {
      return _ref.apply(this, arguments);
    };
  }();
};

exports.updateListReferencesFactory = updateListReferencesFactory;

function createCommonListResource(id) {
  return {
    resourceType: _constants.listResourceType,
    id: id,
    identifier: [{
      use: _reactUtils.IdentifierUseCodes.OFFICIAL,
      value: id
    }],
    status: 'current',
    code: {
      coding: [_fhirHelpers.servicePointProfileInventoryListCoding],
      text: _fhirHelpers.servicePointProfileInventoryListCoding.display
    }
  };
}

function createLocationServicePointList(id, entries) {
  var commonResources = createCommonListResource(id);
  return _objectSpread(_objectSpread({}, commonResources), {}, {
    mode: 'working',
    title: 'Supply Chain commodities',
    entry: entries || []
  });
}

function createUpdateLocationInventoryList(id, InventoryResourceId, servicePoint, currentLocationInventory) {
  var _listPayload$entry;

  var existingLocationInventory = currentLocationInventory !== null && currentLocationInventory !== void 0 ? currentLocationInventory : {};
  var commonResources = createCommonListResource(id);
  var name = servicePoint.name,
      servicePointId = servicePoint.id;
  var now = new Date();
  var stringDate = now.toISOString();
  var newEntry = {
    flag: {
      coding: [_fhirHelpers.servicePointProfileInventoryListCoding],
      text: _fhirHelpers.servicePointProfileInventoryListCoding.display
    },
    date: stringDate,
    item: {
      reference: "".concat(_constants.groupResourceType, "/").concat(InventoryResourceId)
    }
  };

  var listPayload = _objectSpread(_objectSpread({}, commonResources), {}, {
    entry: [],
    title: name,
    subject: {
      reference: "Location/".concat(servicePointId)
    }
  }, existingLocationInventory);

  (_listPayload$entry = listPayload.entry) === null || _listPayload$entry === void 0 ? void 0 : _listPayload$entry.push(newEntry);
  return listPayload;
}

var getInventoryInitialValues = function getInventoryInitialValues(inventory) {
  var _inventory$identifier, _inventory$characteri, _inventory$member;

  var initialValues = {
    id: inventory.id,
    active: inventory.active,
    type: inventory.type,
    actual: inventory.actual,
    name: inventory.name
  };
  (_inventory$identifier = inventory.identifier) === null || _inventory$identifier === void 0 ? void 0 : _inventory$identifier.forEach(function (identifier) {
    var _identifier$type2, _identifier$type2$cod;

    var code = (_identifier$type2 = identifier.type) === null || _identifier$type2 === void 0 ? void 0 : (_identifier$type2$cod = _identifier$type2.coding) === null || _identifier$type2$cod === void 0 ? void 0 : _identifier$type2$cod[0].code;

    if (code === _fhirHelpers.poNumberIdentifierCoding.code) {
      initialValues.poNumber = identifier.value;
    }

    if (code === _fhirHelpers.serialNumberIdentifierCoding.code) {
      initialValues.serialNumber = identifier.value;
    }
  });
  (_inventory$characteri = inventory.characteristic) === null || _inventory$characteri === void 0 ? void 0 : _inventory$characteri.forEach(function (characteristic) {
    var _characteristic$code$;

    var code = (_characteristic$code$ = characteristic.code.coding) === null || _characteristic$code$ === void 0 ? void 0 : _characteristic$code$[0].code;

    if (code === _fhirHelpers.sectionCharacteristicCoding.code) {
      var _characteristic$value, _characteristic$value2;

      var coding = (_characteristic$value = characteristic.valueCodeableConcept) === null || _characteristic$value === void 0 ? void 0 : (_characteristic$value2 = _characteristic$value.coding) === null || _characteristic$value2 === void 0 ? void 0 : _characteristic$value2[0];

      if (coding) {
        initialValues.unicefSection = (0, _reactUtils.getValueSetOptionsValue)(coding);
      }
    }

    if (code === _fhirHelpers.donorCharacteristicCoding.code) {
      var _characteristic$value3, _characteristic$value4;

      var _coding = (_characteristic$value3 = characteristic.valueCodeableConcept) === null || _characteristic$value3 === void 0 ? void 0 : (_characteristic$value4 = _characteristic$value3.coding) === null || _characteristic$value4 === void 0 ? void 0 : _characteristic$value4[0];

      if (_coding) {
        initialValues.donor = (0, _reactUtils.getValueSetOptionsValue)(_coding);
      }
    }

    if (code === _fhirHelpers.quantityCharacteristicCoding.code) {
      var _characteristic$value5;

      initialValues.quantity = (_characteristic$value5 = characteristic.valueQuantity) === null || _characteristic$value5 === void 0 ? void 0 : _characteristic$value5.value;
    }
  });
  var member = (_inventory$member = inventory.member) === null || _inventory$member === void 0 ? void 0 : _inventory$member[0];
  var reference = member === null || member === void 0 ? void 0 : member.entity.reference;

  var _ref2 = (member === null || member === void 0 ? void 0 : member.period) || {},
      start = _ref2.start,
      end = _ref2.end;

  if (end) {
    initialValues.accountabilityEndDate = (0, _dayjs["default"])(end);
  }

  if (start) {
    initialValues.deliveryDate = (0, _dayjs["default"])(start);
  }

  if (reference) {
    var productId = reference.split('/')[1];
    initialValues.product = productId;
  }

  return initialValues;
};

exports.getInventoryInitialValues = getInventoryInitialValues;

var validationRulesFactory = function validationRulesFactory(t, isAttractiveProduct) {
  var _rules;

  var rules = (_rules = {}, (0, _defineProperty2["default"])(_rules, _constants.product, [{
    type: 'string',
    message: t('Must be a valid string')
  }, {
    required: true,
    message: t('Product is required')
  }]), (0, _defineProperty2["default"])(_rules, _constants.unicefSection, [{
    type: 'string',
    message: t('Must be a valid string')
  }, {
    required: true,
    message: t('UNICEF section is required')
  }]), (0, _defineProperty2["default"])(_rules, _constants.deliveryDate, [{
    type: 'date',
    message: t('Must be a valid date')
  }, {
    required: true,
    message: t('Delivery date is required')
  }]), (0, _defineProperty2["default"])(_rules, _constants.accountabilityEndDate, [{
    type: 'date',
    message: t('Must be a valid date')
  }, {
    required: true,
    message: t('Accountability end date is required')
  }]), (0, _defineProperty2["default"])(_rules, _constants.serialNumber, [{
    type: 'string',
    message: t('Must be a valid string')
  }]), (0, _defineProperty2["default"])(_rules, _constants.PONumber, [{
    type: 'string',
    message: t('Must be a valid string')
  }]), _rules);

  if (isAttractiveProduct) {
    rules[_constants.serialNumber].push({
      required: true,
      message: t('Serial number is required')
    });
  }

  return rules;
};

exports.validationRulesFactory = validationRulesFactory;