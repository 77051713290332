"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.hasCode = exports.getResourceType = exports.getLocationFormFields = exports.generateLocationUnit = exports.eusmServicePointValidationRules = exports.defaultValidationRulesFactory = exports.defaultFormFields = void 0;
exports.postPutLocationUnit = postPutLocationUnit;
exports.treeToOptions = void 0;

var _regenerator = _interopRequireDefault(require("@babel/runtime/regenerator"));

var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));

var _toConsumableArray2 = _interopRequireDefault(require("@babel/runtime/helpers/toConsumableArray"));

var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _uuid = require("uuid");

var _lodash = require("lodash");

var _reactUtils = require("@opensrp/react-utils");

var _types = require("../../helpers/types");

var _constants = require("../../constants");

var _fhirHelpers = require("@opensrp/fhir-helpers");

var _excluded = ["physicalType", "type"];

function _createForOfIteratorHelper(o, allowArrayLike) {
  var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"];

  if (!it) {
    if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") {
      if (it) o = it;
      var i = 0;

      var F = function F() {};

      return {
        s: F,
        n: function n() {
          if (i >= o.length) return {
            done: true
          };
          return {
            done: false,
            value: o[i++]
          };
        },
        e: function e(_e) {
          throw _e;
        },
        f: F
      };
    }

    throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
  }

  var normalCompletion = true,
      didErr = false,
      err;
  return {
    s: function s() {
      it = it.call(o);
    },
    n: function n() {
      var step = it.next();
      normalCompletion = step.done;
      return step;
    },
    e: function e(_e2) {
      didErr = true;
      err = _e2;
    },
    f: function f() {
      try {
        if (!normalCompletion && it["return"] != null) it["return"]();
      } finally {
        if (didErr) throw err;
      }
    }
  };
}

function _unsupportedIterableToArray(o, minLen) {
  if (!o) return;
  if (typeof o === "string") return _arrayLikeToArray(o, minLen);
  var n = Object.prototype.toString.call(o).slice(8, -1);
  if (n === "Object" && o.constructor) n = o.constructor.name;
  if (n === "Map" || n === "Set") return Array.from(o);
  if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen);
}

function _arrayLikeToArray(arr, len) {
  if (len == null || len > arr.length) len = arr.length;

  for (var i = 0, arr2 = new Array(len); i < len; i++) {
    arr2[i] = arr[i];
  }

  return arr2;
}

function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);

  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);
    enumerableOnly && (symbols = symbols.filter(function (sym) {
      return Object.getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }

  return keys;
}

function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      (0, _defineProperty2["default"])(target, key, source[key]);
    }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
    });
  }

  return target;
}

var defaultFormFields = {
  id: '',
  name: '',
  parentId: undefined,
  status: _types.LocationUnitStatus.ACTIVE,
  isJurisdiction: true,
  description: undefined,
  alias: undefined
};
exports.defaultFormFields = defaultFormFields;

var getLocationFormFields = function getLocationFormFields(location, parentId) {
  if (location) {
    var _geoJsonExtension$val, _physicalType$coding, _location$partOf;

    var position = location.position,
        extension = location.extension,
        physicalType = location.physicalType,
        name = location.name,
        status = location.status,
        type = location.type,
        description = location.description,
        alias = location.alias,
        id = location.id;
    var geoJsonExtension = (0, _reactUtils.getObjLike)(extension, 'url', _constants.locationGeoJsonExtensionUrl)[0];
    var geoJsonAttachment = geoJsonExtension === null || geoJsonExtension === void 0 ? void 0 : (_geoJsonExtension$val = geoJsonExtension.valueAttachment) === null || _geoJsonExtension$val === void 0 ? void 0 : _geoJsonExtension$val.data;
    var geometryGeoJSon;

    if (geoJsonAttachment) {
      geometryGeoJSon = atob(geoJsonAttachment);
    }

    var physicalTypeCoding = (0, _reactUtils.getObjLike)((_physicalType$coding = physicalType === null || physicalType === void 0 ? void 0 : physicalType.coding) !== null && _physicalType$coding !== void 0 ? _physicalType$coding : [], 'system', _fhirHelpers.hl7PhysicalTypeCodeSystemUri)[0];
    var servicePointTypeCodings = type === null || type === void 0 ? void 0 : type.flatMap(function (concept) {
      var _concept$coding;

      return (_concept$coding = concept.coding) !== null && _concept$coding !== void 0 ? _concept$coding : [];
    });
    var serviceTypeCode = (0, _reactUtils.getObjLike)(servicePointTypeCodings, 'system', _fhirHelpers.eusmServicePointCodeSystemUri)[0];
    return _objectSpread(_objectSpread({}, defaultFormFields), {}, {
      initObj: location,
      isJurisdiction: (physicalTypeCoding === null || physicalTypeCoding === void 0 ? void 0 : physicalTypeCoding.code) !== 'bu',
      name: name,
      status: status,
      geometry: geometryGeoJSon,
      parentId: parentId !== null && parentId !== void 0 ? parentId : (_location$partOf = location.partOf) === null || _location$partOf === void 0 ? void 0 : _location$partOf.reference,
      latitude: position === null || position === void 0 ? void 0 : position.latitude,
      longitude: position === null || position === void 0 ? void 0 : position.longitude,
      serviceType: (0, _reactUtils.getValueSetOptionsValue)(serviceTypeCode),
      alias: alias,
      description: description,
      id: id
    });
  }

  return _objectSpread(_objectSpread({}, defaultFormFields), {}, {
    parentId: parentId
  });
};

exports.getLocationFormFields = getLocationFormFields;

var hasCode = function hasCode(codeList, coding) {
  var _iterator = _createForOfIteratorHelper(codeList),
      _step;

  try {
    for (_iterator.s(); !(_step = _iterator.n()).done;) {
      var code = _step.value;

      if ((0, _lodash.isEqual)(code, coding)) {
        return true;
      }
    }
  } catch (err) {
    _iterator.e(err);
  } finally {
    _iterator.f();
  }

  return false;
};

exports.hasCode = hasCode;

var getResourceType = function getResourceType(parentNode) {
  var admLevel;

  if (parentNode) {
    var _parentNode$model, _parentNode$model$nod;

    var resourceType = (_parentNode$model = parentNode.model) === null || _parentNode$model === void 0 ? void 0 : (_parentNode$model$nod = _parentNode$model.node) === null || _parentNode$model$nod === void 0 ? void 0 : _parentNode$model$nod.type;
    var level = (0, _fhirHelpers.getLocationAdmLevel)(resourceType);
    admLevel = level ? parseInt(level) + 1 : undefined;
  } else {
    admLevel = 0;
  }

  if (admLevel !== undefined) {
    var admLevelTypeCoding = (0, _fhirHelpers.getAdministrativeLevelTypeCoding)(admLevel);
    return admLevelTypeCoding;
  }
};

exports.getResourceType = getResourceType;

var generateLocationUnit = function generateLocationUnit(formValues, initialValues, parentNode) {
  var _initialValues$initOb, _initialValues$initOb2, _initialValues$initOb3;

  var id = formValues.id,
      name = formValues.name,
      status = formValues.status,
      description = formValues.description,
      alias = formValues.alias,
      isJurisdiction = formValues.isJurisdiction,
      geometry = formValues.geometry,
      latitude = formValues.latitude,
      longitude = formValues.longitude,
      serviceType = formValues.serviceType;

  var _ref = (_initialValues$initOb = initialValues.initObj) !== null && _initialValues$initOb !== void 0 ? _initialValues$initOb : {},
      physicalType = _ref.physicalType,
      type = _ref.type,
      restOfInitObj = (0, _objectWithoutProperties2["default"])(_ref, _excluded);

  var adminLevelTypeCoding = getResourceType(parentNode);
  var partOf;

  if (parentNode) {
    partOf = {
      reference: parentNode.model.nodeId,
      display: parentNode.model.node.name
    };
  }

  var initialObTypeConcepts = (type !== null && type !== void 0 ? type : []).filter(function (concept) {
    var _concept$coding2;

    var _iterator2 = _createForOfIteratorHelper((_concept$coding2 = concept.coding) !== null && _concept$coding2 !== void 0 ? _concept$coding2 : []),
        _step2;

    try {
      for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
        var coding = _step2.value;

        if ([_fhirHelpers.hl7PhysicalTypeCodeSystemUri, _fhirHelpers.eusmServicePointCodeSystemUri, _fhirHelpers.administrativeLevelSystemUri].includes(coding.system)) {
          return false;
        }
      }
    } catch (err) {
      _iterator2.e(err);
    } finally {
      _iterator2.f();
    }

    return true;
  });
  var physicalTypeCoding = {
    system: _fhirHelpers.hl7PhysicalTypeCodeSystemUri,
    code: isJurisdiction ? 'jdn' : 'bu',
    display: isJurisdiction ? 'Jurisdiction' : 'Building'
  };

  var payload = _objectSpread(_objectSpread({}, restOfInitObj), {}, {
    resourceType: 'Location',
    status: status,
    name: name,
    alias: alias,
    description: description,
    partOf: partOf,
    type: (0, _toConsumableArray2["default"])(initialObTypeConcepts)
  });

  if (isJurisdiction !== undefined) {
    var physicalTypeConcept = {
      coding: [physicalTypeCoding]
    };
    payload.type.push(physicalTypeConcept);
    payload.physicalType = physicalTypeConcept;
  }

  if (adminLevelTypeCoding) {
    payload.type.push({
      coding: [adminLevelTypeCoding]
    });
  }

  try {
    if (serviceType) {
      var coding = JSON.parse(serviceType);
      payload.type.push({
        coding: [coding]
      });
    }
  } catch (_unused) {
    void 0;
  }

  if (id) {
    payload.id = id;
  } else {
    payload.id = (0, _uuid.v4)();
  }

  var position = {};

  if (longitude) {
    position.longitude = longitude;
  }

  if (latitude) {
    position.latitude = latitude;
  }

  if (!(0, _lodash.isEmpty)(position)) {
    payload.position = position;
  }

  var initialExtensions = (_initialValues$initOb2 = (_initialValues$initOb3 = initialValues.initObj) === null || _initialValues$initOb3 === void 0 ? void 0 : _initialValues$initOb3.extension) !== null && _initialValues$initOb2 !== void 0 ? _initialValues$initOb2 : [];
  var remainingExtensions = initialExtensions.filter(function (ext) {
    return ext.url !== _constants.locationGeoJsonExtensionUrl;
  });

  if (geometry) {
    remainingExtensions.push({
      url: _constants.locationGeoJsonExtensionUrl,
      valueAttachment: {
        data: btoa(geometry)
      }
    });
  }

  if (remainingExtensions.length) {
    payload.extension = remainingExtensions;
  }

  return payload;
};

exports.generateLocationUnit = generateLocationUnit;

var rejectIfNan = function rejectIfNan(value, message) {
  if (isNaN(Number(value))) {
    return Promise.reject(message);
  } else {
    return Promise.resolve();
  }
};

var defaultValidationRulesFactory = function defaultValidationRulesFactory(t) {
  var _ref2;

  return _ref2 = {
    id: [{
      type: 'string'
    }],
    parentId: [{
      type: 'string',
      message: t("Parent ID can only contain letters, numbers and spaces")
    }, {
      required: false
    }],
    name: [{
      type: 'string',
      message: t("Name can only contain letters, numbers and spaces")
    }, {
      required: true,
      message: t("Name is required")
    }],
    alias: [{
      type: 'string',
      message: t("Name can only contain letters, numbers and spaces")
    }, {
      required: true,
      message: t("Alias is required")
    }],
    status: [{
      type: 'string'
    }, {
      required: true,
      message: t("Status is required")
    }],
    isJurisdiction: [{
      type: 'boolean'
    }, {
      required: false
    }],
    description: [{
      type: 'string'
    }, {
      required: false
    }]
  }, (0, _defineProperty2["default"])(_ref2, _constants.externalId, [{
    type: 'string',
    message: t('External ID can only contain letters, numbers and spaces')
  }]), (0, _defineProperty2["default"])(_ref2, _constants.serviceType, [{
    required: false
  }]), (0, _defineProperty2["default"])(_ref2, _constants.geometry, [{
    type: 'string'
  }]), (0, _defineProperty2["default"])(_ref2, _constants.longitude, [function () {
    return {
      validator: function validator(_, value) {
        if (!value) {
          return Promise.resolve();
        }

        return rejectIfNan(value, t('Only decimal values allowed'));
      }
    };
  }]), (0, _defineProperty2["default"])(_ref2, _constants.latitude, [function () {
    return {
      validator: function validator(_, value) {
        if (!value) {
          return Promise.resolve();
        }

        return rejectIfNan(value, t('Only decimal values allowed'));
      }
    };
  }]), _ref2;
};

exports.defaultValidationRulesFactory = defaultValidationRulesFactory;

var eusmServicePointValidationRules = function eusmServicePointValidationRules(t) {
  return _objectSpread(_objectSpread({}, defaultValidationRulesFactory(t)), {}, (0, _defineProperty2["default"])({
    id: [{
      type: 'string'
    }],
    parentId: [{
      type: 'string',
      message: t("Parent ID can only contain letters, numbers and spaces")
    }, {
      required: true,
      message: t('Please choose a parent location for this service point')
    }]
  }, _constants.serviceType, [{
    required: true,
    message: t("service type is required")
  }]));
};

exports.eusmServicePointValidationRules = eusmServicePointValidationRules;

var treeToOptions = function treeToOptions(trees, parentIdDisabledCallback) {
  var recurseCreateOptions = function recurseCreateOptions(node) {
    var optionValue = _objectSpread({
      value: node.model.nodeId,
      title: node.model.node.name
    }, parentIdDisabledCallback ? {
      disabled: parentIdDisabledCallback(node)
    } : {});

    if (node.hasChildren()) {
      optionValue.children = node.children.map(recurseCreateOptions);
    }

    return optionValue;
  };

  return trees.map(recurseCreateOptions);
};

exports.treeToOptions = treeToOptions;

function postPutLocationUnit(_x, _x2) {
  return _postPutLocationUnit.apply(this, arguments);
}

function _postPutLocationUnit() {
  _postPutLocationUnit = (0, _asyncToGenerator2["default"])(_regenerator["default"].mark(function _callee(payload, baseUrl) {
    var serve;
    return _regenerator["default"].wrap(function _callee$(_context) {
      while (1) {
        switch (_context.prev = _context.next) {
          case 0:
            serve = new _reactUtils.FHIRServiceClass(baseUrl, 'Location');
            return _context.abrupt("return", serve.update(payload)["catch"](function (err) {
              throw err;
            }));

          case 2:
          case "end":
            return _context.stop();
        }
      }
    }, _callee);
  }));
  return _postPutLocationUnit.apply(this, arguments);
}