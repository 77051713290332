"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

var _typeof = require("@babel/runtime/helpers/typeof");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CloseFlagForm = void 0;

var _regenerator = _interopRequireDefault(require("@babel/runtime/regenerator"));

var _extends2 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));

var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));

var _react = _interopRequireWildcard(require("react"));

var _antd = require("antd");

var _reactUtils = require("@opensrp/react-utils");

var _reactQuery = require("react-query");

var _notifications = require("@opensrp/notifications");

var _mls = require("../../mls");

var _constants = require("../../constants");

var _rbac = require("@opensrp/rbac");

function _getRequireWildcardCache(nodeInterop) {
  if (typeof WeakMap !== "function") return null;
  var cacheBabelInterop = new WeakMap();
  var cacheNodeInterop = new WeakMap();
  return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) {
    return nodeInterop ? cacheNodeInterop : cacheBabelInterop;
  })(nodeInterop);
}

function _interopRequireWildcard(obj, nodeInterop) {
  if (!nodeInterop && obj && obj.__esModule) {
    return obj;
  }

  if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") {
    return {
      "default": obj
    };
  }

  var cache = _getRequireWildcardCache(nodeInterop);

  if (cache && cache.has(obj)) {
    return cache.get(obj);
  }

  var newObj = {};
  var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor;

  for (var key in obj) {
    if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) {
      var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null;

      if (desc && (desc.get || desc.set)) {
        Object.defineProperty(newObj, key, desc);
      } else {
        newObj[key] = obj[key];
      }
    }
  }

  newObj["default"] = obj;

  if (cache) {
    cache.set(obj, newObj);
  }

  return newObj;
}

var FormItem = _antd.Form.Item;
var TextArea = _antd.Input.TextArea;
var defaultProps = {
  initialValues: {},
  disabled: []
};
var headerProps = {
  pageHeaderProps: {
    title: 'Close Flag',
    onBack: undefined
  }
};

var CloseFlagForm = function CloseFlagForm(props) {
  var initialValues = props.initialValues,
      flag = props.flag,
      mutationEffect = props.mutationEffect;
  var userRole = (0, _rbac.useUserRole)();

  var _useTranslation = (0, _mls.useTranslation)(),
      t = _useTranslation.t;

  var stableInitialValues = (0, _react.useMemo)(function () {
    return initialValues;
  }, [initialValues]);

  var _useMutation = (0, _reactQuery.useMutation)(function (values) {
    return mutationEffect(stableInitialValues, values, flag);
  }, {
    onError: function onError() {
      (0, _notifications.sendErrorNotification)(t('Error Occurred When Closing Flag'));
    },
    onSuccess: function () {
      var _onSuccess = (0, _asyncToGenerator2["default"])(_regenerator["default"].mark(function _callee() {
        return _regenerator["default"].wrap(function _callee$(_context) {
          while (1) {
            switch (_context.prev = _context.next) {
              case 0:
                (0, _notifications.sendSuccessNotification)(t('Flag Closed successfully'));

              case 1:
              case "end":
                return _context.stop();
            }
          }
        }, _callee);
      }));

      function onSuccess() {
        return _onSuccess.apply(this, arguments);
      }

      return onSuccess;
    }()
  }),
      mutate = _useMutation.mutate,
      isLoading = _useMutation.isLoading;

  var statusOptions = [{
    label: t('Active'),
    value: 'active'
  }, {
    label: t('Inactive'),
    value: 'inactive'
  }];

  var handleFinish = function handleFinish(values) {
    mutate(values);
  };

  var saveBtnDisabled = userRole.hasPermissions(['Flag.update', 'Encounter.update', 'Observation.update']);
  var saveBtnDisabledReason = saveBtnDisabled ? t('Missing Required permissions to perform this action') : undefined;
  return _react["default"].createElement(_reactUtils.BodyLayout, {
    headerProps: headerProps
  }, _react["default"].createElement(_antd.Row, {
    className: "user-group"
  }, _react["default"].createElement(_antd.Col, {
    className: "bg-white p-3",
    span: 24
  }, _react["default"].createElement(_antd.Form, (0, _extends2["default"])({}, _reactUtils.formItemLayout, {
    initialValues: stableInitialValues,
    onFinish: handleFinish
  }), _react["default"].createElement(FormItem, {
    name: _constants.locationName,
    id: _constants.locationName,
    label: t('Service Point'),
    rules: [{
      required: true
    }]
  }, _react["default"].createElement(_antd.Input, {
    placeholder: t('(Auto generated)'),
    disabled: true
  })), _react["default"].createElement(FormItem, {
    name: _constants.productName,
    id: _constants.productName,
    label: t('Product')
  }, _react["default"].createElement(_antd.Input, {
    placeholder: t('(Auto generated)'),
    disabled: true
  })), _react["default"].createElement(FormItem, {
    id: _constants.status,
    label: t('Status'),
    name: _constants.status,
    rules: [{
      required: true
    }]
  }, _react["default"].createElement(_antd.Select, {
    options: statusOptions,
    placeholder: t('Select flag status')
  })), _react["default"].createElement(FormItem, {
    id: _constants.comments,
    label: "Comments",
    name: _constants.comments,
    rules: [{
      required: true
    }]
  }, _react["default"].createElement(TextArea, {
    rows: 4,
    placeholder: t('How was the flag resolved?')
  })), _react["default"].createElement(FormItem, (0, _extends2["default"])({}, _reactUtils.tailLayout, {
    help: saveBtnDisabledReason
  }), _react["default"].createElement(_antd.Button, {
    type: "primary",
    id: "submit-button",
    htmlType: "submit",
    disabled: saveBtnDisabled
  }, isLoading ? t('Saving') : t('Save')))))));
};

exports.CloseFlagForm = CloseFlagForm;
CloseFlagForm.defaultProps = defaultProps;