"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.GenericListTabsView = GenericListTabsView;
exports.GenericTabsView = GenericTabsView;

var _extends2 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));

var _antd = require("antd");

var _react = _interopRequireDefault(require("react"));

var _useSearchParams2 = require("../../../hooks/useSearchParams");

var _constants = require("../../../constants");

var _excluded = ["tabViewId", "sideViewQueryName"];

function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);

  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);
    enumerableOnly && (symbols = symbols.filter(function (sym) {
      return Object.getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }

  return keys;
}

function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      (0, _defineProperty2["default"])(target, key, source[key]);
    }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
    });
  }

  return target;
}

function GenericTabsView(props) {
  var _sParams$get, _sParams$get2;

  var tabViewId = props.tabViewId,
      sideViewQueryName = props.sideViewQueryName,
      restprops = (0, _objectWithoutProperties2["default"])(props, _excluded);

  var _useSearchParams = (0, _useSearchParams2.useSearchParams)(),
      sParams = _useSearchParams.sParams,
      addParams = _useSearchParams.addParams,
      removeParam = _useSearchParams.removeParam;

  var activeTabKey = (_sParams$get = sParams.get(_constants.activeTabQuery)) !== null && _sParams$get !== void 0 ? _sParams$get : undefined;
  var tabView = (_sParams$get2 = sParams.get(_constants.tabViewQuery)) !== null && _sParams$get2 !== void 0 ? _sParams$get2 : undefined;

  var onTabChangeHandler = function onTabChangeHandler(key) {
    var _addParams;

    if (sideViewQueryName) {
      var _sParams$get3;

      var tableRowId = (_sParams$get3 = sParams.get(sideViewQueryName)) !== null && _sParams$get3 !== void 0 ? _sParams$get3 : undefined;

      if (tableRowId) {
        removeParam(sideViewQueryName);
      }
    }

    addParams((_addParams = {}, (0, _defineProperty2["default"])(_addParams, _constants.tabViewQuery, tabViewId), (0, _defineProperty2["default"])(_addParams, _constants.activeTabQuery, key), _addParams));
  };

  var extraTabProps = {};

  if (activeTabKey && tabViewId === tabView) {
    extraTabProps['activeKey'] = activeTabKey;
  }

  var tabProps = _objectSpread(_objectSpread({
    onChange: onTabChangeHandler
  }, restprops), extraTabProps);

  return _react["default"].createElement("div", {
    className: "details-tab"
  }, _react["default"].createElement(_antd.Tabs, tabProps));
}

function GenericListTabsView(props) {
  var tabsData = props.tabsData;
  return _react["default"].createElement(_react["default"].Fragment, null, tabsData.map(function (tabProp) {
    return _react["default"].createElement(GenericTabsView, (0, _extends2["default"])({
      key: tabProp.tabViewId
    }, tabProp));
  }));
}