"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.columns = void 0;
exports.conditionDetailsProps = conditionDetailsProps;
exports.parseConditionList = exports.parseCondition = exports.conditionSideViewData = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _react = _interopRequireDefault(require("react"));

var _lodash = require("lodash");

var _utils = require("../../../helpers/utils");

var _reactUtils = require("@opensrp/react-utils");

var parseCondition = function parseCondition(obj) {
  return {
    condition: (0, _utils.getCodeableConcepts)((0, _lodash.get)(obj, 'code')),
    severity: (0, _utils.getCodeableConcepts)((0, _lodash.get)(obj, 'severity')),
    verificationStatus: (0, _utils.getCodeableConcepts)((0, _lodash.get)(obj, 'verificationStatus')),
    category: (0, _utils.getCodeableConcepts)((0, _lodash.get)(obj, 'category')),
    clinicalStatus: (0, _utils.getCodeableConcepts)((0, _lodash.get)(obj, 'clinicalStatus')),
    recordedDate: (0, _lodash.get)(obj, 'recordedDate'),
    onsetDateTime: (0, _lodash.get)(obj, 'onsetDateTime'),
    abatementDateTime: (0, _lodash.get)(obj, 'abatementDateTime'),
    stage: (0, _lodash.get)(obj, 'stage'),
    id: (0, _lodash.get)(obj, 'id')
  };
};

exports.parseCondition = parseCondition;

var parseConditionList = function parseConditionList(list) {
  return list.map(parseCondition);
};

exports.parseConditionList = parseConditionList;

var columns = function columns(t) {
  return [{
    title: t('Condition'),
    dataIndex: 'condition',
    render: function render(value) {
      return _react["default"].createElement(_utils.FhirCodesTooltips, {
        codings: value
      });
    }
  }, {
    title: t('Severity'),
    dataIndex: 'severity',
    render: function render(value) {
      return _react["default"].createElement(_utils.FhirCodesTooltips, {
        codings: value
      });
    }
  }, {
    title: t('Verification Status'),
    dataIndex: 'verificationStatus',
    render: function render(value) {
      return _react["default"].createElement(_utils.FhirCodesTooltips, {
        codings: value
      });
    }
  }];
};

exports.columns = columns;

var getStageValue = function getStageValue(stage) {
  var _stage$;

  if (!stage) return '';
  var summary = (_stage$ = stage[0]) === null || _stage$ === void 0 ? void 0 : _stage$.summary;
  var summaryCoadable = (0, _utils.getCodeableConcepts)(summary);

  if (summaryCoadable.length > 0) {
    return _react["default"].createElement(_utils.FhirCodesTooltips, {
      codings: summaryCoadable
    });
  }

  return summary === null || summary === void 0 ? void 0 : summary.text;
};

var getStatusTitle = function getStatusTitle(verificationStatus) {
  var _verificationStatus$, _verificationStatus$2, _verificationStatus$3;

  return (_verificationStatus$ = (_verificationStatus$2 = verificationStatus[0]) === null || _verificationStatus$2 === void 0 ? void 0 : _verificationStatus$2.display) !== null && _verificationStatus$ !== void 0 ? _verificationStatus$ : (_verificationStatus$3 = verificationStatus[0]) === null || _verificationStatus$3 === void 0 ? void 0 : _verificationStatus$3.code;
};

var conditionSideViewData = function conditionSideViewData(resource, t) {
  var _bodyData;

  var _parseCondition = parseCondition(resource),
      id = _parseCondition.id,
      condition = _parseCondition.condition,
      verificationStatus = _parseCondition.verificationStatus,
      category = _parseCondition.category,
      stage = _parseCondition.stage,
      clinicalStatus = _parseCondition.clinicalStatus,
      onsetDateTime = _parseCondition.onsetDateTime;

  var headerLeftData = (0, _defineProperty2["default"])({}, t('ID'), id);
  var bodyData = (_bodyData = {}, (0, _defineProperty2["default"])(_bodyData, t('Category'), _react["default"].createElement(_utils.FhirCodesTooltips, {
    codings: category
  })), (0, _defineProperty2["default"])(_bodyData, t('stage'), getStageValue(stage)), (0, _defineProperty2["default"])(_bodyData, t('Onset date'), (0, _reactUtils.dateToLocaleString)(onsetDateTime)), (0, _defineProperty2["default"])(_bodyData, t('Clinical status'), _react["default"].createElement(_utils.FhirCodesTooltips, {
    codings: clinicalStatus
  })), _bodyData);
  return {
    title: _react["default"].createElement(_utils.FhirCodesTooltips, {
      codings: condition
    }),
    headerLeftData: headerLeftData,
    bodyData: bodyData,
    status: {
      title: getStatusTitle(verificationStatus),
      color: 'green'
    }
  };
};

exports.conditionSideViewData = conditionSideViewData;

function conditionDetailsProps(resource, t) {
  var _bodyData2;

  var _parseCondition2 = parseCondition(resource),
      id = _parseCondition2.id,
      condition = _parseCondition2.condition,
      severity = _parseCondition2.severity,
      recordedDate = _parseCondition2.recordedDate,
      verificationStatus = _parseCondition2.verificationStatus,
      category = _parseCondition2.category,
      stage = _parseCondition2.stage,
      clinicalStatus = _parseCondition2.clinicalStatus,
      onsetDateTime = _parseCondition2.onsetDateTime,
      abatementDateTime = _parseCondition2.abatementDateTime;

  var headerRightData = (0, _defineProperty2["default"])({}, t('Date created'), (0, _reactUtils.dateToLocaleString)(recordedDate));
  var bodyData = (_bodyData2 = {}, (0, _defineProperty2["default"])(_bodyData2, t('Condition'), _react["default"].createElement(_utils.FhirCodesTooltips, {
    codings: condition
  })), (0, _defineProperty2["default"])(_bodyData2, t('Severity'), _react["default"].createElement(_utils.FhirCodesTooltips, {
    codings: severity
  })), (0, _defineProperty2["default"])(_bodyData2, t('Category'), _react["default"].createElement(_utils.FhirCodesTooltips, {
    codings: category
  })), (0, _defineProperty2["default"])(_bodyData2, t('stage'), getStageValue(stage)), (0, _defineProperty2["default"])(_bodyData2, t('Onset date'), (0, _reactUtils.dateToLocaleString)(onsetDateTime)), (0, _defineProperty2["default"])(_bodyData2, t('Abatement date'), (0, _reactUtils.dateToLocaleString)(abatementDateTime)), (0, _defineProperty2["default"])(_bodyData2, t('Clinical status'), _react["default"].createElement(_utils.FhirCodesTooltips, {
    codings: clinicalStatus
  })), (0, _defineProperty2["default"])(_bodyData2, t('Verification status'), getStatusTitle(verificationStatus)), _bodyData2);
  return {
    title: _react["default"].createElement(_utils.FhirCodesTooltips, {
      codings: condition
    }),
    headerRightData: headerRightData,
    headerLeftData: (0, _defineProperty2["default"])({}, t('Id'), id),
    bodyData: bodyData,
    status: {
      title: getStatusTitle(verificationStatus),
      color: 'green'
    }
  };
}