"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.compressImage = compressImage;
exports.createSupplyManagementList = createSupplyManagementList;
exports.getGroupFormFields = exports.generateGroupPayload = exports.defaultCode = exports.defaultCharacteristic = void 0;
exports.getOrCreateList = getOrCreateList;
exports.getProductImagePayload = getProductImagePayload;
exports.validationRulesFactory = exports.updateListReferencesFactory = exports.postPutGroup = exports.postPutBinary = void 0;

var _regenerator = _interopRequireDefault(require("@babel/runtime/regenerator"));

var _toConsumableArray2 = _interopRequireDefault(require("@babel/runtime/helpers/toConsumableArray"));

var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));

var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _uuid = require("uuid");

var _reactUtils = require("@opensrp/react-utils");

var _lodash = require("lodash");

var _constants = require("../../../constants");

var _utils = require("../../../helpers/utils");

var _fhirHelpers = require("@opensrp/fhir-helpers");

var _utils2 = require("../../ProductForm/utils");

var _browserImageCompression = _interopRequireDefault(require("browser-image-compression"));

var _excluded = ["meta"];

function _createForOfIteratorHelper(o, allowArrayLike) {
  var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"];

  if (!it) {
    if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") {
      if (it) o = it;
      var i = 0;

      var F = function F() {};

      return {
        s: F,
        n: function n() {
          if (i >= o.length) return {
            done: true
          };
          return {
            done: false,
            value: o[i++]
          };
        },
        e: function e(_e) {
          throw _e;
        },
        f: F
      };
    }

    throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
  }

  var normalCompletion = true,
      didErr = false,
      err;
  return {
    s: function s() {
      it = it.call(o);
    },
    n: function n() {
      var step = it.next();
      normalCompletion = step.done;
      return step;
    },
    e: function e(_e2) {
      didErr = true;
      err = _e2;
    },
    f: function f() {
      try {
        if (!normalCompletion && it["return"] != null) it["return"]();
      } finally {
        if (didErr) throw err;
      }
    }
  };
}

function _unsupportedIterableToArray(o, minLen) {
  if (!o) return;
  if (typeof o === "string") return _arrayLikeToArray(o, minLen);
  var n = Object.prototype.toString.call(o).slice(8, -1);
  if (n === "Object" && o.constructor) n = o.constructor.name;
  if (n === "Map" || n === "Set") return Array.from(o);
  if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen);
}

function _arrayLikeToArray(arr, len) {
  if (len == null || len > arr.length) len = arr.length;

  for (var i = 0, arr2 = new Array(len); i < len; i++) {
    arr2[i] = arr[i];
  }

  return arr2;
}

function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);

  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);
    enumerableOnly && (symbols = symbols.filter(function (sym) {
      return Object.getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }

  return keys;
}

function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      (0, _defineProperty2["default"])(target, key, source[key]);
    }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
    });
  }

  return target;
}

var defaultCharacteristic = {
  code: {
    coding: [{
      system: _utils.snomedCodeSystem,
      code: _utils.characteristicUnitMeasureCode,
      display: 'Unit of measure'
    }]
  },
  valueCodeableConcept: {
    coding: [{
      system: _utils.snomedCodeSystem,
      code: '767525000',
      display: 'Unit'
    }],
    text: undefined
  }
};
exports.defaultCharacteristic = defaultCharacteristic;
var defaultCode = {
  coding: [{
    system: _utils.snomedCodeSystem,
    code: _utils.supplyMgSnomedCode,
    display: 'Supply management'
  }]
};
exports.defaultCode = defaultCode;

var validationRulesFactory = function validationRulesFactory(t) {
  var _objectSpread2;

  return _objectSpread(_objectSpread({}, (0, _utils2.defaultValidationRulesFactory)(t)), {}, (_objectSpread2 = {}, (0, _defineProperty2["default"])(_objectSpread2, _constants.materialNumber, [{
    type: 'string',
    message: t('Must be a valid string')
  }, {
    required: true,
    message: t('Required')
  }]), (0, _defineProperty2["default"])(_objectSpread2, _constants.name, [{
    type: 'string',
    message: t('Must be a valid string')
  }, {
    required: true,
    message: t('Required')
  }]), (0, _defineProperty2["default"])(_objectSpread2, _constants.active, [{
    type: 'boolean'
  }, {
    required: true,
    message: t('Required')
  }]), (0, _defineProperty2["default"])(_objectSpread2, _constants.isAttractiveItem, [{
    required: true,
    message: t('Required')
  }, {
    type: 'boolean'
  }]), (0, _defineProperty2["default"])(_objectSpread2, _constants.availability, [{
    type: 'string'
  }, {
    required: true,
    message: t('Required')
  }]), (0, _defineProperty2["default"])(_objectSpread2, _constants.accountabilityPeriod, [{
    required: true,
    message: t('Required')
  }, {
    type: 'number'
  }]), _objectSpread2));
};

exports.validationRulesFactory = validationRulesFactory;

function getValueFromCharacteristic(characteristic) {
  if (Object.prototype.hasOwnProperty.call(characteristic, 'valueCodeableConcept')) {
    var _characteristic$value;

    return (_characteristic$value = characteristic.valueCodeableConcept) === null || _characteristic$value === void 0 ? void 0 : _characteristic$value.text;
  }

  if (Object.prototype.hasOwnProperty.call(characteristic, 'valueBoolean')) {
    return characteristic.valueBoolean;
  }

  if (Object.prototype.hasOwnProperty.call(characteristic, 'valueQuantity')) {
    var _characteristic$value2;

    return (_characteristic$value2 = characteristic.valueQuantity) === null || _characteristic$value2 === void 0 ? void 0 : _characteristic$value2.value;
  }

  if (Object.prototype.hasOwnProperty.call(characteristic, 'valueReference')) {
    var _characteristic$value3;

    return (_characteristic$value3 = characteristic.valueReference) === null || _characteristic$value3 === void 0 ? void 0 : _characteristic$value3.reference;
  }
}

function generateCharacteristicPayload(existingCharacteristic, values) {
  var knownCodes = [_utils.accountabilityCharacteristicCoding, _utils.appropriateUsageCharacteristicCoding, _utils.conditionCharacteristicCoding, _utils.availabilityCharacteristicCoding, _utils.attractiveCharacteristicCoding, _utils.unitOfMeasureCharacteristicCoding];
  var newCharacteristics = (0, _lodash.cloneDeep)(existingCharacteristic);

  for (var _i = 0, _knownCodes = knownCodes; _i < _knownCodes.length; _i++) {
    var coding = _knownCodes[_i];
    updateCharacteristicForCode(newCharacteristics, coding, values);
  }

  return newCharacteristics;
}

function updateCharacteristicForCode(existingCharacteristics, codingCode, values) {
  var checkCharacteristic = (0, _utils.getCharacteristicWithCoding)(existingCharacteristics, codingCode);

  switch (codingCode.code) {
    case _utils.characteristicUnitMeasureCode:
      {
        var _unitOfMeasure = values.unitOfMeasure;

        if (_unitOfMeasure === undefined) {
          return;
        }

        if (checkCharacteristic) {
          var _checkCharacteristic$;

          ((_checkCharacteristic$ = checkCharacteristic.valueCodeableConcept) !== null && _checkCharacteristic$ !== void 0 ? _checkCharacteristic$ : {}).text = _unitOfMeasure;
        } else existingCharacteristics.push(_objectSpread(_objectSpread({}, _utils.unitOfMeasureCharacteristic), {}, {
          valueCodeableConcept: _objectSpread(_objectSpread({}, _utils.unitOfMeasureCharacteristic.valueCodeableConcept), {}, {
            text: _unitOfMeasure
          })
        }));

        break;
      }

    case _utils.accountabilityCharacteristicCode:
      {
        var _accountabilityPeriod = values.accountabilityPeriod;

        if (_accountabilityPeriod === undefined) {
          return;
        }

        if (checkCharacteristic) {
          var _checkCharacteristic$2;

          ((_checkCharacteristic$2 = checkCharacteristic.valueQuantity) !== null && _checkCharacteristic$2 !== void 0 ? _checkCharacteristic$2 : {}).value = _accountabilityPeriod;
        } else {
          existingCharacteristics.push(_objectSpread(_objectSpread({}, _utils.accountabilityCharacteristic), {}, {
            valueQuantity: {
              value: _accountabilityPeriod
            }
          }));
        }

        break;
      }

    case _utils.appropriateUsageCharacteristicCode:
      {
        var _appropriateUsage = values.appropriateUsage;

        if (_appropriateUsage === undefined) {
          return;
        }

        if (checkCharacteristic) {
          var _checkCharacteristic$3;

          ((_checkCharacteristic$3 = checkCharacteristic.valueCodeableConcept) !== null && _checkCharacteristic$3 !== void 0 ? _checkCharacteristic$3 : {}).text = _appropriateUsage;
        } else existingCharacteristics.push(_objectSpread(_objectSpread({}, _utils.appropriateUsageCharacteristic), {}, {
          valueCodeableConcept: _objectSpread(_objectSpread({}, _utils.appropriateUsageCharacteristic.valueCodeableConcept), {}, {
            text: _appropriateUsage
          })
        }));

        break;
      }

    case _utils.conditionCharacteristicCode:
      {
        var _condition = values.condition;

        if (_condition === undefined) {
          return;
        }

        if (checkCharacteristic) {
          var _checkCharacteristic$4;

          ((_checkCharacteristic$4 = checkCharacteristic.valueCodeableConcept) !== null && _checkCharacteristic$4 !== void 0 ? _checkCharacteristic$4 : {}).text = _condition;
        } else existingCharacteristics.push(_objectSpread(_objectSpread({}, _utils.conditionCharacteristic), {}, {
          valueCodeableConcept: _objectSpread(_objectSpread({}, _utils.conditionCharacteristic.valueCodeableConcept), {}, {
            text: _condition
          })
        }));

        break;
      }

    case _utils.availabilityCharacteristicCode:
      {
        var _availability = values.availability;

        if (_availability === undefined) {
          return;
        }

        if (checkCharacteristic) {
          var _checkCharacteristic$5;

          ((_checkCharacteristic$5 = checkCharacteristic.valueCodeableConcept) !== null && _checkCharacteristic$5 !== void 0 ? _checkCharacteristic$5 : {}).text = _availability;
        } else existingCharacteristics.push(_objectSpread(_objectSpread({}, _utils.availabilityCharacteristic), {}, {
          valueCodeableConcept: _objectSpread(_objectSpread({}, _utils.availabilityCharacteristic.valueCodeableConcept), {}, {
            text: _availability
          })
        }));

        break;
      }

    case _utils.attractiveCharacteristicCode:
      {
        var _isAttractiveItem = values.isAttractiveItem;

        if (_isAttractiveItem === undefined) {
          return;
        }

        if (checkCharacteristic) {
          checkCharacteristic.valueBoolean = _isAttractiveItem;
        } else existingCharacteristics.push(_objectSpread(_objectSpread({}, _utils.attractiveCharacteristic), {}, {
          valueBoolean: _isAttractiveItem
        }));

        break;
      }
  }
}

function fileToBase64(file) {
  if (!file) {
    return new Promise(function (r) {
      return r(undefined);
    });
  }

  return new Promise(function (resolve, reject) {
    var reader = new FileReader();

    reader.onload = function () {
      var base64String = reader.result.split(',')[1];
      resolve(base64String);
    };

    reader.onerror = function (error) {
      reject(error);
    };

    reader.readAsDataURL(file);
  });
}

function compressImage(_x) {
  return _compressImage.apply(this, arguments);
}

function _compressImage() {
  _compressImage = (0, _asyncToGenerator2["default"])(_regenerator["default"].mark(function _callee3(file) {
    var options, compressedBlob;
    return _regenerator["default"].wrap(function _callee3$(_context3) {
      while (1) {
        switch (_context3.prev = _context3.next) {
          case 0:
            if (file) {
              _context3.next = 2;
              break;
            }

            return _context3.abrupt("return");

          case 2:
            options = {
              maxSizeMB: 1,
              maxWidthOrHeight: 1920,
              fileType: 'image/webp'
            };
            _context3.next = 5;
            return (0, _browserImageCompression["default"])(file, options);

          case 5:
            compressedBlob = _context3.sent;
            return _context3.abrupt("return", compressedBlob);

          case 7:
          case "end":
            return _context3.stop();
        }
      }
    }, _callee3);
  }));
  return _compressImage.apply(this, arguments);
}

function getProductImagePayload(_x2, _x3) {
  return _getProductImagePayload.apply(this, arguments);
}

function _getProductImagePayload() {
  _getProductImagePayload = (0, _asyncToGenerator2["default"])(_regenerator["default"].mark(function _callee4(values, initialValues) {
    var _initialValues$produc, _initialValues$produc2, _values$productImage, _values$productImage$;

    var initialImage, currentImage, currentImageb64, initialImageb64, scaledDownImage, scaledDownCurrentImageb64, _initialValues$produc3, _initialValues$produc4, _initialValues$produc5, id, payload, _initialValues$produc6, _initialValues$produc7, _initialValues$produc8, _id, _payload;

    return _regenerator["default"].wrap(function _callee4$(_context4) {
      while (1) {
        switch (_context4.prev = _context4.next) {
          case 0:
            initialImage = (_initialValues$produc = initialValues.productImage) === null || _initialValues$produc === void 0 ? void 0 : (_initialValues$produc2 = _initialValues$produc[0]) === null || _initialValues$produc2 === void 0 ? void 0 : _initialValues$produc2.originFileObj;
            currentImage = (_values$productImage = values.productImage) === null || _values$productImage === void 0 ? void 0 : (_values$productImage$ = _values$productImage[0]) === null || _values$productImage$ === void 0 ? void 0 : _values$productImage$.originFileObj;
            _context4.next = 4;
            return fileToBase64(currentImage);

          case 4:
            currentImageb64 = _context4.sent;
            _context4.next = 7;
            return fileToBase64(initialImage);

          case 7:
            initialImageb64 = _context4.sent;
            _context4.next = 10;
            return compressImage(currentImage);

          case 10:
            scaledDownImage = _context4.sent;
            _context4.next = 13;
            return fileToBase64(scaledDownImage);

          case 13:
            scaledDownCurrentImageb64 = _context4.sent;

            if (!(currentImageb64 === initialImageb64)) {
              _context4.next = 16;
              break;
            }

            return _context4.abrupt("return", {
              changed: false
            });

          case 16:
            if (!(currentImage === undefined)) {
              _context4.next = 22;
              break;
            }

            id = (_initialValues$produc3 = (_initialValues$produc4 = initialValues.productImage) === null || _initialValues$produc4 === void 0 ? void 0 : (_initialValues$produc5 = _initialValues$produc4[0]) === null || _initialValues$produc5 === void 0 ? void 0 : _initialValues$produc5.uid) !== null && _initialValues$produc3 !== void 0 ? _initialValues$produc3 : (0, _uuid.v4)();
            payload = {
              id: id,
              resourceType: _constants.binaryResourceType
            };
            return _context4.abrupt("return", {
              changed: true,
              payload: payload
            });

          case 22:
            _id = (_initialValues$produc6 = (_initialValues$produc7 = initialValues.productImage) === null || _initialValues$produc7 === void 0 ? void 0 : (_initialValues$produc8 = _initialValues$produc7[0]) === null || _initialValues$produc8 === void 0 ? void 0 : _initialValues$produc8.uid) !== null && _initialValues$produc6 !== void 0 ? _initialValues$produc6 : (0, _uuid.v4)();
            _payload = {
              id: _id,
              resourceType: _constants.binaryResourceType,
              contentType: scaledDownImage === null || scaledDownImage === void 0 ? void 0 : scaledDownImage.type,
              data: scaledDownCurrentImageb64
            };
            return _context4.abrupt("return", {
              changed: true,
              payload: _payload
            });

          case 25:
          case "end":
            return _context4.stop();
        }
      }
    }, _callee4);
  }));
  return _getProductImagePayload.apply(this, arguments);
}

var getGroupFormFields = function getGroupFormFields(obj, binary) {
  var _obj$characteristic;

  if (!obj) {
    var _ref;

    return _ref = {
      initialObject: {
        group: {
          code: defaultCode
        }
      },
      active: true
    }, (0, _defineProperty2["default"])(_ref, _constants.isAttractiveItem, true), (0, _defineProperty2["default"])(_ref, "type", _fhirHelpers.R4GroupTypeCodes.SUBSTANCE), _ref;
  }

  var id = obj.id,
      name = obj.name,
      active = obj.active,
      identifier = obj.identifier,
      type = obj.type;
  var identifierObj = (0, _reactUtils.getObjLike)(identifier, 'use', _reactUtils.IdentifierUseCodes.OFFICIAL);
  var formFieldsFromCharacteristics = {};

  var _iterator = _createForOfIteratorHelper((_obj$characteristic = obj.characteristic) !== null && _obj$characteristic !== void 0 ? _obj$characteristic : []),
      _step;

  try {
    for (_iterator.s(); !(_step = _iterator.n()).done;) {
      var _characteristic$code$;

      var characteristic = _step.value;
      var characteristicCoding = (_characteristic$code$ = characteristic.code.coding) !== null && _characteristic$code$ !== void 0 ? _characteristic$code$ : [];

      var _iterator2 = _createForOfIteratorHelper(characteristicCoding),
          _step2;

      try {
        for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
          var _coding$system;

          var coding = _step2.value;
          var codingSystem = (_coding$system = coding.system) === null || _coding$system === void 0 ? void 0 : _coding$system.toLowerCase();
          var codingCode = coding.code;

          if (codingSystem === _utils.snomedCodeSystem) {
            if (codingCode === _utils.characteristicUnitMeasureCode) {
              var val = getValueFromCharacteristic(characteristic);
              formFieldsFromCharacteristics[_constants.unitOfMeasure] = val;
            }
          }

          if (codingSystem === _utils.smartRegisterCodeSystem) {
            if (codingCode === _utils.accountabilityCharacteristicCode) {
              var _val = getValueFromCharacteristic(characteristic);

              formFieldsFromCharacteristics[_constants.accountabilityPeriod] = _val;
            }

            if (codingCode === _utils.appropriateUsageCharacteristicCode) {
              var _val2 = getValueFromCharacteristic(characteristic);

              formFieldsFromCharacteristics[_constants.appropriateUsage] = _val2;
            }

            if (codingCode === _utils.conditionCharacteristicCode) {
              var _val3 = getValueFromCharacteristic(characteristic);

              formFieldsFromCharacteristics[_constants.condition] = _val3;
            }

            if (codingCode === _utils.availabilityCharacteristicCode) {
              var _val4 = getValueFromCharacteristic(characteristic);

              formFieldsFromCharacteristics[_constants.availability] = _val4;
            }

            if (codingCode === _utils.attractiveCharacteristicCode) {
              var _val5 = getValueFromCharacteristic(characteristic);

              formFieldsFromCharacteristics[_constants.isAttractiveItem] = _val5;
            }
          }
        }
      } catch (err) {
        _iterator2.e(err);
      } finally {
        _iterator2.f();
      }
    }
  } catch (err) {
    _iterator.e(err);
  } finally {
    _iterator.f();
  }

  var productImageFromUrl;

  if (binary !== null && binary !== void 0 && binary.data && binary.contentType) {
    productImageFromUrl = base64ToFile(binary.data, binary.contentType);
    productImageFromUrl = [{
      uid: binary.id,
      originFileObj: productImageFromUrl
    }];
  }

  var formFields = _objectSpread(_objectSpread({
    initialObject: {
      group: obj,
      binary: binary
    },
    id: id,
    identifier: (0, _lodash.get)(identifierObj, '0.value'),
    materialNumber: (0, _lodash.get)(identifierObj, '0.value'),
    active: active,
    name: name,
    type: type !== null && type !== void 0 ? type : _fhirHelpers.R4GroupTypeCodes.SUBSTANCE
  }, formFieldsFromCharacteristics), {}, {
    productImage: productImageFromUrl
  });

  return formFields;
};

exports.getGroupFormFields = getGroupFormFields;

function base64ToFile(base64String, mimeType) {
  var byteCharacters = atob(base64String);
  var byteNumbers = new Array(byteCharacters.length);

  for (var i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }

  var byteArray = new Uint8Array(byteNumbers);
  var blob = new Blob([byteArray], {
    type: mimeType
  });
  return new File([blob], '', {
    type: mimeType
  });
}

var generateGroupPayload = function () {
  var _ref2 = (0, _asyncToGenerator2["default"])(_regenerator["default"].mark(function _callee(values, initialValues) {
    var _initialValues$initia, _initialGroupObject$i, _initialGroupObject$c;

    var id, materialNumber, active, name, type, initialObject, initialGroupObject, payload, _ref3, meta, rest, existingIdentifiers, newIdentifiers, existingCharacteristics, newCharacteristics, _yield$getProductImag, changed, binaryPayload, binaryResourceUrl, productImageCharacteristic;

    return _regenerator["default"].wrap(function _callee$(_context) {
      while (1) {
        switch (_context.prev = _context.next) {
          case 0:
            id = values.id, materialNumber = values.materialNumber, active = values.active, name = values.name, type = values.type;
            initialObject = initialValues.initialObject;
            initialGroupObject = (_initialValues$initia = initialValues.initialObject) === null || _initialValues$initia === void 0 ? void 0 : _initialValues$initia.group;
            payload = {
              resourceType: _constants.groupResourceType,
              active: !!active
            };

            if (initialObject) {
              _ref3 = initialGroupObject !== null && initialGroupObject !== void 0 ? initialGroupObject : {}, meta = _ref3.meta, rest = (0, _objectWithoutProperties2["default"])(_ref3, _excluded);
              payload = _objectSpread(_objectSpread({}, rest), payload);
            }

            if (name) {
              payload.name = name;
            }

            if (id) {
              payload.id = id;
            } else {
              payload.id = (0, _uuid.v4)();
            }

            existingIdentifiers = (_initialGroupObject$i = initialGroupObject === null || initialGroupObject === void 0 ? void 0 : initialGroupObject.identifier) !== null && _initialGroupObject$i !== void 0 ? _initialGroupObject$i : [];
            newIdentifiers = existingIdentifiers.filter(function (identifier) {
              return identifier.use !== _reactUtils.IdentifierUseCodes.OFFICIAL;
            });

            if (materialNumber) {
              newIdentifiers.push({
                use: _reactUtils.IdentifierUseCodes.OFFICIAL,
                value: materialNumber
              });
            }

            if (newIdentifiers.length) {
              payload.identifier = newIdentifiers;
            }

            if (type) {
              payload.type = type;
            }

            existingCharacteristics = (_initialGroupObject$c = initialGroupObject === null || initialGroupObject === void 0 ? void 0 : initialGroupObject.characteristic) !== null && _initialGroupObject$c !== void 0 ? _initialGroupObject$c : [];
            newCharacteristics = generateCharacteristicPayload(existingCharacteristics, values);
            _context.next = 16;
            return getProductImagePayload(values, initialValues);

          case 16:
            _yield$getProductImag = _context.sent;
            changed = _yield$getProductImag.changed;
            binaryPayload = _yield$getProductImag.payload;

            if (changed) {
              newCharacteristics = newCharacteristics.filter(function (stic) {
                var _stic$code$coding;

                return ((_stic$code$coding = stic.code.coding) !== null && _stic$code$coding !== void 0 ? _stic$code$coding : []).map(function (coding) {
                  return coding.code;
                }).indexOf(_utils.photoUploadCharacteristicCode) < 0;
              });

              if (binaryPayload) {
                binaryResourceUrl = "".concat(_constants.binaryResourceType, "/").concat(binaryPayload.id);
                productImageCharacteristic = {
                  code: {
                    coding: [{
                      system: _utils.smartRegisterCodeSystem,
                      code: _utils.photoUploadCharacteristicCode,
                      display: 'Product Image code'
                    }]
                  },
                  valueReference: {
                    reference: binaryResourceUrl
                  }
                };
                newCharacteristics = [].concat((0, _toConsumableArray2["default"])(newCharacteristics), [productImageCharacteristic]);
              }
            }

            payload.characteristic = newCharacteristics;
            return _context.abrupt("return", {
              group: payload,
              binary: binaryPayload,
              binaryChanged: changed
            });

          case 22:
          case "end":
            return _context.stop();
        }
      }
    }, _callee);
  }));

  return function generateGroupPayload(_x4, _x5) {
    return _ref2.apply(this, arguments);
  };
}();

exports.generateGroupPayload = generateGroupPayload;

var postPutGroup = function postPutGroup(baseUrl, payload) {
  var serve = new _reactUtils.FHIRServiceClass(baseUrl, _constants.groupResourceType);
  return serve.update(payload);
};

exports.postPutGroup = postPutGroup;

var postPutBinary = function postPutBinary(baseUrl, payload) {
  var serve = new _reactUtils.FHIRServiceClass(baseUrl, _constants.binaryResourceType);
  return serve.update(payload);
};

exports.postPutBinary = postPutBinary;

function getOrCreateList(_x6, _x7) {
  return _getOrCreateList.apply(this, arguments);
}

function _getOrCreateList() {
  _getOrCreateList = (0, _asyncToGenerator2["default"])(_regenerator["default"].mark(function _callee5(baseUrl, listId) {
    var serve;
    return _regenerator["default"].wrap(function _callee5$(_context5) {
      while (1) {
        switch (_context5.prev = _context5.next) {
          case 0:
            serve = new _reactUtils.FHIRServiceClass(baseUrl, _constants.listResourceType);
            return _context5.abrupt("return", serve.read(listId)["catch"](function (err) {
              if (err.statusCode === 404) {
                var listResource = createSupplyManagementList(listId);
                return serve.update(listResource);
              }

              throw err;
            }));

          case 2:
          case "end":
            return _context5.stop();
        }
      }
    }, _callee5);
  }));
  return _getOrCreateList.apply(this, arguments);
}

var updateListReferencesFactory = function updateListReferencesFactory(baseUrl, listId, initialBinary) {
  return function () {
    var _ref4 = (0, _asyncToGenerator2["default"])(_regenerator["default"].mark(function _callee2(formResponses, editingGroup) {
      var _payload$entry;

      var group, binary, binaryChanged, commoditiesListResource, payload, existingEntries, toRemoveBinaryRef, toAddBinaryRef, serve;
      return _regenerator["default"].wrap(function _callee2$(_context2) {
        while (1) {
          switch (_context2.prev = _context2.next) {
            case 0:
              group = formResponses.group, binary = formResponses.binary, binaryChanged = formResponses.binaryChanged;

              if (!(editingGroup && !binaryChanged)) {
                _context2.next = 3;
                break;
              }

              return _context2.abrupt("return");

            case 3:
              _context2.next = 5;
              return getOrCreateList(baseUrl, listId);

            case 5:
              commoditiesListResource = _context2.sent;
              payload = (0, _lodash.cloneDeep)(commoditiesListResource);
              existingEntries = (_payload$entry = payload.entry) !== null && _payload$entry !== void 0 ? _payload$entry : [];

              if (binaryChanged) {
                if (initialBinary) {
                  toRemoveBinaryRef = "".concat(_constants.binaryResourceType, "/").concat(initialBinary.id);
                  existingEntries = existingEntries.filter(function (entry) {
                    return entry.item.reference !== toRemoveBinaryRef;
                  });
                }

                if (binary) {
                  toAddBinaryRef = "".concat(_constants.binaryResourceType, "/").concat(binary.id);
                  existingEntries.push({
                    item: {
                      reference: toAddBinaryRef
                    }
                  });
                }
              }

              if (!editingGroup) {
                existingEntries.push({
                  item: {
                    reference: "".concat(_constants.groupResourceType, "/").concat(group.id)
                  }
                });
              }

              if (existingEntries.length) {
                payload.entry = existingEntries;
              }

              serve = new _reactUtils.FHIRServiceClass(baseUrl, _constants.listResourceType);
              return _context2.abrupt("return", serve.update(payload));

            case 13:
            case "end":
              return _context2.stop();
          }
        }
      }, _callee2);
    }));

    return function (_x8, _x9) {
      return _ref4.apply(this, arguments);
    };
  }();
};

exports.updateListReferencesFactory = updateListReferencesFactory;

function createSupplyManagementList(id) {
  return {
    resourceType: _constants.listResourceType,
    id: id,
    identifier: [{
      use: _reactUtils.IdentifierUseCodes.OFFICIAL,
      value: id
    }],
    status: 'current',
    mode: 'working',
    title: 'Supply Chain commodities',
    code: {
      coding: [{
        system: 'http://ona.io',
        code: 'supply-chain',
        display: 'Supply Chain Commodity'
      }],
      text: 'Supply Chain Commodity'
    },
    entry: []
  };
}