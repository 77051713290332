"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

var _typeof = require("@babel/runtime/helpers/typeof");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.BaseAllLocationListFlat = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _react = _interopRequireWildcard(require("react"));

var _reactUtils = require("@opensrp/react-utils");

var _constants = require("../../constants");

var _reactHelmet = require("react-helmet");

var _mls = require("../../mls");

var _antd = require("antd");

var _reactRouterDom = require("react-router-dom");

var _utils = require("./utils");

require("./base.css");

var _dataGridFilterRow = require("./dataGridFilterRow");

function _getRequireWildcardCache(nodeInterop) {
  if (typeof WeakMap !== "function") return null;
  var cacheBabelInterop = new WeakMap();
  var cacheNodeInterop = new WeakMap();
  return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) {
    return nodeInterop ? cacheNodeInterop : cacheBabelInterop;
  })(nodeInterop);
}

function _interopRequireWildcard(obj, nodeInterop) {
  if (!nodeInterop && obj && obj.__esModule) {
    return obj;
  }

  if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") {
    return {
      "default": obj
    };
  }

  var cache = _getRequireWildcardCache(nodeInterop);

  if (cache && cache.has(obj)) {
    return cache.get(obj);
  }

  var newObj = {};
  var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor;

  for (var key in obj) {
    if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) {
      var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null;

      if (desc && (desc.get || desc.set)) {
        Object.defineProperty(newObj, key, desc);
      } else {
        newObj[key] = obj[key];
      }
    }
  }

  newObj["default"] = obj;

  if (cache) {
    cache.set(obj, newObj);
  }

  return newObj;
}

function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);

  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);
    enumerableOnly && (symbols = symbols.filter(function (sym) {
      return Object.getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }

  return keys;
}

function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      (0, _defineProperty2["default"])(target, key, source[key]);
    }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
    });
  }

  return target;
}

var getSearchParamsFactory = function getSearchParamsFactory(ourParams) {
  return function (search) {
    var baseSearchParam = _objectSpread(_objectSpread({}, ourParams), {}, {
      _include: 'Location:partof'
    });

    if (search) {
      return _objectSpread({
        'name:contains': search
      }, baseSearchParam);
    }

    return baseSearchParam;
  };
};

var BaseAllLocationListFlat = function BaseAllLocationListFlat(props) {
  var _props$showParentLoca;

  var fhirBaseURL = props.fhirBaseURL,
      extraParamFilters = props.extraParamFilters,
      addLocationBtnRender = props.addLocationBtnRender,
      getColumns = props.getColumns,
      pageTitle = props.pageTitle;

  var _useMls = (0, _mls.useMls)(),
      t = _useMls.t;

  var history = (0, _reactRouterDom.useHistory)();
  var getSearchParams = getSearchParamsFactory(extraParamFilters !== null && extraParamFilters !== void 0 ? extraParamFilters : {});

  var _useSimpleTabularView = (0, _reactUtils.useSimpleTabularView)(fhirBaseURL, _constants.locationResourceType, getSearchParams, _utils.getEntryFromBundle),
      _useSimpleTabularView2 = _useSimpleTabularView.queryValues,
      data = _useSimpleTabularView2.data,
      isFetching = _useSimpleTabularView2.isFetching,
      isLoading = _useSimpleTabularView2.isLoading,
      error = _useSimpleTabularView2.error,
      tablePaginationProps = _useSimpleTabularView.tablePaginationProps,
      searchFormProps = _useSimpleTabularView.searchFormProps,
      _useSimpleTabularView3 = _useSimpleTabularView.sortOptions,
      updateSortParams = _useSimpleTabularView3.updateSortParams,
      getControlledSortProps = _useSimpleTabularView3.getControlledSortProps,
      _useSimpleTabularView4 = _useSimpleTabularView.filterOptions,
      updateFilterParams = _useSimpleTabularView4.updateFilterParams,
      currentFilters = _useSimpleTabularView4.currentFilters;

  var tableData = (0, _react.useMemo)(function () {
    var _data$records;

    return (0, _utils.getTableData)((_data$records = data === null || data === void 0 ? void 0 : data.records) !== null && _data$records !== void 0 ? _data$records : []);
  }, [data]);

  if (error && !data) {
    return _react["default"].createElement(_reactUtils.BrokenPage, {
      errorMessage: error.message
    });
  }

  var getTableLocale = function getTableLocale() {
    var urlQuery = history.location.search;
    var nameSearchActive = urlQuery.includes('search=');

    if (!tableData.length && (!isFetching || !isLoading)) {
      var description = nameSearchActive ? '' : t('No data available to display, you can start adding data now');
      return {
        emptyText: _react["default"].createElement(_reactUtils.NoData, {
          description: description
        }, !nameSearchActive && addLocationBtnRender())
      };
    }
  };

  var tableProps = {
    datasource: tableData,
    columns: getColumns(t, getControlledSortProps),
    loading: isFetching || isLoading,
    pagination: tablePaginationProps,
    locale: getTableLocale(),
    onChange: function onChange(_, __, sorter) {
      var sorterArray = Array.isArray(sorter) ? sorter : [sorter];
      var sortMap = sorterArray.reduce(function (acc, sort) {
        var _sort$column;

        var dataIndex = (_sort$column = sort.column) === null || _sort$column === void 0 ? void 0 : _sort$column.dataIndex;

        if (!sort.order) {
          acc[dataIndex] = undefined;
          return acc;
        }

        if (dataIndex) {
          acc[dataIndex] = {
            paramAccessor: dataIndex,
            order: sort.order
          };
        }

        return acc;
      }, {});
      updateSortParams(sortMap);
    }
  };
  var headerProps = {
    pageHeaderProps: {
      title: pageTitle,
      onBack: undefined
    }
  };
  return _react["default"].createElement(_reactUtils.BodyLayout, {
    headerProps: headerProps,
    id: "all-locations"
  }, _react["default"].createElement(_reactHelmet.Helmet, null, _react["default"].createElement("title", null, pageTitle)), _react["default"].createElement(_antd.Row, {
    className: "list-view"
  }, _react["default"].createElement(_antd.Col, {
    className: "main-content"
  }, _react["default"].createElement("div", {
    className: "main-content__header"
  }, _react["default"].createElement(_reactUtils.SearchForm, searchFormProps), addLocationBtnRender()), _react["default"].createElement(_dataGridFilterRow.LocationGridFilterRowRender, {
    fhirBaseUrl: fhirBaseURL,
    updateFilterParams: updateFilterParams,
    currentFilters: currentFilters,
    showParentLocationFilter: (_props$showParentLoca = props.showParentLocationFilter) !== null && _props$showParentLoca !== void 0 ? _props$showParentLoca : true
  }), _react["default"].createElement(_reactUtils.TableLayout, tableProps))));
};

exports.BaseAllLocationListFlat = BaseAllLocationListFlat;