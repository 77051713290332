"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RichPageHeader = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _react = _interopRequireDefault(require("react"));

var _proLayout = require("@ant-design/pro-layout");

var _reactRouterDom = require("react-router-dom");

var _mls = require("../../../mls");

var _antd = require("antd");

function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);

  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);
    enumerableOnly && (symbols = symbols.filter(function (sym) {
      return Object.getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }

  return keys;
}

function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      (0, _defineProperty2["default"])(target, key, source[key]);
    }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
    });
  }

  return target;
}

var RichPageHeader = function RichPageHeader(props) {
  var pageHeaderProps = props.pageHeaderProps,
      breadCrumbProps = props.breadCrumbProps;

  var _useTranslation = (0, _mls.useTranslation)(),
      t = _useTranslation.t;

  var history = (0, _reactRouterDom.useHistory)();

  var updatedBreadcrumbProps = _objectSpread({
    itemRender: function itemRender(route, _, items, __) {
      var last = items.indexOf(route) === items.length - 1;
      return last ? _react["default"].createElement("span", null, route.title) : _react["default"].createElement(_reactRouterDom.Link, {
        to: route.path ? route.path : '#'
      }, route.title);
    }
  }, breadCrumbProps);

  var updatedPageheaderProps = _objectSpread({
    className: 'site-page-header',
    onBack: function onBack() {
      return history.goBack();
    },
    title: t('View details')
  }, pageHeaderProps);

  if (breadCrumbProps !== null && breadCrumbProps !== void 0 && breadCrumbProps.items) {
    var _pageHeaderProps$brea;

    var breadcrumbRenderFn = function breadcrumbRenderFn() {
      return _react["default"].createElement(_antd.Breadcrumb, updatedBreadcrumbProps);
    };

    updatedPageheaderProps.breadcrumbRender = (_pageHeaderProps$brea = pageHeaderProps === null || pageHeaderProps === void 0 ? void 0 : pageHeaderProps.breadcrumbRender) !== null && _pageHeaderProps$brea !== void 0 ? _pageHeaderProps$brea : breadcrumbRenderFn;
  }

  return _react["default"].createElement(_proLayout.PageHeader, updatedPageheaderProps);
};

exports.RichPageHeader = RichPageHeader;