"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CommodityList = void 0;

var _pkgConfig = require("@opensrp/pkg-config");

var _List = require("./Eusm/List");

var _List2 = require("./Default/List");

var _react = _interopRequireDefault(require("react"));

var _mls = require("../../mls");

var _antd = require("antd");

var CommodityList = function CommodityList(props) {
  var _useTranslation = (0, _mls.useTranslation)(),
      t = _useTranslation.t;

  var listId = props.listId;
  var projectCode = (0, _pkgConfig.getConfig)('projectCode');

  if (!listId) {
    return _react["default"].createElement(_antd.Alert, {
      type: "error",
      message: t('Incorrect/Missing Configuration'),
      description: t('List id is either incorrectly configured or missing. Kindly contact support to fix this.'),
      banner: true,
      showIcon: true
    });
  }

  if (projectCode === 'eusm') {
    return _react["default"].createElement(_List.EusmCommodityList, props);
  } else {
    return _react["default"].createElement(_List2.DefaultCommodityList, props);
  }
};

exports.CommodityList = CommodityList;