"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useMls = void 0;

var _i18n = require("@opensrp/i18n");

var _constants = require("./constants");

var useMls = function useMls(ns, options) {
  return (0, _i18n.useTranslation)(ns ? ns : _constants.namespace, options);
};

exports.useMls = useMls;