"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.EusmLocationListFlat = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _react = _interopRequireDefault(require("react"));

var _mls = require("../../mls");

var _antd = require("antd");

var _reactRouterDom = require("react-router-dom");

var _rbac = require("@opensrp/rbac");

var _icons = require("@ant-design/icons");

var _base = require("./base");

var _utils = require("./utils");

var _constants = require("../../constants");

function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);

  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);
    enumerableOnly && (symbols = symbols.filter(function (sym) {
      return Object.getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }

  return keys;
}

function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      (0, _defineProperty2["default"])(target, key, source[key]);
    }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
    });
  }

  return target;
}

var EusmLocationListFlat = function EusmLocationListFlat(props) {
  var _useMls = (0, _mls.useMls)(),
      t = _useMls.t;

  var history = (0, _reactRouterDom.useHistory)();

  var getItems = function getItems(_) {
    return [{
      key: '1',
      label: _react["default"].createElement(_reactRouterDom.Link, {
        to: "".concat(_constants.URL_LOCATION_VIEW_DETAILS, "/").concat(_.id),
        className: "m-0 p-1"
      }, t('View details'))
    }];
  };

  var getColumns = function getColumns(t, getControlledSortProps) {
    return [_objectSpread({
      title: t('Name'),
      dataIndex: 'name',
      sorter: true
    }, getControlledSortProps('name')), {
      title: t('Type'),
      dataIndex: 'type'
    }, {
      title: t('Status'),
      dataIndex: 'status'
    }, {
      title: t('Parent'),
      dataIndex: 'parent'
    }, {
      title: t('Actions'),
      width: '10%',
      render: function render(_, record) {
        return _react["default"].createElement("span", {
          className: "d-flex align-items-center"
        }, _react["default"].createElement(_rbac.RbacCheck, {
          permissions: ['Location.update']
        }, _react["default"].createElement(_react["default"].Fragment, null, _react["default"].createElement(_reactRouterDom.Link, {
          to: "".concat(_constants.URL_SERVICE_POINT_ADD_EDIT, "/").concat(record.id.toString()),
          className: "m-0 p-1"
        }, t('Edit')), _react["default"].createElement(_antd.Divider, {
          type: "vertical"
        }))), _react["default"].createElement(_antd.Dropdown, {
          menu: {
            items: getItems(record)
          },
          placement: "bottomRight",
          arrow: true,
          trigger: ['click']
        }, _react["default"].createElement(_icons.MoreOutlined, {
          className: "more-options",
          "data-testid": "action-dropdown"
        })));
      }
    }];
  };

  var addLocationBtnRender = function addLocationBtnRender() {
    return _react["default"].createElement(_rbac.RbacCheck, {
      permissions: ['Location.create']
    }, _react["default"].createElement(_antd.Button, {
      type: "primary",
      onClick: function onClick() {
        return history.push(_constants.URL_SERVICE_POINT_ADD_EDIT);
      }
    }, _react["default"].createElement(_icons.PlusOutlined, null), t('Add Service point')));
  };

  var baseProps = _objectSpread(_objectSpread({}, props), {}, {
    pageTitle: t('Service points'),
    addLocationBtnRender: addLocationBtnRender,
    getColumns: getColumns,
    extraParamFilters: _utils.eusmPhysicalLocationsFilterParams,
    showParentLocationFilter: false
  });

  return _react["default"].createElement(_base.BaseAllLocationListFlat, baseProps);
};

exports.EusmLocationListFlat = EusmLocationListFlat;