"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CareTeamDetailsView = void 0;

var _react = _interopRequireDefault(require("react"));

var _mls = require("../../../../mls");

var _reactUtils = require("@opensrp/react-utils");

var _antd = require("antd");

var CareTeamDetailsView = function CareTeamDetailsView(_ref) {
  var _practitionerDetails$;

  var loading = _ref.loading,
      practitionerDetails = _ref.practitionerDetails,
      error = _ref.error;

  var _useTranslation = (0, _mls.useTranslation)(),
      t = _useTranslation.t;

  if (error) {
    return _react["default"].createElement(_antd.Alert, {
      type: "error"
    }, t('An error occurred while trying to fetch the practitioner details.'));
  }

  var careTeams = (_practitionerDetails$ = practitionerDetails.careteams) !== null && _practitionerDetails$ !== void 0 ? _practitionerDetails$ : [];
  var tableData = careTeams.map(function (resource) {
    var id = resource.id,
        status = resource.status,
        name = resource.name,
        category = resource.category;
    return {
      id: id,
      status: status,
      name: name,
      category: category !== null && category !== void 0 ? category : []
    };
  });
  var columns = [{
    title: t('Id'),
    dataIndex: 'id'
  }, {
    title: t('Name'),
    dataIndex: 'name'
  }, {
    title: t('Status'),
    dataIndex: 'status'
  }, {
    title: t('Category'),
    dataIndex: 'category',
    render: function render(concepts) {
      return concepts.map(function (concept, index) {
        return _react["default"].createElement(_reactUtils.CodeableConcept, {
          key: index,
          concept: concept
        });
      });
    }
  }];
  var tableProps = {
    datasource: tableData,
    columns: columns,
    loading: loading,
    size: 'small'
  };
  return _react["default"].createElement(_reactUtils.TableLayout, tableProps);
};

exports.CareTeamDetailsView = CareTeamDetailsView;