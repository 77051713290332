"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.EusmCommodityList = void 0;

var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));

var _react = _interopRequireDefault(require("react"));

var _antd = require("antd");

var _icons = require("@ant-design/icons");

var _constants = require("../../../constants");

var _reactRouterDom = require("react-router-dom");

var _mls = require("../../../mls");

var _reactUtils = require("@opensrp/react-utils");

var _utils = require("../../../helpers/utils");

var _rbac = require("@opensrp/rbac");

var _ViewDetails = require("./ViewDetails");

var _GroupGridFilterRow = require("./GroupGridFilterRow");

var _ClientSideActionsGrid = require("../../BaseComponents/BaseGroupsListView/ClientSideActionsGrid");

var _excluded = ["permissions"];

var EusmCommodityList = function EusmCommodityList(props) {
  var fhirBaseURL = props.fhirBaseURL,
      listId = props.listId;
  var history = (0, _reactRouterDom.useHistory)();

  var _useTranslation = (0, _mls.useTranslation)(),
      t = _useTranslation.t;

  var _useSearchParams = (0, _reactUtils.useSearchParams)(),
      addParam = _useSearchParams.addParam;

  var userRole = (0, _rbac.useUserRole)();

  var getItems = function getItems(record) {
    return [{
      key: '1',
      permissions: [],
      label: _react["default"].createElement(_antd.Button, {
        "data-testid": "view-details",
        onClick: function onClick() {
          return addParam(_reactUtils.viewDetailsQuery, record.id);
        },
        type: "link"
      }, t('View Details'))
    }].filter(function (item) {
      return userRole.hasPermissions(item.permissions);
    }).map(function (item) {
      var permissions = item.permissions,
          rest = (0, _objectWithoutProperties2["default"])(item, _excluded);
      return rest;
    });
  };

  var getColumns = function getColumns(t) {
    return [{
      title: t('Material Number'),
      dataIndex: 'identifier',
      key: 'identifier',
      sorter: function sorter(a, b) {
        var _a$identifier, _b$identifier;

        return ((_a$identifier = a.identifier) !== null && _a$identifier !== void 0 ? _a$identifier : '').localeCompare((_b$identifier = b.identifier) !== null && _b$identifier !== void 0 ? _b$identifier : '');
      }
    }, {
      title: t('Name'),
      dataIndex: 'name',
      key: 'name',
      sorter: function sorter(a, b) {
        var _a$name, _b$name;

        return ((_a$name = a.name) !== null && _a$name !== void 0 ? _a$name : '').localeCompare((_b$name = b.name) !== null && _b$name !== void 0 ? _b$name : '');
      }
    }, {
      title: t('Is it an Asset'),
      dataIndex: 'attractive',
      key: 'attractive',
      render: function render(value) {
        return _react["default"].createElement("div", null, value ? t('Yes') : t('No'));
      }
    }, {
      title: t('Active'),
      dataIndex: 'active',
      key: 'active',
      render: function render(value) {
        return _react["default"].createElement("div", null, value ? t('Active') : t('Disabled'));
      }
    }, {
      title: t('Actions'),
      width: '10%',
      render: function render(_, record) {
        return _react["default"].createElement("span", {
          className: "d-flex align-items-center"
        }, _react["default"].createElement(_rbac.RbacCheck, {
          permissions: ['Group.update', 'List.create', 'List.update']
        }, _react["default"].createElement(_react["default"].Fragment, null, _react["default"].createElement(_reactRouterDom.Link, {
          to: "".concat(_constants.ADD_EDIT_COMMODITY_URL, "/").concat(record.id),
          className: "m-0 p-1"
        }, t('Edit')), _react["default"].createElement(_antd.Divider, {
          type: "vertical"
        }))), _react["default"].createElement(_antd.Dropdown, {
          menu: {
            items: getItems(record)
          },
          placement: "bottomRight",
          arrow: true,
          trigger: ['click']
        }, _react["default"].createElement(_icons.MoreOutlined, {
          "data-testid": "action-dropdown",
          className: "more-options"
        })));
      }
    }];
  };

  var baseListViewProps = {
    getColumns: getColumns,
    addGroupBtnRender: function addGroupBtnRender() {
      return _react["default"].createElement(_rbac.RbacCheck, {
        permissions: ['Group.create', 'List.create', 'List.update']
      }, _react["default"].createElement(_antd.Button, {
        type: "primary",
        onClick: function onClick() {
          return history.push(_constants.ADD_EDIT_COMMODITY_URL);
        }
      }, _react["default"].createElement(_icons.PlusOutlined, null), t('Add commodity')));
    },
    fhirBaseURL: fhirBaseURL,
    dataTransformer: function dataTransformer(bundle) {
      return (0, _reactUtils.getResourcesFromBundle)(bundle).map(function (group) {
        return (0, _ViewDetails.parseEusmCommodity)(group);
      });
    },
    pageTitle: t('Commodity List'),
    extraQueryFilters: {
      code: "".concat(_utils.snomedCodeSystem, "|").concat(_utils.supplyMgSnomedCode),
      '_has:List:item:_id': listId
    },
    viewDetailsRender: function viewDetailsRender(fhirBaseURL, resourceId) {
      return _react["default"].createElement(_ViewDetails.ViewDetailsWrapper, {
        fhirBaseURL: fhirBaseURL,
        resourceId: resourceId
      });
    },
    filterRowRender: function filterRowRender(registerFilter, filterRegistry) {
      return _react["default"].createElement(_GroupGridFilterRow.GroupGridFilerRow, {
        updateFilterParams: registerFilter,
        currentFilters: filterRegistry
      });
    }
  };
  return _react["default"].createElement(_ClientSideActionsGrid.ClientSideActionsBaseListView, baseListViewProps);
};

exports.EusmCommodityList = EusmCommodityList;