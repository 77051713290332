"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.taskResourceType = exports.planDefinitionType = exports.patientResourceType = exports.immunizationResourceType = exports.encounterResourceType = exports.dateFormat = exports.conditionResourceType = exports.carePlanResourceType = exports.LIST_PATIENTS_URL = void 0;
var dateFormat = 'yyyy-MM-dd';
exports.dateFormat = dateFormat;
var patientResourceType = 'Patient';
exports.patientResourceType = patientResourceType;
var carePlanResourceType = 'CarePlan';
exports.carePlanResourceType = carePlanResourceType;
var immunizationResourceType = 'Immunization';
exports.immunizationResourceType = immunizationResourceType;
var encounterResourceType = 'Encounter';
exports.encounterResourceType = encounterResourceType;
var conditionResourceType = 'Condition';
exports.conditionResourceType = conditionResourceType;
var taskResourceType = 'Task';
exports.taskResourceType = taskResourceType;
var planDefinitionType = 'PlanDefinition';
exports.planDefinitionType = planDefinitionType;
var LIST_PATIENTS_URL = '/fhir/Patient';
exports.LIST_PATIENTS_URL = LIST_PATIENTS_URL;