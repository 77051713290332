"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PopulatedTableTabs = exports.MemoizePopulatedTableTabs = void 0;

var _toConsumableArray2 = _interopRequireDefault(require("@babel/runtime/helpers/toConsumableArray"));

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _react = _interopRequireDefault(require("react"));

var _reactUtils = require("@opensrp/react-utils");

var _constants = require("../../../constants");

var _mls = require("../../../mls");

var _utils = require("../utils");

var _CarePlan = require("../ResourceSchema/CarePlan");

var _Immunization = require("../ResourceSchema/Immunization");

var _Encounter = require("../ResourceSchema/Encounter");

var _Condition = require("../ResourceSchema/Condition");

var _Task = require("../ResourceSchema/Task");

var _antd = require("antd");

var _rbac = require("@opensrp/rbac");

function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);

  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);
    enumerableOnly && (symbols = symbols.filter(function (sym) {
      return Object.getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }

  return keys;
}

function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      (0, _defineProperty2["default"])(target, key, source[key]);
    }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
    });
  }

  return target;
}

var PopulatedTableTabs = function PopulatedTableTabs(props) {
  var fhirBaseURL = props.fhirBaseURL,
      patientId = props.patientId;

  var _useTranslation = (0, _mls.useTranslation)(),
      t = _useTranslation.t;

  var userRole = (0, _rbac.useUserRole)();

  var _useSearchParams = (0, _reactUtils.useSearchParams)(),
      addParams = _useSearchParams.addParams,
      removeParam = _useSearchParams.removeParam;

  var hasCareTeamRead = userRole.hasPermissions(['CareTeam.read']);
  var hasConditionRead = userRole.hasPermissions(['Condition.read']);
  var hasTaskRead = userRole.hasPermissions(['Task.read']);
  var hasImmunizationRead = userRole.hasPermissions(['Immunization.read']);
  var hasEncounterRead = userRole.hasPermissions(['Encounter.read']);
  var defaultTableData = {
    resourceId: patientId,
    fhirBaseURL: fhirBaseURL,
    searchParamsFactory: _utils.defaultSearchParamsFactory
  };
  var tableActionColumn = {
    title: t('Actions'),
    render: function render(value) {
      return _react["default"].createElement(_antd.Button, {
        onClick: function onClick() {
          return addParams((0, _defineProperty2["default"])({}, _reactUtils.sideViewQuery, value.id));
        },
        type: "link"
      }, t('View'));
    }
  };

  var carePlanTableData = _objectSpread(_objectSpread({}, defaultTableData), {}, {
    resourceType: _constants.carePlanResourceType,
    tableColumns: [].concat((0, _toConsumableArray2["default"])((0, _CarePlan.columns)(t)), [tableActionColumn]),
    tableDataGetter: _CarePlan.parseCareplanList,
    extractSideViewDetails: (0, _utils.sidePreviewDetailsExtractor)(patientId, _CarePlan.carePlanSideViewData, function () {
      return removeParam(_reactUtils.sideViewQuery);
    })
  });

  var conditionTableData = _objectSpread(_objectSpread({}, defaultTableData), {}, {
    resourceType: _constants.conditionResourceType,
    tableColumns: [].concat((0, _toConsumableArray2["default"])((0, _Condition.columns)(t)), [tableActionColumn]),
    tableDataGetter: _Condition.parseConditionList,
    extractSideViewDetails: (0, _utils.sidePreviewDetailsExtractor)(patientId, _Condition.conditionSideViewData, function () {
      return removeParam(_reactUtils.sideViewQuery);
    })
  });

  var taskTableData = _objectSpread(_objectSpread({}, defaultTableData), {}, {
    resourceType: _constants.taskResourceType,
    tableColumns: [].concat((0, _toConsumableArray2["default"])((0, _Task.columns)(t)), [tableActionColumn]),
    tableDataGetter: _Task.parseTaskList,
    searchParamsFactory: _Task.taskSearchParams,
    extractSideViewDetails: (0, _utils.sidePreviewDetailsExtractor)(patientId, _Task.taskSideViewData, function () {
      return removeParam(_reactUtils.sideViewQuery);
    })
  });

  var immunizationTableData = _objectSpread(_objectSpread({}, defaultTableData), {}, {
    resourceType: _constants.immunizationResourceType,
    tableColumns: [].concat((0, _toConsumableArray2["default"])((0, _Immunization.columns)(t)), [tableActionColumn]),
    tableDataGetter: _Immunization.parseImmunizationList,
    searchParamsFactory: _Immunization.immunizationSearchParams,
    extractSideViewDetails: (0, _utils.sidePreviewDetailsExtractor)(patientId, _Immunization.immunizationSideViewData, function () {
      return removeParam(_reactUtils.sideViewQuery);
    })
  });

  var patientEncounterTableData = _objectSpread(_objectSpread({}, defaultTableData), {}, {
    resourceType: _constants.encounterResourceType,
    tableColumns: [].concat((0, _toConsumableArray2["default"])((0, _Encounter.columns)(t)), [tableActionColumn]),
    tableDataGetter: _Encounter.parseEncounterList,
    extractSideViewDetails: (0, _utils.sidePreviewDetailsExtractor)(patientId, _Encounter.encounterPreviewExtractor, function () {
      return removeParam(_reactUtils.sideViewQuery);
    })
  });

  var tabViewProps = {
    tabViewId: 'tabView',
    sideViewQueryName: _reactUtils.sideViewQuery,
    size: 'small',
    items: [{
      label: _react["default"].createElement(_reactUtils.TabsTitle, {
        fhirBaseURL: fhirBaseURL,
        resourceType: _constants.carePlanResourceType,
        title: t('Care plan'),
        resourceFilters: (0, _utils.defaultSearchParamsFactory)(patientId),
        hasResourcePermissions: hasCareTeamRead
      }),
      key: 'carePlan',
      children: _react["default"].createElement(_reactUtils.TabsTable, carePlanTableData),
      disabled: !hasCareTeamRead
    }, {
      label: _react["default"].createElement(_reactUtils.TabsTitle, {
        fhirBaseURL: fhirBaseURL,
        resourceType: _constants.conditionResourceType,
        title: t('Condition'),
        resourceFilters: (0, _utils.defaultSearchParamsFactory)(patientId),
        hasResourcePermissions: hasConditionRead
      }),
      key: 'condition',
      children: _react["default"].createElement(_reactUtils.TabsTable, conditionTableData),
      disabled: !hasConditionRead
    }, {
      label: _react["default"].createElement(_reactUtils.TabsTitle, {
        fhirBaseURL: fhirBaseURL,
        resourceType: _constants.taskResourceType,
        title: t('Task'),
        resourceFilters: (0, _Task.taskSearchParams)(patientId),
        hasResourcePermissions: hasTaskRead
      }),
      key: 'task',
      children: _react["default"].createElement(_reactUtils.TabsTable, taskTableData),
      disabled: !hasTaskRead
    }, {
      label: _react["default"].createElement(_reactUtils.TabsTitle, {
        fhirBaseURL: fhirBaseURL,
        resourceType: _constants.immunizationResourceType,
        title: t('Immunization'),
        resourceFilters: (0, _Immunization.immunizationSearchParams)(patientId),
        hasResourcePermissions: hasImmunizationRead
      }),
      key: 'immunization',
      children: _react["default"].createElement(_reactUtils.TabsTable, immunizationTableData),
      disabled: !hasImmunizationRead
    }, {
      label: _react["default"].createElement(_reactUtils.TabsTitle, {
        fhirBaseURL: fhirBaseURL,
        resourceType: _constants.encounterResourceType,
        title: t('Patient encounter'),
        resourceFilters: (0, _utils.defaultSearchParamsFactory)(patientId),
        hasResourcePermissions: hasEncounterRead
      }),
      key: 'patientEncounter',
      children: _react["default"].createElement(_reactUtils.TabsTable, patientEncounterTableData),
      disabled: !hasEncounterRead
    }]
  };
  return _react["default"].createElement(_reactUtils.GenericTabsView, tabViewProps);
};

exports.PopulatedTableTabs = PopulatedTableTabs;

var MemoizePopulatedTableTabs = _react["default"].memo(PopulatedTableTabs);

exports.MemoizePopulatedTableTabs = MemoizePopulatedTableTabs;