"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.InventoryView = void 0;

var _extends2 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _react = _interopRequireDefault(require("react"));

var _mls = require("../../../mls");

var _reactUtils = require("@opensrp/react-utils");

var _antd = require("antd");

var _constants = require("../../../constants");

var _rbac = require("@opensrp/rbac");

var _reactRouterDom = require("react-router-dom");

var _icons = require("@ant-design/icons");

var _fhirHelpers = require("@opensrp/fhir-helpers");

var _utils = require("../utils");

var _fhirGroupManagement = require("@opensrp/fhir-group-management");

function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);

  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);
    enumerableOnly && (symbols = symbols.filter(function (sym) {
      return Object.getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }

  return keys;
}

function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      (0, _defineProperty2["default"])(target, key, source[key]);
    }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
    });
  }

  return target;
}

function _createForOfIteratorHelper(o, allowArrayLike) {
  var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"];

  if (!it) {
    if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") {
      if (it) o = it;
      var i = 0;

      var F = function F() {};

      return {
        s: F,
        n: function n() {
          if (i >= o.length) return {
            done: true
          };
          return {
            done: false,
            value: o[i++]
          };
        },
        e: function e(_e) {
          throw _e;
        },
        f: F
      };
    }

    throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
  }

  var normalCompletion = true,
      didErr = false,
      err;
  return {
    s: function s() {
      it = it.call(o);
    },
    n: function n() {
      var step = it.next();
      normalCompletion = step.done;
      return step;
    },
    e: function e(_e2) {
      didErr = true;
      err = _e2;
    },
    f: function f() {
      try {
        if (!normalCompletion && it["return"] != null) it["return"]();
      } finally {
        if (didErr) throw err;
      }
    }
  };
}

function _unsupportedIterableToArray(o, minLen) {
  if (!o) return;
  if (typeof o === "string") return _arrayLikeToArray(o, minLen);
  var n = Object.prototype.toString.call(o).slice(8, -1);
  if (n === "Object" && o.constructor) n = o.constructor.name;
  if (n === "Map" || n === "Set") return Array.from(o);
  if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen);
}

function _arrayLikeToArray(arr, len) {
  if (len == null || len > arr.length) len = arr.length;

  for (var i = 0, arr2 = new Array(len); i < len; i++) {
    arr2[i] = arr[i];
  }

  return arr2;
}

function parseInventoryGroup(group) {
  var _firstMember$entity, _firstMember$period, _firstMember$period2, _quantityCharacterist, _poNumberIdentifier$, _sectionCharacteristi, _donorCharacteristic$, _serialNumberIdentifi;

  var member = group.member,
      characteristic = group.characteristic,
      identifier = group.identifier,
      id = group.id;

  var sanitizeDate = function sanitizeDate(dateString) {
    if (!dateString) return;
    var sampleDate = new Date(dateString);
    if (isNaN(sampleDate.getTime())) return;
    return sampleDate.toLocaleDateString();
  };

  var firstMember = member === null || member === void 0 ? void 0 : member[0];
  var productReference = firstMember === null || firstMember === void 0 ? void 0 : (_firstMember$entity = firstMember.entity) === null || _firstMember$entity === void 0 ? void 0 : _firstMember$entity.reference;
  var startDate = firstMember === null || firstMember === void 0 ? void 0 : (_firstMember$period = firstMember.period) === null || _firstMember$period === void 0 ? void 0 : _firstMember$period.start;
  var endDate = firstMember === null || firstMember === void 0 ? void 0 : (_firstMember$period2 = firstMember.period) === null || _firstMember$period2 === void 0 ? void 0 : _firstMember$period2.end;
  var poNumberIdentifier = identifier === null || identifier === void 0 ? void 0 : identifier.filter(function (id) {
    var _id$type$coding, _id$type;

    return ((_id$type$coding = (_id$type = id.type) === null || _id$type === void 0 ? void 0 : _id$type.coding) !== null && _id$type$coding !== void 0 ? _id$type$coding : []).indexOf(_fhirHelpers.poNumberIdentifierCoding);
  });
  var sectionCharacteristic = (0, _fhirHelpers.getCharacteristicWithCoding)(characteristic !== null && characteristic !== void 0 ? characteristic : [], _fhirHelpers.sectionCharacteristicCoding);
  var donorCharacteristic = (0, _fhirHelpers.getCharacteristicWithCoding)(characteristic !== null && characteristic !== void 0 ? characteristic : [], _fhirHelpers.donorCharacteristicCoding);
  var quantityCharacteristic = (0, _fhirHelpers.getCharacteristicWithCoding)(characteristic !== null && characteristic !== void 0 ? characteristic : [], _fhirHelpers.quantityCharacteristicCoding);
  var serialNumberIdentifier = identifier === null || identifier === void 0 ? void 0 : identifier.filter(function (id) {
    var _id$type2, _id$type2$coding;

    var hasCoding = (_id$type2 = id.type) === null || _id$type2 === void 0 ? void 0 : (_id$type2$coding = _id$type2.coding) === null || _id$type2$coding === void 0 ? void 0 : _id$type2$coding.some(function (coding) {
      return coding.code === _fhirHelpers.serialNumberIdentifierCoding.code;
    });
    return hasCoding;
  });
  return {
    id: id,
    productReference: productReference,
    quantity: quantityCharacteristic === null || quantityCharacteristic === void 0 ? void 0 : (_quantityCharacterist = quantityCharacteristic.valueQuantity) === null || _quantityCharacterist === void 0 ? void 0 : _quantityCharacterist.value,
    poNumber: poNumberIdentifier === null || poNumberIdentifier === void 0 ? void 0 : (_poNumberIdentifier$ = poNumberIdentifier[0]) === null || _poNumberIdentifier$ === void 0 ? void 0 : _poNumberIdentifier$.value,
    deliveryDate: sanitizeDate(startDate),
    accountabilityEndDate: sanitizeDate(endDate),
    unicefSection: sectionCharacteristic === null || sectionCharacteristic === void 0 ? void 0 : (_sectionCharacteristi = sectionCharacteristic.valueCodeableConcept) === null || _sectionCharacteristi === void 0 ? void 0 : _sectionCharacteristi.text,
    donor: donorCharacteristic === null || donorCharacteristic === void 0 ? void 0 : (_donorCharacteristic$ = donorCharacteristic.valueCodeableConcept) === null || _donorCharacteristic$ === void 0 ? void 0 : _donorCharacteristic$.text,
    serialNumber: serialNumberIdentifier === null || serialNumberIdentifier === void 0 ? void 0 : (_serialNumberIdentifi = serialNumberIdentifier[0]) === null || _serialNumberIdentifi === void 0 ? void 0 : _serialNumberIdentifi.value
  };
}

function parseProductGroup(group) {
  var name = group.name;
  return {
    productName: name
  };
}

function isInventory(res) {
  var _res$code$coding, _res$code;

  return (0, _utils.hasCode)((_res$code$coding = (_res$code = res.code) === null || _res$code === void 0 ? void 0 : _res$code.coding) !== null && _res$code$coding !== void 0 ? _res$code$coding : [], _fhirHelpers.inventoryGroupCoding);
}

function isProduct(res) {
  var _res$code$coding2, _res$code2;

  return (0, _utils.hasCode)((_res$code$coding2 = (_res$code2 = res.code) === null || _res$code2 === void 0 ? void 0 : _res$code2.coding) !== null && _res$code$coding2 !== void 0 ? _res$code$coding2 : [], _fhirHelpers.productCoding);
}

function dataTransformer(bundleResponse) {
  var _bundleResponse$entry;

  var entry = ((_bundleResponse$entry = bundleResponse.entry) !== null && _bundleResponse$entry !== void 0 ? _bundleResponse$entry : []).map(function (x) {
    return x.resource;
  });
  var inventoryById = {};
  var productById = {};

  var _iterator = _createForOfIteratorHelper(entry),
      _step;

  try {
    for (_iterator.s(); !(_step = _iterator.n()).done;) {
      var resource = _step.value;

      if (isInventory(resource)) {
        inventoryById[resource.id] = parseInventoryGroup(resource);
      }

      if (isProduct(resource)) {
        productById["".concat(_fhirHelpers.groupResourceType, "/").concat(resource.id)] = parseProductGroup(resource);
      }
    }
  } catch (err) {
    _iterator.e(err);
  } finally {
    _iterator.f();
  }

  var tableData = [];

  for (var _i = 0, _Object$values = Object.values(inventoryById); _i < _Object$values.length; _i++) {
    var inventory = _Object$values[_i];
    var tableDataEntry = inventory;
    var productReference = inventory === null || inventory === void 0 ? void 0 : inventory.productReference;
    var correspondingProductReference = productReference ? productById[productReference] : {};

    if (correspondingProductReference) {
      tableDataEntry = _objectSpread(_objectSpread({}, tableDataEntry), correspondingProductReference);
    }

    tableData.push(tableDataEntry);
  }

  return tableData;
}

function matchesSearch(obj, search) {
  var _obj$productName;

  return ((_obj$productName = obj.productName) !== null && _obj$productName !== void 0 ? _obj$productName : '').toLowerCase().includes(search.toLowerCase());
}

function activeInventoryByAccEndDate(obj) {
  if (obj.accountabilityEndDate === undefined) {
    return true;
  }

  var currentAccEndDate = Date.parse(obj.accountabilityEndDate);

  if (!isNaN(currentAccEndDate)) {
    return currentAccEndDate >= Date.now();
  }

  return false;
}

var InventoryView = function InventoryView(_ref) {
  var _filterRegistry$accEn;

  var fhirBaseUrl = _ref.fhirBaseUrl,
      locationId = _ref.locationId;

  var _useMls = (0, _mls.useMls)(),
      t = _useMls.t;

  var history = (0, _reactRouterDom.useHistory)();

  var _useTabularViewWithLo = (0, _reactUtils.useTabularViewWithLocalSearch)(fhirBaseUrl, _constants.listResourceType, {
    subject: locationId,
    _include: 'List:item',
    '_include:recurse': 'Group:member'
  }, dataTransformer, (0, _defineProperty2["default"])({}, _constants.accEndDateFilterKey, {
    value: 'active',
    filterFunc: function filterFunc(el) {
      return activeInventoryByAccEndDate(el);
    }
  })),
      _useTabularViewWithLo2 = _useTabularViewWithLo.queryValues,
      data = _useTabularViewWithLo2.data,
      isLoading = _useTabularViewWithLo2.isLoading,
      error = _useTabularViewWithLo2.error,
      tablePaginationProps = _useTabularViewWithLo.tablePaginationProps,
      rawSearchFormProps = _useTabularViewWithLo.searchFormProps,
      _useTabularViewWithLo3 = _useTabularViewWithLo.filterOptions,
      registerFilter = _useTabularViewWithLo3.registerFilter,
      deregisterFilter = _useTabularViewWithLo3.deregisterFilter,
      filterRegistry = _useTabularViewWithLo3.filterRegistry;

  if (error && !data.length) {
    return _react["default"].createElement(_antd.Alert, {
      type: "error"
    }, t('An error occurred while fetching inventory'));
  }

  var baseInventoryPath = "".concat(_fhirGroupManagement.ADD_LOCATION_INVENTORY, "/").concat(locationId);
  var columns = [{
    title: t('Product name'),
    dataIndex: 'productName',
    key: 'productName',
    sorter: function sorter(rec1, rec2) {
      if (rec1.productName && rec2.productName) {
        if (rec1.productName > rec2.productName) {
          return -1;
        }

        if (rec1.productName < rec2.productName) {
          return 1;
        }
      }

      return 0;
    },
    defaultSortOrder: 'descend'
  }, {
    title: t('Qty'),
    dataIndex: 'quantity',
    key: 'quantity'
  }, {
    title: t('PO no.'),
    dataIndex: 'poNumber',
    key: 'poNumber'
  }, {
    title: t('Serial no.'),
    dataIndex: 'serialNumber',
    key: 'serialNumber'
  }, {
    title: t('Delivery dt.'),
    dataIndex: 'deliveryDate',
    key: 'deliveryDate'
  }, {
    title: t('Acct. end dt.'),
    dataIndex: 'accountabilityEndDate',
    key: 'accountabilityEndDate'
  }, {
    title: t('Unicef section'),
    dataIndex: 'unicefSection',
    key: 'unicefSection'
  }, {
    title: t('Donor'),
    dataIndex: 'donor',
    key: 'donor'
  }, {
    title: t('Actions'),
    render: function render(_ref2) {
      var id = _ref2.id;
      return _react["default"].createElement("span", {
        className: "d-flex align-items-center"
      }, _react["default"].createElement(_rbac.RbacCheck, {
        permissions: ['Group.update']
      }, _react["default"].createElement(_react["default"].Fragment, null, _react["default"].createElement(_reactRouterDom.Link, {
        to: "".concat(baseInventoryPath, "/").concat(id),
        className: "m-0 p-1"
      }, t('Edit')), _react["default"].createElement(_antd.Divider, {
        type: "vertical"
      }))));
    },
    width: '20%'
  }];

  var searchFormProps = _objectSpread(_objectSpread({}, rawSearchFormProps), {}, {
    onChangeHandler: function onChangeHandler(event) {
      rawSearchFormProps.onChangeHandler(event);
      var searchText = event.target.value;

      if (searchText) {
        registerFilter(_constants.nameFilterKey, function (el) {
          return matchesSearch(el, searchText);
        }, searchText);
      } else {
        deregisterFilter(_constants.nameFilterKey);
      }
    }
  });

  var tableProps = {
    datasource: data,
    columns: columns,
    loading: isLoading,
    size: 'small',
    tablePaginationProps: tablePaginationProps
  };
  var activeValue = 'active';
  var inactiveValue = 'inactive';
  return _react["default"].createElement(_antd.Row, {
    "data-testid": "inventory-tab",
    className: "list-view"
  }, _react["default"].createElement(_antd.Col, {
    style: {
      width: '100%'
    }
  }, _react["default"].createElement("div", {
    className: "main-content__header"
  }, _react["default"].createElement(_antd.Space, {
    size: 'large'
  }, _react["default"].createElement(_reactUtils.SearchForm, (0, _extends2["default"])({
    "data-testid": "search-form"
  }, searchFormProps)), _react["default"].createElement(_antd.Space, null, _react["default"].createElement(_antd.Typography.Text, null, t('Accountability status:')), _react["default"].createElement(_antd.Radio.Group, {
    size: "small",
    value: (_filterRegistry$accEn = filterRegistry[_constants.accEndDateFilterKey]) === null || _filterRegistry$accEn === void 0 ? void 0 : _filterRegistry$accEn.value,
    buttonStyle: "solid",
    onChange: function onChange(event) {
      var val = event.target.value;

      switch (val) {
        case activeValue:
          registerFilter(_constants.accEndDateFilterKey, function (el) {
            return activeInventoryByAccEndDate(el);
          }, val);
          break;

        case inactiveValue:
          registerFilter(_constants.accEndDateFilterKey, function (el) {
            return !activeInventoryByAccEndDate(el);
          }, val);
          break;
      }
    }
  }, _react["default"].createElement(_antd.Radio.Button, {
    value: activeValue
  }, t('Active')), _react["default"].createElement(_antd.Radio.Button, {
    value: inactiveValue
  }, t('Inactive'))))), _react["default"].createElement(_rbac.RbacCheck, {
    permissions: ['Group.create']
  }, _react["default"].createElement(_antd.Button, {
    type: "primary",
    onClick: function onClick() {
      return history.push(baseInventoryPath);
    }
  }, _react["default"].createElement(_icons.PlusOutlined, null), t('Add Inventory')))), _react["default"].createElement(_reactUtils.TableLayout, tableProps)));
};

exports.InventoryView = InventoryView;