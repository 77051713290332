"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.BodyLayout = void 0;

var _react = _interopRequireDefault(require("react"));

var _RichPageHeader = require("../PageHeaders/RichPageHeader");

var BodyLayout = function BodyLayout(props) {
  var headerProps = props.headerProps,
      children = props.children;
  return _react["default"].createElement("div", {
    className: "details-full-view"
  }, _react["default"].createElement(_RichPageHeader.RichPageHeader, headerProps), _react["default"].createElement("div", {
    className: "content-body"
  }, children));
};

exports.BodyLayout = BodyLayout;