"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.LocationPathBreadCrumb = void 0;

var _antd = require("antd");

var _constants = require("../../../constants");

var _reactRouterDom = require("react-router-dom");

var _react = _interopRequireDefault(require("react"));

var LocationPathBreadCrumb = function LocationPathBreadCrumb(props) {
  var locationPath = props.locationPath;
  var breadCrumbItems = locationPath.map(function (location) {
    var name = location.name,
        id = location.id;
    return {
      title: name,
      path: "".concat(_constants.URL_LOCATION_VIEW_DETAILS, "/").concat(id)
    };
  });
  return _react["default"].createElement(_antd.Breadcrumb, {
    style: {
      marginLeft: '16px'
    },
    separator: ">",
    items: breadCrumbItems,
    itemRender: function itemRender(route, _, items, __) {
      var last = items.indexOf(route) === items.length - 1 || items.indexOf(route) === 0;
      return last ? _react["default"].createElement("span", null, route.title) : _react["default"].createElement(_reactRouterDom.Link, {
        to: route.path ? route.path : '#'
      }, route.title);
    }
  });
};

exports.LocationPathBreadCrumb = LocationPathBreadCrumb;