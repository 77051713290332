"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _CodeableConcept = require("./CodeableConcept");

Object.keys(_CodeableConcept).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _CodeableConcept[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _CodeableConcept[key];
    }
  });
});

var _Coding = require("./Coding");

Object.keys(_Coding).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _Coding[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _Coding[key];
    }
  });
});