"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PractitionerDetailsView = void 0;

var _toConsumableArray2 = _interopRequireDefault(require("@babel/runtime/helpers/toConsumableArray"));

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _react = _interopRequireDefault(require("react"));

var _mls = require("../../../../mls");

var _reactUtils = require("@opensrp/react-utils");

var _constants = require("../../../../constants");

var _antd = require("antd");

var _userManagement = require("@opensrp/user-management");

function _createForOfIteratorHelper(o, allowArrayLike) {
  var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"];

  if (!it) {
    if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") {
      if (it) o = it;
      var i = 0;

      var F = function F() {};

      return {
        s: F,
        n: function n() {
          if (i >= o.length) return {
            done: true
          };
          return {
            done: false,
            value: o[i++]
          };
        },
        e: function e(_e) {
          throw _e;
        },
        f: F
      };
    }

    throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
  }

  var normalCompletion = true,
      didErr = false,
      err;
  return {
    s: function s() {
      it = it.call(o);
    },
    n: function n() {
      var step = it.next();
      normalCompletion = step.done;
      return step;
    },
    e: function e(_e2) {
      didErr = true;
      err = _e2;
    },
    f: function f() {
      try {
        if (!normalCompletion && it["return"] != null) it["return"]();
      } finally {
        if (didErr) throw err;
      }
    }
  };
}

function _unsupportedIterableToArray(o, minLen) {
  if (!o) return;
  if (typeof o === "string") return _arrayLikeToArray(o, minLen);
  var n = Object.prototype.toString.call(o).slice(8, -1);
  if (n === "Object" && o.constructor) n = o.constructor.name;
  if (n === "Map" || n === "Set") return Array.from(o);
  if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen);
}

function _arrayLikeToArray(arr, len) {
  if (len == null || len > arr.length) len = arr.length;

  for (var i = 0, arr2 = new Array(len); i < len; i++) {
    arr2[i] = arr[i];
  }

  return arr2;
}

var PractitionerDetailsView = function PractitionerDetailsView(props) {
  var _practitionerDetails$, _practitionerDetails$2;

  var loading = props.loading,
      practitionerDetails = props.practitionerDetails,
      error = props.error;

  var _useTranslation = (0, _mls.useTranslation)(),
      t = _useTranslation.t;

  if (error) {
    return _react["default"].createElement(_antd.Alert, {
      type: "error"
    }, t('An error occurred while trying to fetch the practitioner details.'));
  }

  var practitioners = (_practitionerDetails$ = practitionerDetails.practitioner) !== null && _practitionerDetails$ !== void 0 ? _practitionerDetails$ : [];
  var practitionerRoles = (_practitionerDetails$2 = practitionerDetails.practitionerRoles) !== null && _practitionerDetails$2 !== void 0 ? _practitionerDetails$2 : [];
  var tableData = processPractitionerDetails(practitioners, practitionerRoles);
  var columns = [{
    title: t('Id'),
    dataIndex: 'id'
  }, {
    title: t('Name'),
    dataIndex: 'name'
  }, {
    title: t('Active'),
    dataIndex: 'active',
    render: function render(value) {
      return value === true ? 'Active' : 'Inactive';
    }
  }, {
    title: t('User Type'),
    dataIndex: 'userType'
  }, {
    title: t('Practitioner Role Coding'),
    dataIndex: 'concepts',
    render: function render(concepts) {
      return concepts.map(function (concept, index) {
        return _react["default"].createElement(_reactUtils.CodeableConcept, {
          key: index,
          concept: concept
        });
      });
    }
  }];
  var tableProps = {
    datasource: tableData,
    columns: columns,
    loading: loading,
    size: 'small'
  };
  return _react["default"].createElement(_reactUtils.TableLayout, tableProps);
};

exports.PractitionerDetailsView = PractitionerDetailsView;

function processPractitionerDetails(practitioners, practitionerRoles) {
  var tableData = {};
  var tempPractitionerRoleCodings = {};

  var _iterator = _createForOfIteratorHelper(practitioners),
      _step;

  try {
    for (_iterator.s(); !(_step = _iterator.n()).done;) {
      var _typedRes$name;

      var res = _step.value;
      var typedRes = res;
      var resName = (_typedRes$name = typedRes.name) === null || _typedRes$name === void 0 ? void 0 : _typedRes$name[0];
      tableData["".concat(_constants.practitionerResourceType, "/").concat(typedRes.id)] = {
        id: res.id,
        name: (0, _reactUtils.parseFhirHumanName)(resName),
        active: res.active,
        concepts: [],
        userType: undefined
      };
    }
  } catch (err) {
    _iterator.e(err);
  } finally {
    _iterator.f();
  }

  var _iterator2 = _createForOfIteratorHelper(practitionerRoles),
      _step2;

  try {
    for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
      var _typedRes$practitione, _typedRes$code;

      var _res = _step2.value;
      var _typedRes = _res;
      var practitionerId = (_typedRes$practitione = _typedRes.practitioner) === null || _typedRes$practitione === void 0 ? void 0 : _typedRes$practitione.reference;
      var concepts = (_typedRes$code = _typedRes.code) !== null && _typedRes$code !== void 0 ? _typedRes$code : [];
      var userType = undefined;
      var userTypeCode = (0, _userManagement.getUserTypeCode)(_res);

      if (userTypeCode) {
        userType = (0, _userManagement.getUserType)(userTypeCode);
      }

      if (tableData[practitionerId] === undefined) {
        tableData[practitionerId].concepts = [].concat((0, _toConsumableArray2["default"])(tableData[practitionerId].concepts), (0, _toConsumableArray2["default"])(concepts));
        tableData[practitionerId].userType = userType;
      } else if (tempPractitionerRoleCodings[practitionerId] === undefined) {
        tempPractitionerRoleCodings[practitionerId] = {
          concepts: []
        };
        tempPractitionerRoleCodings[practitionerId].concepts = [];
        tempPractitionerRoleCodings[practitionerId].userType = userType;
      }

      tempPractitionerRoleCodings[practitionerId].concepts = [].concat((0, _toConsumableArray2["default"])(tempPractitionerRoleCodings[practitionerId].concepts), (0, _toConsumableArray2["default"])(concepts));
    }
  } catch (err) {
    _iterator2.e(err);
  } finally {
    _iterator2.f();
  }

  for (var _i = 0, _Object$entries = Object.entries(tempPractitionerRoleCodings); _i < _Object$entries.length; _i++) {
    var _Object$entries$_i = (0, _slicedToArray2["default"])(_Object$entries[_i], 2),
        key = _Object$entries$_i[0],
        value = _Object$entries$_i[1];

    tableData[key].concepts = [].concat((0, _toConsumableArray2["default"])(tableData[key].concepts), (0, _toConsumableArray2["default"])(value.concepts));
    tableData[key].userType = value.userType;
  }

  return Object.values(tableData);
}