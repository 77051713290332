"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ImportDetailViewDetails = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _react = _interopRequireDefault(require("react"));

var _antd = require("antd");

var _reactQuery = require("react-query");

var _reactUtils = require("@opensrp/react-utils");

var _constants = require("../../constants");

var _mls = require("../../mls");

var _reactHelmet = require("react-helmet");

var _reactRouter = require("react-router");

var _utils = require("../../helpers/utils");

var _statusTag = require("../../components/statusTag");

require("./index.css");

var ImportDetailViewDetails = function ImportDetailViewDetails(_) {
  var _otherDetailsMap, _data$statusReason, _data$statusReason2;

  var params = (0, _reactRouter.useParams)();
  var workflowId = params.workflowId;

  var _useTranslation = (0, _mls.useTranslation)(),
      t = _useTranslation.t;

  var _useQuery = (0, _reactQuery.useQuery)([_constants.dataImportRQueryKey, workflowId], function () {
    var service = new _reactUtils.OpenSRPService("/$import", _constants.IMPORT_DOMAIN_URI);
    return service.read(workflowId).then(function (res) {
      return res;
    });
  }, {
    enabled: !!workflowId
  }),
      data = _useQuery.data,
      isLoading = _useQuery.isLoading,
      error = _useQuery.error;

  if (isLoading) {
    return _react["default"].createElement(_antd.Spin, {
      size: "large",
      className: "custom-spinner"
    });
  }

  if (error && !data) {
    return _react["default"].createElement(_reactUtils.BrokenPage, {
      errorMessage: "An Error occurred when fetching this workflow"
    });
  }

  if (!data) {
    return _react["default"].createElement(_reactUtils.Resource404, null);
  }

  var pageTitle = t("View details | {{workflowId}}", {
    workflowId: data.workflowId
  });
  var headerProps = {
    pageHeaderProps: {
      title: pageTitle,
      onBack: undefined
    }
  };
  var dateCreatedKeyPairing = (0, _defineProperty2["default"])({}, t('Date Created'), (0, _utils.formatTimestamp)(data.dateCreated));
  var headerLeftData = {
    ID: data.workflowId
  };
  var otherDetailsMap = (_otherDetailsMap = {}, (0, _defineProperty2["default"])(_otherDetailsMap, t('Workflow type'), data.workflowType), (0, _defineProperty2["default"])(_otherDetailsMap, t('Date Started'), (0, _utils.formatTimestamp)(data.dateStarted)), (0, _defineProperty2["default"])(_otherDetailsMap, t('Date Ended'), (0, _utils.formatTimestamp)(data.dateEnded)), (0, _defineProperty2["default"])(_otherDetailsMap, t('Author'), data.author), _otherDetailsMap);
  return _react["default"].createElement(_reactUtils.BodyLayout, {
    headerProps: headerProps
  }, _react["default"].createElement(_reactHelmet.Helmet, null, _react["default"].createElement("title", null, pageTitle, " ")), _react["default"].createElement("div", {
    className: "view-details-container"
  }, _react["default"].createElement(_reactUtils.ResourceDetails, {
    title: data.workflowType,
    headerLeftData: headerLeftData,
    headerRightData: dateCreatedKeyPairing,
    status: {
      title: data.status,
      color: (0, _statusTag.getStatusColor)(data.status)
    },
    bodyData: function bodyData() {
      return _react["default"].createElement(_reactUtils.KeyValuesDescriptions, {
        data: otherDetailsMap,
        column: 2
      });
    }
  }), _react["default"].createElement(_antd.Tabs, {
    "data-testid": "details-tab",
    style: {
      width: '100%'
    },
    size: 'small',
    items: [{
      label: t('Log Output'),
      key: 'logOutput',
      children: _react["default"].createElement("div", {
        className: "terminal-output"
      }, _react["default"].createElement("pre", null, (_data$statusReason = data.statusReason) === null || _data$statusReason === void 0 ? void 0 : _data$statusReason.stdout, (_data$statusReason2 = data.statusReason) === null || _data$statusReason2 === void 0 ? void 0 : _data$statusReason2.stderr))
    }]
  })));
};

exports.ImportDetailViewDetails = ImportDetailViewDetails;