"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.EditLink = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _react = _interopRequireDefault(require("react"));

var _reactRouterDom = require("react-router-dom");

var _constants = require("../../constants");

var EditLink = function EditLink(props) {
  var _physicalType$coding;

  var location = props.location,
      editLinkText = props.editLinkText;
  var id = location.id,
      physicalType = location.physicalType;
  var isBuilding = (physicalType === null || physicalType === void 0 ? void 0 : (_physicalType$coding = physicalType.coding) === null || _physicalType$coding === void 0 ? void 0 : _physicalType$coding[0].code) === 'bu';
  var currentLocation = (0, _reactRouterDom.useLocation)();
  var backToParam = new URLSearchParams((0, _defineProperty2["default"])({}, _constants.BACK_SEARCH_PARAM, currentLocation.pathname));
  return _react["default"].createElement(_reactRouterDom.Link, {
    "data-testid": "edit-child-location",
    to: isBuilding ? "".concat(_constants.URL_SERVICE_POINT_ADD_EDIT, "/").concat(id, "?").concat(backToParam) : "".concat(_constants.URL_LOCATION_UNIT_EDIT, "/").concat(id, "?").concat(backToParam),
    className: "m-0 p-1"
  }, editLinkText);
};

exports.EditLink = EditLink;