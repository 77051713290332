"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.conceptsHaveCodings = conceptsHaveCodings;
exports.getAdministrativeLevelTypeCoding = getAdministrativeLevelTypeCoding;
exports.getCharacteristicWithCode = getCharacteristicWithCode;
exports.getCharacteristicWithCoding = getCharacteristicWithCoding;
exports.getLocationAdmLevel = getLocationAdmLevel;
exports.getLocationAdmLevelCoding = getLocationAdmLevelCoding;

var _codeSystems = require("../constants/codeSystems");

function _createForOfIteratorHelper(o, allowArrayLike) {
  var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"];

  if (!it) {
    if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") {
      if (it) o = it;
      var i = 0;

      var F = function F() {};

      return {
        s: F,
        n: function n() {
          if (i >= o.length) return {
            done: true
          };
          return {
            done: false,
            value: o[i++]
          };
        },
        e: function e(_e) {
          throw _e;
        },
        f: F
      };
    }

    throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
  }

  var normalCompletion = true,
      didErr = false,
      err;
  return {
    s: function s() {
      it = it.call(o);
    },
    n: function n() {
      var step = it.next();
      normalCompletion = step.done;
      return step;
    },
    e: function e(_e2) {
      didErr = true;
      err = _e2;
    },
    f: function f() {
      try {
        if (!normalCompletion && it["return"] != null) it["return"]();
      } finally {
        if (didErr) throw err;
      }
    }
  };
}

function _unsupportedIterableToArray(o, minLen) {
  if (!o) return;
  if (typeof o === "string") return _arrayLikeToArray(o, minLen);
  var n = Object.prototype.toString.call(o).slice(8, -1);
  if (n === "Object" && o.constructor) n = o.constructor.name;
  if (n === "Map" || n === "Set") return Array.from(o);
  if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen);
}

function _arrayLikeToArray(arr, len) {
  if (len == null || len > arr.length) len = arr.length;

  for (var i = 0, arr2 = new Array(len); i < len; i++) {
    arr2[i] = arr[i];
  }

  return arr2;
}

function getCharacteristicWithCoding(characteristics, coding) {
  return getCharacteristicWithCode(characteristics, coding.system, coding.code)[0];
}

function codingMatchesCode(coding, system, code) {
  var thisCodingSystem = coding.system;
  var thisCodingCode = coding.code;

  if (system && code) {
    if (system && thisCodingSystem === system && code && thisCodingCode === code) {
      return true;
    }
  } else {
    if (system && thisCodingSystem === system || code && thisCodingCode === code) {
      return true;
    }
  }

  return false;
}

function getCharacteristicWithCode(characteristics, system, code) {
  if (!system && !code) {
    return characteristics;
  }

  var matchedCharacteristics = [];

  var _iterator = _createForOfIteratorHelper(characteristics),
      _step;

  try {
    for (_iterator.s(); !(_step = _iterator.n()).done;) {
      var _characteristic$code$;

      var characteristic = _step.value;
      var codings = (_characteristic$code$ = characteristic.code.coding) !== null && _characteristic$code$ !== void 0 ? _characteristic$code$ : [];

      var _iterator2 = _createForOfIteratorHelper(codings),
          _step2;

      try {
        for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
          var thisCoding = _step2.value;

          if (codingMatchesCode(thisCoding, system, code)) {
            matchedCharacteristics.push(characteristic);
          }
        }
      } catch (err) {
        _iterator2.e(err);
      } finally {
        _iterator2.f();
      }
    }
  } catch (err) {
    _iterator.e(err);
  } finally {
    _iterator.f();
  }

  return matchedCharacteristics;
}

function getAdministrativeLevelTypeCoding(admLevel) {
  return {
    system: _codeSystems.administrativeLevelSystemUri,
    code: "".concat(admLevel),
    display: "Level ".concat(admLevel)
  };
}

function getLocationAdmLevelCoding(type) {
  var _type$flatMap;

  var typeCodingFlatMap = (_type$flatMap = type === null || type === void 0 ? void 0 : type.flatMap(function (concept) {
    return concept.coding;
  })) !== null && _type$flatMap !== void 0 ? _type$flatMap : [];
  var admLevelCoding = typeCodingFlatMap.filter(function (coding) {
    return (coding === null || coding === void 0 ? void 0 : coding.system) === _codeSystems.administrativeLevelSystemUri;
  });
  return admLevelCoding[0];
}

function getLocationAdmLevel(type) {
  var coding = getLocationAdmLevelCoding(type);
  return coding === null || coding === void 0 ? void 0 : coding.code;
}

function conceptsHaveCodings() {
  var concepts = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  var codings = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];

  if (codings.length === 0) {
    return true;
  }

  if (concepts.length === 0) {
    return false;
  }

  var conceptCodingsBySystemAndCoding = concepts.reduce(function (acc, concept) {
    var _concept$coding;

    var _iterator3 = _createForOfIteratorHelper((_concept$coding = concept.coding) !== null && _concept$coding !== void 0 ? _concept$coding : []),
        _step3;

    try {
      for (_iterator3.s(); !(_step3 = _iterator3.n()).done;) {
        var coding = _step3.value;
        var systemKey = "".concat(coding.system);
        var codeKey = "".concat(coding.code);

        if (acc[systemKey] === undefined) {
          acc[systemKey] = {};
        }

        acc[systemKey][codeKey] = coding;
        return acc;
      }
    } catch (err) {
      _iterator3.e(err);
    } finally {
      _iterator3.f();
    }

    return acc;
  }, {});

  var _iterator4 = _createForOfIteratorHelper(codings),
      _step4;

  try {
    for (_iterator4.s(); !(_step4 = _iterator4.n()).done;) {
      var _conceptCodingsBySyst;

      var coding = _step4.value;
      var codingSystemCheck = "".concat(coding.system);
      var codingCodeCheck = "".concat(coding.code);

      if ((_conceptCodingsBySyst = conceptCodingsBySystemAndCoding[codingSystemCheck]) !== null && _conceptCodingsBySyst !== void 0 && _conceptCodingsBySyst[codingCodeCheck]) {
        return true;
      }
    }
  } catch (err) {
    _iterator4.e(err);
  } finally {
    _iterator4.f();
  }

  return false;
}