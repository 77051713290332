"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.UserDetails = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _react = _interopRequireDefault(require("react"));

var _antd = require("antd");

var _mls = require("../../../mls");

var _reactRouter = require("react-router");

var _reactUtils = require("@opensrp/react-utils");

var _userManagement = require("@opensrp/user-management");

var _keycloakService = require("@opensrp/keycloak-service");

var _reactQuery = require("react-query");

var _GroupDetailView = require("./ViewDetailResources/GroupDetailView");

var _PractitionerDetails = require("./ViewDetailResources/PractitionerDetails");

var _CareTeamDetails = require("./ViewDetailResources/CareTeamDetails");

var _OrganizationDetailsView = require("./ViewDetailResources/OrganizationDetailsView");

var _constants = require("../../../constants");

require("./index.css");

var _UserDeleteBtn = require("../../UserDeleteBtn");

var _RoleDetailView = require("./ViewDetailResources/RoleDetailView");

var _rbac = require("@opensrp/rbac");

var _reactHelmet = require("react-helmet");

var _reactRouterDom = require("react-router-dom");

function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);

  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);
    enumerableOnly && (symbols = symbols.filter(function (sym) {
      return Object.getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }

  return keys;
}

function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      (0, _defineProperty2["default"])(target, key, source[key]);
    }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
    });
  }

  return target;
}

var UserDetails = function UserDetails(props) {
  var _practitionerDetails$, _objectSpread2, _dateCreated;

  var keycloakBaseUrl = props.keycloakBaseURL,
      fhirBaseUrl = props.fhirBaseURL;
  var params = (0, _reactRouter.useParams)();
  var resourceId = params.id;

  var _useTranslation = (0, _mls.useTranslation)(),
      t = _useTranslation.t;

  var history = (0, _reactRouter.useHistory)();
  var userRole = (0, _rbac.useUserRole)();
  var hasPractitionerRead = userRole.hasPermissions(['PractitionerDetail.read']);
  var hasGroupRead = userRole.hasPermissions(['iam_group.read']);

  var userDeleteAfterAction = function userDeleteAfterAction() {
    history.push(_userManagement.URL_USER);
  };

  var _useQuery = (0, _reactQuery.useQuery)([_userManagement.KEYCLOAK_URL_USERS, resourceId], function () {
    return new _keycloakService.KeycloakService("".concat(_userManagement.KEYCLOAK_URL_USERS), keycloakBaseUrl).read(resourceId);
  }),
      user = _useQuery.data,
      userIsLoading = _useQuery.isLoading,
      userError = _useQuery.error;

  var extraQueryFilters = {
    'keycloak-uuid': resourceId
  };

  var _useQuery2 = (0, _reactQuery.useQuery)([_constants.practitionerDetailsResourceType, resourceId], function () {
    return new _reactUtils.FHIRServiceClass(fhirBaseUrl, _constants.practitionerDetailsResourceType).list(extraQueryFilters);
  }, {
    select: function select(res) {
      return (0, _reactUtils.getResourcesFromBundle)(res)[0];
    }
  }),
      practitionerDetails = _useQuery2.data,
      detailsLoading = _useQuery2.isLoading,
      detailsError = _useQuery2.error;

  var practDetailsByResName = (_practitionerDetails$ = practitionerDetails === null || practitionerDetails === void 0 ? void 0 : practitionerDetails.fhir) !== null && _practitionerDetails$ !== void 0 ? _practitionerDetails$ : {};

  if (userIsLoading) {
    return _react["default"].createElement(_antd.Spin, {
      className: "custom-spinner"
    });
  }

  if (userError && !user) {
    return _react["default"].createElement(_reactUtils.BrokenPage, {
      errorMessage: t('An error occurred when fetching the user details.')
    });
  }

  if (user === undefined) {
    return _react["default"].createElement(_reactUtils.Resource404, {
      title: t('Could not find the user.')
    });
  }

  var id = user.id,
      firstName = user.firstName,
      lastName = user.lastName,
      username = user.username,
      email = user.email,
      emailVerified = user.emailVerified,
      enabled = user.enabled,
      attributes = user.attributes,
      createdTimestamp = user.createdTimestamp;
  var fhirCoreAppId = attributes === null || attributes === void 0 ? void 0 : attributes.fhir_core_app_id;
  var breadCrumbProps = {
    items: [{
      title: t('Users'),
      path: _userManagement.URL_USER
    }, {
      title: t('View details')
    }]
  };
  var pageTitle = t("View details | {{userName}}", {
    userName: user.username
  });
  var pageHeaderProps = {
    title: pageTitle
  };
  var richPageHeaderProps = {
    pageHeaderProps: pageHeaderProps,
    breadCrumbProps: breadCrumbProps
  };
  var dateCreated = createdTimestamp ? new Date(createdTimestamp) : undefined;

  if (!(0, _reactUtils.isValidDate)(dateCreated)) {
    dateCreated = undefined;
  }

  var otherDetailsMap = _objectSpread(_objectSpread(_objectSpread((_objectSpread2 = {}, (0, _defineProperty2["default"])(_objectSpread2, t('First Name'), firstName), (0, _defineProperty2["default"])(_objectSpread2, t('Last Name'), lastName), (0, _defineProperty2["default"])(_objectSpread2, t('Username'), username), (0, _defineProperty2["default"])(_objectSpread2, t('Email'), email), _objectSpread2), attributes !== null && attributes !== void 0 && attributes.nationalId ? (0, _defineProperty2["default"])({}, t('National ID'), attributes.nationalId) : {}), attributes !== null && attributes !== void 0 && attributes.phoneNumber ? (0, _defineProperty2["default"])({}, t('Phone Number'), attributes.phoneNumber) : {}), {}, (0, _defineProperty2["default"])({}, t('FHIR Core App Id'), fhirCoreAppId));

  var dateCreatedKeyPairing = (0, _defineProperty2["default"])({}, t('Date Created'), (_dateCreated = dateCreated) === null || _dateCreated === void 0 ? void 0 : _dateCreated.toLocaleString());
  var headerLeftData = (0, _defineProperty2["default"])({
    ID: id
  }, t('Verified'), emailVerified ? t('True') : t('False'));
  return _react["default"].createElement(_reactUtils.BodyLayout, {
    headerProps: richPageHeaderProps
  }, _react["default"].createElement(_reactHelmet.Helmet, null, _react["default"].createElement("title", null, pageTitle, " ")), _react["default"].createElement("div", {
    "data-testid": "user-profile",
    style: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      padding: '16px',
      gap: '14px',
      background: '#FFF',
      borderRadius: '12px'
    }
  }, _react["default"].createElement(_reactUtils.ResourceDetails, {
    column: 2,
    title: user.username,
    headerLeftData: headerLeftData,
    headerRightData: dateCreatedKeyPairing,
    status: {
      title: enabled ? t('Enabled') : t('Disabled'),
      color: enabled ? 'green' : 'red'
    },
    headerActions: _react["default"].createElement(_react["default"].Fragment, null, _react["default"].createElement(_rbac.RbacCheck, {
      permissions: ['iam_user.delete', 'Practitioner.delete'],
      key: "user-delete-btn"
    }, _react["default"].createElement(_UserDeleteBtn.UserDeleteBtn, {
      fhirBaseUrl: fhirBaseUrl,
      keycloakBaseUrl: keycloakBaseUrl,
      resourceId: resourceId,
      afterActions: userDeleteAfterAction
    })), _react["default"].createElement(_rbac.RbacCheck, {
      permissions: ['iam_user.update', 'Practitioner.update'],
      key: "edit-user"
    }, _react["default"].createElement(_reactRouterDom.Link, {
      to: "".concat(_userManagement.URL_USER_EDIT, "/").concat(resourceId)
    }, t('Edit')))),
    bodyData: otherDetailsMap
  }), _react["default"].createElement("div", {
    className: "details-tab"
  }, _react["default"].createElement(_antd.Tabs, {
    defaultActiveKey: "1",
    size: 'small',
    items: [{
      label: t('User groups'),
      key: 'Groups',
      children: _react["default"].createElement(_GroupDetailView.KeycloakGroupDetails, {
        keycloakBaseUrl: keycloakBaseUrl,
        resourceId: resourceId
      }),
      disabled: !hasGroupRead
    }, {
      label: t('User roles'),
      key: 'Roles',
      children: _react["default"].createElement(_RoleDetailView.KeycloakRoleDetails, {
        keycloakBaseUrl: keycloakBaseUrl,
        resourceId: resourceId
      })
    }, {
      label: t('Practitioners'),
      key: 'Practitioners',
      children: _react["default"].createElement(_PractitionerDetails.PractitionerDetailsView, {
        loading: detailsLoading,
        practitionerDetails: practDetailsByResName,
        error: detailsError
      }),
      disabled: !hasPractitionerRead
    }, {
      label: t('CareTeams'),
      key: 'CareTeams',
      children: _react["default"].createElement(_CareTeamDetails.CareTeamDetailsView, {
        loading: detailsLoading,
        practitionerDetails: practDetailsByResName,
        error: detailsError
      }),
      disabled: !hasPractitionerRead
    }, {
      label: t('Organizations'),
      key: 'Organizations',
      children: _react["default"].createElement(_OrganizationDetailsView.OrganizationDetailsView, {
        loading: detailsLoading,
        practitionerDetails: practDetailsByResName,
        error: detailsError
      }),
      disabled: !hasPractitionerRead
    }]
  }))));
};

exports.UserDetails = UserDetails;