"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.parseTaskList = exports.parseTask = exports.columns = void 0;
exports.taskDetailsProps = taskDetailsProps;
exports.taskSideViewData = exports.taskSearchParams = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _react = _interopRequireDefault(require("react"));

var _lodash = require("lodash");

var _utils = require("../../../helpers/utils");

var _reactUtils = require("@opensrp/react-utils");

var parseTask = function parseTask(obj) {
  return {
    status: (0, _lodash.get)(obj, 'status'),
    description: (0, _lodash.get)(obj, 'description'),
    executionPeriod: (0, _lodash.get)(obj, 'executionPeriod'),
    authoredOn: (0, _lodash.get)(obj, 'authoredOn'),
    intent: (0, _lodash.get)(obj, 'intent'),
    code: (0, _lodash.get)(obj, 'code'),
    codeableCode: (0, _utils.getCodeableConcepts)((0, _lodash.get)(obj, 'code')),
    reasonCode: (0, _utils.getCodeableConcepts)((0, _lodash.get)(obj, 'reasonCode')),
    businessStatus: (0, _utils.getCodeableConcepts)((0, _lodash.get)(obj, 'businessStatus')),
    basedOn: (0, _lodash.get)(obj, 'basedOn'),
    priority: (0, _lodash.get)(obj, 'priority'),
    id: (0, _lodash.get)(obj, 'id')
  };
};

exports.parseTask = parseTask;

var parseTaskList = function parseTaskList(list) {
  return list.map(parseTask);
};

exports.parseTaskList = parseTaskList;

var columns = function columns(t) {
  return [{
    title: t('Task'),
    dataIndex: 'codeableCode',
    render: function render(value, tableData) {
      var _tableData$code;

      if (value.length > 0) {
        return _react["default"].createElement(_utils.FhirCodesTooltips, {
          codings: value
        });
      }

      return (_tableData$code = tableData.code) === null || _tableData$code === void 0 ? void 0 : _tableData$code.text;
    }
  }, {
    title: t('Period'),
    dataIndex: 'executionPeriod',
    render: function render(value) {
      return _react["default"].createElement(_utils.FhirPeriod, value);
    }
  }, {
    title: t('Description'),
    dataIndex: 'description'
  }];
};

exports.columns = columns;

var taskSearchParams = function taskSearchParams(patientId) {
  return {
    patient: patientId
  };
};

exports.taskSearchParams = taskSearchParams;

var getTitle = function getTitle(codeableCode, code) {
  if (codeableCode.length > 0) {
    return _react["default"].createElement(_utils.FhirCodesTooltips, {
      codings: codeableCode
    });
  }

  return code === null || code === void 0 ? void 0 : code.text;
};

var taskSideViewData = function taskSideViewData(resource, t) {
  var _bodyData;

  var _parseTask = parseTask(resource),
      id = _parseTask.id,
      status = _parseTask.status,
      intent = _parseTask.intent,
      executionPeriod = _parseTask.executionPeriod,
      priority = _parseTask.priority,
      code = _parseTask.code,
      codeableCode = _parseTask.codeableCode;

  var headerLeftData = (0, _defineProperty2["default"])({}, t('ID'), id);
  var bodyData = (_bodyData = {}, (0, _defineProperty2["default"])(_bodyData, t('Period'), _react["default"].createElement(_utils.FhirPeriod, executionPeriod)), (0, _defineProperty2["default"])(_bodyData, t('Priority'), priority), (0, _defineProperty2["default"])(_bodyData, t('Status'), status), (0, _defineProperty2["default"])(_bodyData, t('Intent'), intent), _bodyData);
  return {
    title: getTitle(codeableCode, code),
    headerLeftData: headerLeftData,
    bodyData: bodyData,
    status: {
      title: status !== null && status !== void 0 ? status : '',
      color: 'green'
    }
  };
};

exports.taskSideViewData = taskSideViewData;

function taskDetailsProps(resource, t) {
  var _bodyData2;

  var _parseTask2 = parseTask(resource),
      id = _parseTask2.id,
      status = _parseTask2.status,
      description = _parseTask2.description,
      intent = _parseTask2.intent,
      executionPeriod = _parseTask2.executionPeriod,
      priority = _parseTask2.priority,
      businessStatus = _parseTask2.businessStatus,
      authoredOn = _parseTask2.authoredOn,
      reasonCode = _parseTask2.reasonCode,
      code = _parseTask2.code,
      codeableCode = _parseTask2.codeableCode;

  var bodyData = (_bodyData2 = {}, (0, _defineProperty2["default"])(_bodyData2, t('Period'), _react["default"].createElement(_utils.FhirPeriod, executionPeriod)), (0, _defineProperty2["default"])(_bodyData2, t('Priority'), priority), (0, _defineProperty2["default"])(_bodyData2, t('Status'), status), (0, _defineProperty2["default"])(_bodyData2, t('Business status'), _react["default"].createElement(_utils.FhirCodesTooltips, {
    codings: businessStatus
  })), (0, _defineProperty2["default"])(_bodyData2, t('Intent'), intent), (0, _defineProperty2["default"])(_bodyData2, t('reason'), _react["default"].createElement(_utils.FhirCodesTooltips, {
    codings: reasonCode
  })), (0, _defineProperty2["default"])(_bodyData2, t('Description'), description), _bodyData2);
  return {
    title: getTitle(codeableCode, code),
    headerRightData: (0, _defineProperty2["default"])({}, t('Date created'), (0, _reactUtils.dateToLocaleString)(authoredOn)),
    headerLeftData: (0, _defineProperty2["default"])({}, t('Id'), id),
    bodyData: bodyData,
    status: {
      title: status,
      color: 'green'
    }
  };
}