"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.LocationGridFilterRowRender = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _antd = require("antd");

var _react = _interopRequireDefault(require("react"));

var _reactUtils = require("@opensrp/react-utils");

var _fhirHelpers = require("@opensrp/fhir-helpers");

var _mls = require(".././../mls");

var _i18n = require("@opensrp/i18n");

var partOfFilterDataIdx = 'partof';
var statusFilterDataIdx = 'status';

var LocationGridFilterRowRender = function LocationGridFilterRowRender(props) {
  var _currentFilters$statu, _currentFilters$statu2;

  var _useMls = (0, _mls.useMls)(),
      t = _useMls.t;

  var fhirBaseUrl = props.fhirBaseUrl,
      updateFilterParams = props.updateFilterParams,
      currentFilters = props.currentFilters,
      showParentLocationFilter = props.showParentLocationFilter;
  return _react["default"].createElement("div", {
    className: "filter-row",
    "data-testid": "filter-row"
  }, _react["default"].createElement(_antd.Space, null, showParentLocationFilter && _react["default"].createElement(_i18n.Trans, {
    t: t,
    i18nKey: "parentLocationFilter"
  }, _react["default"].createElement(_antd.Space, null, "Parent Location:", _react["default"].createElement(_reactUtils.PaginatedAsyncSelect, {
    allowClear: true,
    showSearch: true,
    resourceType: _fhirHelpers.locationResourceType,
    baseUrl: fhirBaseUrl,
    transformOption: function transformOption(resource) {
      return {
        value: resource.id,
        label: resource.name,
        ref: resource
      };
    },
    onChange: function onChange(value) {
      if (!value) {
        updateFilterParams((0, _defineProperty2["default"])({}, partOfFilterDataIdx, undefined));
      } else {
        updateFilterParams((0, _defineProperty2["default"])({}, partOfFilterDataIdx, {
          paramAccessor: 'partof',
          rawValue: value,
          paramValue: value
        }));
      }
    }
  }))), _react["default"].createElement(_i18n.Trans, {
    t: t,
    i18nKey: "locationStatusFilter"
  }, _react["default"].createElement(_antd.Space, null, "Status:", _react["default"].createElement(_antd.Select, {
    id: "location-status-filter",
    value: (_currentFilters$statu = (_currentFilters$statu2 = currentFilters['status']) === null || _currentFilters$statu2 === void 0 ? void 0 : _currentFilters$statu2.rawValue) !== null && _currentFilters$statu !== void 0 ? _currentFilters$statu : '*',
    style: {
      width: 120
    },
    onChange: function onChange(value) {
      if (value === '*') {
        updateFilterParams((0, _defineProperty2["default"])({}, statusFilterDataIdx, undefined));
        return;
      }

      updateFilterParams((0, _defineProperty2["default"])({}, statusFilterDataIdx, {
        paramAccessor: 'status',
        rawValue: value,
        paramValue: value
      }));
    },
    options: [{
      value: 'active',
      label: t('Active')
    }, {
      value: 'inactive',
      label: t('Inactive')
    }, {
      value: '*',
      label: t('Show all')
    }]
  })))));
};

exports.LocationGridFilterRowRender = LocationGridFilterRowRender;