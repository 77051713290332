"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

var _typeof = require("@babel/runtime/helpers/typeof");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.AddLocationInventory = void 0;

var _regenerator = _interopRequireDefault(require("@babel/runtime/regenerator"));

var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));

var _react = _interopRequireWildcard(require("react"));

var _mls = require("../../mls");

var _reactHelmet = require("react-helmet");

var _reactUtils = require("@opensrp/react-utils");

var _form = require("./form");

var _reactRouter = require("react-router");

var _constants = require("../../constants");

var _reactQuery = require("react-query");

var _utils = require("./utils");

var _antd = require("antd");

function _getRequireWildcardCache(nodeInterop) {
  if (typeof WeakMap !== "function") return null;
  var cacheBabelInterop = new WeakMap();
  var cacheNodeInterop = new WeakMap();
  return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) {
    return nodeInterop ? cacheNodeInterop : cacheBabelInterop;
  })(nodeInterop);
}

function _interopRequireWildcard(obj, nodeInterop) {
  if (!nodeInterop && obj && obj.__esModule) {
    return obj;
  }

  if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") {
    return {
      "default": obj
    };
  }

  var cache = _getRequireWildcardCache(nodeInterop);

  if (cache && cache.has(obj)) {
    return cache.get(obj);
  }

  var newObj = {};
  var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor;

  for (var key in obj) {
    if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) {
      var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null;

      if (desc && (desc.get || desc.set)) {
        Object.defineProperty(newObj, key, desc);
      } else {
        newObj[key] = obj[key];
      }
    }
  }

  newObj["default"] = obj;

  if (cache) {
    cache.set(obj, newObj);
  }

  return newObj;
}

var AddLocationInventory = function AddLocationInventory(props) {
  var fhirBaseURL = props.fhirBaseURL,
      listId = props.listId,
      commodityListId = props.commodityListId;

  var _useTranslation = (0, _mls.useTranslation)(),
      t = _useTranslation.t;

  var _useParams = (0, _reactRouter.useParams)(),
      inventoryId = _useParams.inventoryId,
      servicePointId = _useParams.servicePointId;

  var pageTitle = inventoryId ? t('Edit Service Point Inventory') : t('Add Service Point Inventory');
  var inventoryResource = (0, _reactQuery.useQuery)([fhirBaseURL, inventoryId], (0, _asyncToGenerator2["default"])(_regenerator["default"].mark(function _callee() {
    return _regenerator["default"].wrap(function _callee$(_context) {
      while (1) {
        switch (_context.prev = _context.next) {
          case 0:
            _context.next = 2;
            return new _reactUtils.FHIRServiceClass(fhirBaseURL, _constants.groupResourceType).read(inventoryId);

          case 2:
            return _context.abrupt("return", _context.sent);

          case 3:
          case "end":
            return _context.stop();
        }
      }
    }, _callee);
  })), {
    enabled: !!inventoryId
  });
  var servicePoint = (0, _reactQuery.useQuery)([fhirBaseURL, servicePointId], (0, _asyncToGenerator2["default"])(_regenerator["default"].mark(function _callee2() {
    return _regenerator["default"].wrap(function _callee2$(_context2) {
      while (1) {
        switch (_context2.prev = _context2.next) {
          case 0:
            _context2.next = 2;
            return new _reactUtils.FHIRServiceClass(fhirBaseURL, _constants.locationResourceType).read(servicePointId);

          case 2:
            return _context2.abrupt("return", _context2.sent);

          case 3:
          case "end":
            return _context2.stop();
        }
      }
    }, _callee2);
  })));
  var error = inventoryResource.error || servicePoint.error;
  var isLoading = inventoryResource.isLoading || servicePoint.isLoading;
  var initialValues = (0, _react.useMemo)(function () {
    return inventoryResource.data ? (0, _utils.getInventoryInitialValues)(inventoryResource.data) : {};
  }, [inventoryResource.data]);

  if (inventoryResource.error && !inventoryResource.data || servicePoint.error && !servicePoint.data) {
    return _react["default"].createElement(_reactUtils.BrokenPage, {
      errorMessage: error.message
    });
  }

  var formProps = {
    fhirBaseURL: fhirBaseURL,
    listResourceId: listId,
    inventoryId: inventoryId,
    initialValues: initialValues,
    inventoryResourceObj: inventoryResource.data,
    servicePointObj: servicePoint.data,
    commodityListId: commodityListId
  };
  var headerProps = {
    pageHeaderProps: {
      title: pageTitle,
      onBack: undefined
    }
  };
  return _react["default"].createElement(_reactUtils.BodyLayout, {
    headerProps: headerProps
  }, _react["default"].createElement(_reactHelmet.Helmet, null, _react["default"].createElement("title", null, pageTitle)), _react["default"].createElement("div", {
    className: "bg-white p-5"
  }, isLoading ? _react["default"].createElement(_antd.Spin, {
    size: "large",
    className: "custom-spinner"
  }) : _react["default"].createElement(_form.AddLocationInventoryForm, formProps)));
};

exports.AddLocationInventory = AddLocationInventory;