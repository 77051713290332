"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

var _typeof = require("@babel/runtime/helpers/typeof");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.AddLocationInventoryForm = void 0;

var _regenerator = _interopRequireDefault(require("@babel/runtime/regenerator"));

var _extends2 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));

var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _react = _interopRequireWildcard(require("react"));

var _antd = require("antd");

var _reactUtils = require("@opensrp/react-utils");

var _mls = require("../../mls");

var _reactQuery = require("react-query");

var _utils = require("../../helpers/utils");

var _notifications = require("@opensrp/notifications");

var _constants = require("../../constants");

var _utils2 = require("./utils");

var _reactRouter = require("react-router");

var _fhirHelpers = require("@opensrp/fhir-helpers");

function _getRequireWildcardCache(nodeInterop) {
  if (typeof WeakMap !== "function") return null;
  var cacheBabelInterop = new WeakMap();
  var cacheNodeInterop = new WeakMap();
  return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) {
    return nodeInterop ? cacheNodeInterop : cacheBabelInterop;
  })(nodeInterop);
}

function _interopRequireWildcard(obj, nodeInterop) {
  if (!nodeInterop && obj && obj.__esModule) {
    return obj;
  }

  if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") {
    return {
      "default": obj
    };
  }

  var cache = _getRequireWildcardCache(nodeInterop);

  if (cache && cache.has(obj)) {
    return cache.get(obj);
  }

  var newObj = {};
  var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor;

  for (var key in obj) {
    if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) {
      var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null;

      if (desc && (desc.get || desc.set)) {
        Object.defineProperty(newObj, key, desc);
      } else {
        newObj[key] = obj[key];
      }
    }
  }

  newObj["default"] = obj;

  if (cache) {
    cache.set(obj, newObj);
  }

  return newObj;
}

function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);

  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);
    enumerableOnly && (symbols = symbols.filter(function (sym) {
      return Object.getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }

  return keys;
}

function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      (0, _defineProperty2["default"])(target, key, source[key]);
    }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
    });
  }

  return target;
}

var FormItem = _antd.Form.Item;
var defaultProps = {
  initialValues: {}
};

var getProductQueryFilters = function getProductQueryFilters(listId) {
  var listFilter = {};

  if (listId) {
    listFilter['_has:List:item:_id'] = listId;
  }

  return _objectSpread({
    code: "".concat(_utils.snomedCodeSystem, "|").concat(_utils.supplyMgSnomedCode)
  }, listFilter);
};

var AddLocationInventoryForm = function AddLocationInventoryForm(props) {
  var fhirBaseURL = props.fhirBaseURL,
      initialValues = props.initialValues,
      inventoryId = props.inventoryId,
      listResourceId = props.listResourceId,
      inventoryResourceObj = props.inventoryResourceObj,
      servicePointObj = props.servicePointObj,
      commodityListId = props.commodityListId;

  var _useState = (0, _react.useState)((0, _utils2.isAttractiveProduct)(inventoryResourceObj)),
      _useState2 = (0, _slicedToArray2["default"])(_useState, 2),
      attractiveProduct = _useState2[0],
      setAttractiveProduct = _useState2[1];

  var _useState3 = (0, _react.useState)(),
      _useState4 = (0, _slicedToArray2["default"])(_useState3, 2),
      accounterbilityMonths = _useState4[0],
      setAccounterbilityMonths = _useState4[1];

  var _useTranslation = (0, _mls.useTranslation)(),
      t = _useTranslation.t;

  var queryClient = (0, _reactQuery.useQueryClient)();
  var history = (0, _reactRouter.useHistory)();

  var _Form$useForm = _antd.Form.useForm(),
      _Form$useForm2 = (0, _slicedToArray2["default"])(_Form$useForm, 1),
      form = _Form$useForm2[0];

  var editMode = !!inventoryId;

  var _useMutation = (0, _reactQuery.useMutation)(function () {
    var _ref = (0, _asyncToGenerator2["default"])(_regenerator["default"].mark(function _callee(values) {
      var payload;
      return _regenerator["default"].wrap(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              payload = (0, _utils2.getLocationInventoryPayload)(values, editMode, inventoryResourceObj);
              return _context.abrupt("return", (0, _utils2.postLocationInventory)(fhirBaseURL, payload, editMode, listResourceId, servicePointObj));

            case 2:
            case "end":
              return _context.stop();
          }
        }
      }, _callee);
    }));

    return function (_x) {
      return _ref.apply(this, arguments);
    };
  }(), {
    onError: function onError(error) {
      (0, _notifications.sendErrorNotification)(error.message);
    },
    onSuccess: function onSuccess() {
      var successMessage = editMode ? t('Location inventory updated successfully') : t('Location inventory created successfully');
      (0, _notifications.sendSuccessNotification)(successMessage);

      if (editMode) {
        queryClient.invalidateQueries([fhirBaseURL, inventoryId])["catch"](function () {
          (0, _notifications.sendInfoNotification)(t('Failed to refresh data, please refresh the page'));
        });
      } else {
        form.resetFields();
      }
    }
  }),
      mutate = _useMutation.mutate,
      isLoading = _useMutation.isLoading;

  var productChangeHandler = function productChangeHandler(fullOption) {
    var product = Array.isArray(fullOption) ? fullOption[0] : fullOption;
    var endDate = (0, _utils2.productAccountabilityMonths)(product.ref);
    setAttractiveProduct((0, _utils2.isAttractiveProduct)(product.ref));

    if (endDate) {
      setAccounterbilityMonths(endDate);
    }
  };

  var delveryDateChangeHandler = function delveryDateChangeHandler(selectedDate) {
    if (accounterbilityMonths && selectedDate) {
      var newDate = selectedDate.add(accounterbilityMonths, 'month');
      form.setFieldValue(_constants.accountabilityEndDate, newDate);
    }
  };

  var validationRules = (0, _utils2.validationRulesFactory)(t, attractiveProduct);
  var productQueryFilters = getProductQueryFilters(commodityListId);
  return _react["default"].createElement(_antd.Form, (0, _extends2["default"])({
    form: form
  }, _reactUtils.formItemLayout, {
    onFinish: function onFinish(values) {
      mutate(values);
    },
    initialValues: initialValues
  }), _react["default"].createElement(FormItem, {
    id: _constants.product,
    rules: validationRules[_constants.product],
    name: _constants.product,
    label: t('Product name')
  }, _react["default"].createElement(_reactUtils.ClientSideActionsSelect, {
    fhirBaseUrl: fhirBaseURL,
    resourceType: _constants.groupResourceType,
    transformOption: _utils2.processProductOptions,
    extraQueryParams: productQueryFilters,
    showSearch: true,
    placeholder: t('Select product'),
    getFullOptionOnChange: productChangeHandler,
    disabled: editMode
  })), _react["default"].createElement(FormItem, {
    id: _constants.quantity,
    name: _constants.quantity,
    label: t('Quantity')
  }, _react["default"].createElement(_antd.Input, {
    min: 0,
    placeholder: t('Quantity'),
    type: "number"
  })), _react["default"].createElement(FormItem, {
    id: _constants.deliveryDate,
    rules: validationRules[_constants.deliveryDate],
    name: _constants.deliveryDate,
    label: t('Delivery date')
  }, _react["default"].createElement(_antd.DatePicker, {
    placeholder: t('Delivery date'),
    onChange: delveryDateChangeHandler,
    disabledDate: _utils2.handleDisabledFutureDates
  })), _react["default"].createElement(FormItem, {
    id: _constants.accountabilityEndDate,
    name: _constants.accountabilityEndDate,
    label: t('Accountability end date'),
    rules: validationRules[_constants.accountabilityEndDate]
  }, _react["default"].createElement(_antd.DatePicker, {
    placeholder: t('End date'),
    disabledDate: _utils2.handleDisabledPastDates
  })), _react["default"].createElement(FormItem, {
    hidden: true,
    id: "expiryDate",
    name: _constants.expiryDate,
    label: t('Expiry date')
  }, _react["default"].createElement(_antd.DatePicker, {
    placeholder: t('Expiry date'),
    disabledDate: _utils2.handleDisabledPastDates
  })), _react["default"].createElement(FormItem, {
    id: _constants.unicefSection,
    name: _constants.unicefSection,
    label: t('UNICEF section'),
    rules: validationRules[_constants.unicefSection]
  }, _react["default"].createElement(_reactUtils.ValueSetAsyncSelect, {
    placeholder: t('Select UNICEF section'),
    showSearch: true,
    valueSetURL: _fhirHelpers.unicefSectionValueSetURI,
    fhirBaseUrl: fhirBaseURL
  })), attractiveProduct ? _react["default"].createElement(FormItem, {
    id: _constants.serialNumber,
    rules: validationRules[_constants.serialNumber],
    name: _constants.serialNumber,
    label: t('Serial number')
  }, _react["default"].createElement(_antd.Input, {
    placeholder: t('Serial number')
  })) : null, _react["default"].createElement(FormItem, {
    id: _constants.donor,
    name: _constants.donor,
    label: t('Donor')
  }, _react["default"].createElement(_reactUtils.ValueSetAsyncSelect, {
    placeholder: t('Select donor'),
    showSearch: true,
    valueSetURL: _fhirHelpers.unicefDonorValueSetURI,
    fhirBaseUrl: fhirBaseURL
  })), _react["default"].createElement(FormItem, {
    id: _constants.PONumber,
    rules: validationRules[_constants.PONumber],
    name: _constants.PONumber,
    label: t('PO number')
  }, _react["default"].createElement(_antd.Input, {
    placeholder: t('PO number')
  })), _react["default"].createElement(FormItem, {
    hidden: true,
    id: "id",
    name: _constants.id,
    label: t('product Id')
  }, _react["default"].createElement(_antd.Input, {
    disabled: true
  })), _react["default"].createElement(FormItem, {
    hidden: true,
    id: "active",
    name: _constants.active,
    label: t('Active')
  }, _react["default"].createElement(_antd.Switch, {
    checked: initialValues.active,
    disabled: true
  })), _react["default"].createElement(FormItem, {
    hidden: true,
    id: "actual",
    name: _constants.actual,
    label: t('Actual')
  }, _react["default"].createElement(_antd.Switch, {
    checked: initialValues.actual,
    disabled: true
  })), _react["default"].createElement(FormItem, {
    hidden: true,
    id: "name",
    name: _constants.name,
    label: t('Name')
  }, _react["default"].createElement(_antd.Input, {
    disabled: true
  })), _react["default"].createElement(FormItem, {
    hidden: true,
    id: "type",
    name: _constants.type,
    label: t('Type')
  }, _react["default"].createElement(_antd.Input, {
    disabled: true
  })), _react["default"].createElement(FormItem, _reactUtils.tailLayout, _react["default"].createElement(_antd.Space, null, _react["default"].createElement(_antd.Button, {
    type: "primary",
    id: "submit-button",
    disabled: isLoading,
    htmlType: "submit"
  }, isLoading ? t('Saving') : t('Save')), _react["default"].createElement(_antd.Button, {
    id: "cancel-button",
    onClick: function onClick() {
      return history.goBack();
    }
  }, t('Cancel')))));
};

exports.AddLocationInventoryForm = AddLocationInventoryForm;
AddLocationInventoryForm.defaultProps = defaultProps;