"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.ProductFlag = void 0;

var _regenerator = _interopRequireDefault(require("@babel/runtime/regenerator"));

var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));

var _react = _interopRequireDefault(require("react"));

var _CloseFlagForm = require("../CloseFlagForm");

var _constants = require("../../constants");

var _antd = require("antd");

var _reactQuery = require("react-query");

var _reactUtils = require("@opensrp/react-utils");

var _fhirHelpers = require("@opensrp/fhir-helpers");

var _utils = require("../Utils/utils");

var _mls = require("../../mls");

var ProductFlag = function ProductFlag(props) {
  var _inventoryGroup$data, _inventoryGroup$data$, _inventoryGroup$data$2, _inventoryGroup$data5, _inventoryGroup$data6, _inventoryGroup$data7, _list$data, _list$data$entry, _list$data$entry$0$re, _list$data$entry$0$re2, _list$data3, _list$data3$entry, _list$data3$entry$0$r, _list$data3$entry$0$r2, _product$data, _location$data, _list$data4, _list$data4$entry, _list$data4$entry$0$r, _list$data4$entry$0$r2, _product$data2, _location$data2;

  var fhirBaseUrl = props.fhirBaseUrl,
      inventoryGroupReference = props.inventoryGroupReference,
      flag = props.flag,
      practitionerId = props.practitionerId;

  var _useTranslation = (0, _mls.useTranslation)(),
      t = _useTranslation.t;

  var inventoryGroup = (0, _reactQuery.useQuery)([_fhirHelpers.groupResourceType, inventoryGroupReference], function () {
    return new _reactUtils.FHIRServiceClass(fhirBaseUrl, '').read(inventoryGroupReference);
  }, {
    enabled: !!inventoryGroupReference,
    staleTime: _constants.thatiMinutes
  });
  var product = (0, _reactQuery.useQuery)([_fhirHelpers.groupResourceType, (_inventoryGroup$data = inventoryGroup.data) === null || _inventoryGroup$data === void 0 ? void 0 : (_inventoryGroup$data$ = _inventoryGroup$data.member) === null || _inventoryGroup$data$ === void 0 ? void 0 : (_inventoryGroup$data$2 = _inventoryGroup$data$[0]) === null || _inventoryGroup$data$2 === void 0 ? void 0 : _inventoryGroup$data$2.entity.reference], function () {
    var _inventoryGroup$data2, _inventoryGroup$data3, _inventoryGroup$data4;

    return new _reactUtils.FHIRServiceClass(fhirBaseUrl, '').read((_inventoryGroup$data2 = inventoryGroup.data) === null || _inventoryGroup$data2 === void 0 ? void 0 : (_inventoryGroup$data3 = _inventoryGroup$data2.member) === null || _inventoryGroup$data3 === void 0 ? void 0 : (_inventoryGroup$data4 = _inventoryGroup$data3[0]) === null || _inventoryGroup$data4 === void 0 ? void 0 : _inventoryGroup$data4.entity.reference);
  }, {
    enabled: !!((_inventoryGroup$data5 = inventoryGroup.data) !== null && _inventoryGroup$data5 !== void 0 && (_inventoryGroup$data6 = _inventoryGroup$data5.member) !== null && _inventoryGroup$data6 !== void 0 && (_inventoryGroup$data7 = _inventoryGroup$data6[0]) !== null && _inventoryGroup$data7 !== void 0 && _inventoryGroup$data7.entity.reference),
    staleTime: _constants.thatiMinutes
  });
  var list = (0, _reactQuery.useQuery)([_fhirHelpers.listResourceType, inventoryGroupReference], function () {
    return new _reactUtils.FHIRServiceClass(fhirBaseUrl, _fhirHelpers.listResourceType).list({
      item: inventoryGroupReference === null || inventoryGroupReference === void 0 ? void 0 : inventoryGroupReference.split('/')[1],
      code: "".concat(_fhirHelpers.servicePointProfileInventoryListCoding.system, "|").concat(_fhirHelpers.servicePointProfileInventoryListCoding.code)
    });
  }, {
    enabled: !!(inventoryGroupReference !== null && inventoryGroupReference !== void 0 && inventoryGroupReference.split('/')[1]),
    staleTime: _constants.thatiMinutes
  });
  var location = (0, _reactQuery.useQuery)([_fhirHelpers.locationResourceType, (_list$data = list.data) === null || _list$data === void 0 ? void 0 : (_list$data$entry = _list$data.entry) === null || _list$data$entry === void 0 ? void 0 : (_list$data$entry$0$re = _list$data$entry[0].resource) === null || _list$data$entry$0$re === void 0 ? void 0 : (_list$data$entry$0$re2 = _list$data$entry$0$re.subject) === null || _list$data$entry$0$re2 === void 0 ? void 0 : _list$data$entry$0$re2.reference], (0, _asyncToGenerator2["default"])(_regenerator["default"].mark(function _callee() {
    var _list$data2, _list$data2$entry, _list$data2$entry$0$r, _list$data2$entry$0$r2;

    return _regenerator["default"].wrap(function _callee$(_context) {
      while (1) {
        switch (_context.prev = _context.next) {
          case 0:
            return _context.abrupt("return", new _reactUtils.FHIRServiceClass(fhirBaseUrl, '').read("".concat((_list$data2 = list.data) === null || _list$data2 === void 0 ? void 0 : (_list$data2$entry = _list$data2.entry) === null || _list$data2$entry === void 0 ? void 0 : (_list$data2$entry$0$r = _list$data2$entry[0].resource) === null || _list$data2$entry$0$r === void 0 ? void 0 : (_list$data2$entry$0$r2 = _list$data2$entry$0$r.subject) === null || _list$data2$entry$0$r2 === void 0 ? void 0 : _list$data2$entry$0$r2.reference)));

          case 1:
          case "end":
            return _context.stop();
        }
      }
    }, _callee);
  })), {
    enabled: !!((_list$data3 = list.data) !== null && _list$data3 !== void 0 && (_list$data3$entry = _list$data3.entry) !== null && _list$data3$entry !== void 0 && (_list$data3$entry$0$r = _list$data3$entry[0].resource) !== null && _list$data3$entry$0$r !== void 0 && (_list$data3$entry$0$r2 = _list$data3$entry$0$r.subject) !== null && _list$data3$entry$0$r2 !== void 0 && _list$data3$entry$0$r2.reference),
    staleTime: _constants.thatiMinutes
  });

  if (inventoryGroup.isLoading || inventoryGroup.isFetching || product.isLoading || product.isFetching || list.isLoading || list.isFetching || location.isLoading || location.isFetching) {
    return _react["default"].createElement(_antd.Spin, {
      size: "large",
      className: "custom-spinner"
    });
  }

  if (inventoryGroup.error && !inventoryGroup.data) {
    return _react["default"].createElement(_reactUtils.BrokenPage, {
      errorMessage: t('An error occurred while fetching the inventory')
    });
  }

  var initialValues = {
    productName: (_product$data = product.data) === null || _product$data === void 0 ? void 0 : _product$data.name,
    locationName: (_location$data = location.data) === null || _location$data === void 0 ? void 0 : _location$data.name,
    listSubject: (_list$data4 = list.data) === null || _list$data4 === void 0 ? void 0 : (_list$data4$entry = _list$data4.entry) === null || _list$data4$entry === void 0 ? void 0 : (_list$data4$entry$0$r = _list$data4$entry[0].resource) === null || _list$data4$entry$0$r === void 0 ? void 0 : (_list$data4$entry$0$r2 = _list$data4$entry$0$r.subject) === null || _list$data4$entry$0$r2 === void 0 ? void 0 : _list$data4$entry$0$r2.reference,
    status: flag.status,
    practitionerId: practitionerId
  };
  return (_product$data2 = product.data) !== null && _product$data2 !== void 0 && _product$data2.name && (_location$data2 = location.data) !== null && _location$data2 !== void 0 && _location$data2.name ? _react["default"].createElement(_CloseFlagForm.CloseFlagForm, {
    initialValues: initialValues,
    flag: flag,
    mutationEffect: function () {
      var _ref2 = (0, _asyncToGenerator2["default"])(_regenerator["default"].mark(function _callee2(initialValues, values, activeFlag) {
        return _regenerator["default"].wrap(function _callee2$(_context2) {
          while (1) {
            switch (_context2.prev = _context2.next) {
              case 0:
                return _context2.abrupt("return", (0, _utils.putCloseFlagResources)(initialValues, values, activeFlag, fhirBaseUrl));

              case 1:
              case "end":
                return _context2.stop();
            }
          }
        }, _callee2);
      }));

      return function (_x, _x2, _x3) {
        return _ref2.apply(this, arguments);
      };
    }()
  }) : _react["default"].createElement(_reactUtils.BrokenPage, {
    title: t('Invalid Flag'),
    errorMessage: t('Missing  location field. This information is required to close the flag form.'),
    extraLinks: _react["default"].createElement(_react["default"].Fragment, null, _react["default"].createElement(_antd.Button, {
      type: "primary",
      onClick: function onClick() {
        window.close();
      }
    }, t('Close App')))
  });
};

exports.ProductFlag = ProductFlag;
var _default = ProductFlag;
exports["default"] = _default;