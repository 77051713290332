"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CommodityAddEdit = void 0;

var _pkgConfig = require("@opensrp/pkg-config");

var _Eusm = require("./Eusm");

var _Default = require("./Default");

var _react = _interopRequireDefault(require("react"));

var CommodityAddEdit = function CommodityAddEdit(props) {
  var projectCode = (0, _pkgConfig.getConfig)('projectCode');

  if (projectCode === 'eusm') {
    return _react["default"].createElement(_Eusm.CommodityAddEdit, props);
  } else {
    return _react["default"].createElement(_Default.CommodityAddEdit, props);
  }
};

exports.CommodityAddEdit = CommodityAddEdit;