"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.valueSetResourceType = exports.serviceType = exports.servicePointNamespace = exports.parentIdQueryParam = exports.namespace = exports.nameFilterKey = exports.longitude = exports.locationResourceType = exports.locationHierarchyResourceType = exports.locationGeoJsonExtensionUrl = exports.listResourceType = exports.latitude = exports.isJurisdiction = exports.geometry = exports.externalId = exports.accEndDateFilterKey = exports.URL_SERVICE_POINT_LIST = exports.URL_SERVICE_POINT_ADD_EDIT = exports.URL_LOCATION_VIEW_DETAILS = exports.URL_LOCATION_UNIT_EDIT = exports.URL_LOCATION_UNIT_ADD = exports.URL_LOCATION_UNIT = exports.URL_ALL_LOCATIONS = exports.URL_ADMIN = exports.MATCH_SEARCH_MODE = exports.BACK_SEARCH_PARAM = void 0;
var locationHierarchyResourceType = 'LocationHierarchy';
exports.locationHierarchyResourceType = locationHierarchyResourceType;
var listResourceType = 'List';
exports.listResourceType = listResourceType;
var locationResourceType = 'Location';
exports.locationResourceType = locationResourceType;
var valueSetResourceType = 'ValueSet';
exports.valueSetResourceType = valueSetResourceType;
var URL_ADMIN = '/admin';
exports.URL_ADMIN = URL_ADMIN;
var URL_LOCATION_UNIT = "".concat(URL_ADMIN, "/location/unit");
exports.URL_LOCATION_UNIT = URL_LOCATION_UNIT;
var URL_LOCATION_UNIT_ADD = "".concat(URL_LOCATION_UNIT, "/add");
exports.URL_LOCATION_UNIT_ADD = URL_LOCATION_UNIT_ADD;
var URL_LOCATION_UNIT_EDIT = "".concat(URL_LOCATION_UNIT, "/edit");
exports.URL_LOCATION_UNIT_EDIT = URL_LOCATION_UNIT_EDIT;
var URL_LOCATION_VIEW_DETAILS = "".concat(URL_LOCATION_UNIT, "/view");
exports.URL_LOCATION_VIEW_DETAILS = URL_LOCATION_VIEW_DETAILS;
var URL_ALL_LOCATIONS = "".concat(URL_ADMIN, "/location/all");
exports.URL_ALL_LOCATIONS = URL_ALL_LOCATIONS;
var URL_SERVICE_POINT_ADD_EDIT = "".concat(URL_ADMIN, "/service-points/add-edit");
exports.URL_SERVICE_POINT_ADD_EDIT = URL_SERVICE_POINT_ADD_EDIT;
var URL_SERVICE_POINT_LIST = "".concat(URL_ADMIN, "/service-points");
exports.URL_SERVICE_POINT_LIST = URL_SERVICE_POINT_LIST;
var BACK_SEARCH_PARAM = 'back_to';
exports.BACK_SEARCH_PARAM = BACK_SEARCH_PARAM;
var serviceType = 'serviceType';
exports.serviceType = serviceType;
var geometry = 'geometry';
exports.geometry = geometry;
var latitude = 'latitude';
exports.latitude = latitude;
var longitude = 'longitude';
exports.longitude = longitude;
var externalId = 'externalId';
exports.externalId = externalId;
var isJurisdiction = 'isJurisdiction';
exports.isJurisdiction = isJurisdiction;
var locationGeoJsonExtensionUrl = 'http://build.fhir.org/extension-location-boundary-geojson.html';
exports.locationGeoJsonExtensionUrl = locationGeoJsonExtensionUrl;
var namespace = 'fhir-location-management';
exports.namespace = namespace;
var servicePointNamespace = 'fhir-service-point';
exports.servicePointNamespace = servicePointNamespace;
var MATCH_SEARCH_MODE = 'match';
exports.MATCH_SEARCH_MODE = MATCH_SEARCH_MODE;
var parentIdQueryParam = 'parentId';
exports.parentIdQueryParam = parentIdQueryParam;
var accEndDateFilterKey = 'accountabilityEndDate';
exports.accEndDateFilterKey = accEndDateFilterKey;
var nameFilterKey = 'name';
exports.nameFilterKey = nameFilterKey;