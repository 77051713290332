"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useServerSideActionsDataGrid = void 0;
exports.useSimpleTabularView = useSimpleTabularView;

var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));

var _regenerator = _interopRequireDefault(require("@babel/runtime/regenerator"));

var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _react = require("react");

var _utils = require("../components/Search/utils");

var _utils2 = require("../helpers/utils");

var _reactQuery = require("react-query");

var _pkgConfig = require("@opensrp/pkg-config");

var _reactRouter = require("react-router");

var _utils3 = require("./utils");

var _excluded = ["data"];

function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);

  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);
    enumerableOnly && (symbols = symbols.filter(function (sym) {
      return Object.getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }

  return keys;
}

function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      (0, _defineProperty2["default"])(target, key, source[key]);
    }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
    });
  }

  return target;
}

var defaultGetExtraParams = function defaultGetExtraParams(search) {
  if (search) {
    return {
      'name:contains': search
    };
  }

  return {};
};

function useSimpleTabularView(fhirBaseUrl, resourceType) {
  var _ref;

  var extraParams = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : defaultGetExtraParams;
  var extractResources = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : _utils2.getResourcesFromBundle;
  var defaultSortState = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : {};
  var defaultFilterState = arguments.length > 5 && arguments[5] !== undefined ? arguments[5] : {};
  var location = (0, _reactRouter.useLocation)();
  var history = (0, _reactRouter.useHistory)();
  var match = (0, _reactRouter.useRouteMatch)();

  var _useState = (0, _react.useState)(defaultSortState),
      _useState2 = (0, _slicedToArray2["default"])(_useState, 2),
      sortState = _useState2[0],
      setSortState = _useState2[1];

  var _useState3 = (0, _react.useState)(defaultFilterState),
      _useState4 = (0, _slicedToArray2["default"])(_useState3, 2),
      filterState = _useState4[0],
      setFilterState = _useState4[1];

  var page = (0, _utils3.getNumberParam)(location, _utils3.pageQuery, _utils3.startingPage);
  var search = (0, _utils3.getStringParam)(location, _utils3.searchQuery);
  var defaultPageSize = (_ref = (0, _pkgConfig.getConfig)('defaultTablesPageSize')) !== null && _ref !== void 0 ? _ref : _utils3.startingPageSize;
  var pageSize = (0, _utils3.getNumberParam)(location, _utils3.pageSizeQuery, defaultPageSize);
  var otherParams = typeof extraParams === 'function' ? extraParams(search) : extraParams;
  otherParams = _objectSpread(_objectSpread(_objectSpread(_objectSpread({}, otherParams), (0, _utils3.SortDescToSearchParams)(sortState)), (0, _utils3.filterDescToSearchParams)(filterState)), {}, {
    _total: 'accurate',
    _getpagesoffset: (page - 1) * pageSize,
    _count: pageSize
  });
  var queryFn = (0, _react.useCallback)(function () {
    var _ref3 = (0, _asyncToGenerator2["default"])(_regenerator["default"].mark(function _callee(_ref2) {
      var _ref2$queryKey, _, otherParams;

      return _regenerator["default"].wrap(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              _ref2$queryKey = (0, _slicedToArray2["default"])(_ref2.queryKey, 2), _ = _ref2$queryKey[0], otherParams = _ref2$queryKey[1];
              return _context.abrupt("return", (0, _utils3.loadResources)(fhirBaseUrl, resourceType, otherParams).then(function (res) {
                return res;
              }));

            case 2:
            case "end":
              return _context.stop();
          }
        }
      }, _callee);
    }));

    return function (_x) {
      return _ref3.apply(this, arguments);
    };
  }(), [fhirBaseUrl, resourceType]);
  var rQuery = {
    queryKey: [resourceType, otherParams],
    queryFn: queryFn,
    select: function select(data) {
      var _data$total;

      return {
        records: extractResources(data),
        total: (_data$total = data.total) !== null && _data$total !== void 0 ? _data$total : 0
      };
    },
    keepPreviousData: true,
    staleTime: 5000,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false
  };

  var _useQuery = (0, _reactQuery.useQuery)(rQuery),
      data = _useQuery.data,
      restQueryValues = (0, _objectWithoutProperties2["default"])(_useQuery, _excluded);

  var searchFormProps = {
    wrapperClassName: 'elongate-search-bar',
    defaultValue: (0, _utils.getQueryParams)(location)[_utils3.searchQuery],
    onChangeHandler: function onChangeHandler(event) {
      var nextUrl = (0, _utils3.getNextUrlOnSearch)(event, location, match);
      history.push(nextUrl);
    }
  };
  var tablePaginationProps = {
    current: page,
    pageSize: pageSize,
    total: data === null || data === void 0 ? void 0 : data.total,
    defaultPageSize: defaultPageSize,
    onChange: function onChange(current, pageSize) {
      if (current && pageSize) {
        var newSParams = new URLSearchParams(location.search);
        newSParams.set(_utils3.pageSizeQuery, pageSize.toString());
        newSParams.set(_utils3.pageQuery, current.toString());
        history.push("".concat(match.url, "?").concat(newSParams.toString()));
      }
    }
  };
  var updateSortParams = (0, _react.useCallback)(function (state) {
    var sanitized = (0, _utils3.sanitizeParams)(sortState, state);
    setSortState(sanitized);
  }, [setSortState, sortState]);
  var updateFilterParams = (0, _react.useCallback)(function (state) {
    var sanitized = (0, _utils3.sanitizeParams)(filterState, state);
    setFilterState(sanitized);
  }, [setFilterState, filterState]);

  var getControlledSortProps = function getControlledSortProps(dataIndex) {
    var sortColumnProps = sortState[dataIndex];

    if (sortColumnProps) {
      var _sortState$dataIndex;

      return {
        sorter: true,
        sortOrder: (_sortState$dataIndex = sortState[dataIndex]) === null || _sortState$dataIndex === void 0 ? void 0 : _sortState$dataIndex.order,
        sortDirections: ['ascend', 'descend']
      };
    } else {
      return {};
    }
  };

  return {
    tablePaginationProps: tablePaginationProps,
    sortOptions: {
      updateSortParams: updateSortParams,
      getControlledSortProps: getControlledSortProps,
      currentParams: otherParams,
      sortState: sortState
    },
    filterOptions: {
      updateFilterParams: updateFilterParams,
      currentFilters: filterState,
      currentParams: otherParams
    },
    queryValues: _objectSpread({
      data: data
    }, restQueryValues),
    searchFormProps: searchFormProps
  };
}

var useServerSideActionsDataGrid = useSimpleTabularView;
exports.useServerSideActionsDataGrid = useServerSideActionsDataGrid;